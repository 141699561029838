import deepFreeze from 'deep-freeze';

import { SharedMailboxes } from '../actions';

import SharedMailboxState, {
  APISharedMailbox,
} from '../../interfaces/SharedMailboxes';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: SharedMailboxState = {
  error: '',
  fetching: false,
  saving: false,
  sharedMailboxesById: {},
  valid: {
    email: true,
    pin: true,
  },
};

/**
 * Clear phonenumbers
 */
const clear = (
  state: SharedMailboxState = initialState,
): SharedMailboxState => {
  return {
    ...state,
    sharedMailboxesById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (
  state: SharedMailboxState = initialState,
): SharedMailboxState => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive ivr menus
 */
const receive = (
  state: SharedMailboxState = initialState,
  receivedMailboxes: APISharedMailbox[] = [],
): SharedMailboxState => {
  const processedMailboxes = processCreated(receivedMailboxes);
  const { idMap } = arrayToIdMapAndOrdering(processedMailboxes, 'id');

  return {
    ...state,
    sharedMailboxesById: {
      ...state.sharedMailboxesById,
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: SharedMailboxState = initialState,
): SharedMailboxState => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (
  state: SharedMailboxState = initialState,
): SharedMailboxState => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (
  state: SharedMailboxState = initialState,
): SharedMailboxState => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a mailbox
 */
const remove = (
  state: SharedMailboxState = initialState,
  sharedMailbox: APISharedMailbox,
): SharedMailboxState => {
  const { [sharedMailbox.id]: _, ...rest } = state.sharedMailboxesById;
  return {
    ...state,
    // TODO check
    sharedMailboxesById: rest,
  };
};
const updateValidity = (
  state: SharedMailboxState = initialState,
  validity: Validity<APISharedMailbox>,
): SharedMailboxState => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};

interface SharedMailboxAction extends ActionInterface {
  payload: {
    item?: APISharedMailbox;
    items?: APISharedMailbox[];
    validity?: Validity<APISharedMailbox>;
  };
}

export default (
  state: SharedMailboxState = initialState,
  action?: SharedMailboxAction,
): SharedMailboxState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case SharedMailboxes.clear:
      return clear(state);

    case SharedMailboxes.fetching:
      return fetching(state);

    case SharedMailboxes.receive:
      return receive(state, action.payload.items);

    case SharedMailboxes.fetchingDone:
      return fetchingDone(state);

    case SharedMailboxes.remove:
      return remove(state, action.payload.item);

    case SharedMailboxes.saving:
      return saving(state);

    case SharedMailboxes.savingDone:
      return savingDone(state);

    case SharedMailboxes.updateValidity:
      return updateValidity(state, action.payload.validity);

    default:
      return state;
  }
};
