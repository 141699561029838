import deepFreeze from 'deep-freeze';

import { Pricing } from '../actions';

import IPricing, { APIPricing } from '../../interfaces/Pricing';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState: IPricing = {
  fetching: false,
  pricing: undefined,
  saving: false,
};

const clear = (state: IPricing = initialState): IPricing => {
  return {
    ...{},
    ...state,
    pricing: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IPricing = initialState): IPricing => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive pricing
 */
const receive = (
  state: IPricing = initialState,
  pricing: APIPricing,
): IPricing => {
  return {
    ...{},
    ...state,
    pricing,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IPricing = initialState): IPricing => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IPricing = initialState): IPricing => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: IPricing = initialState): IPricing => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

interface ActionPricing extends ActionInterface {
  payload: {
    pricing: APIPricing;
  };
}

export default (
  state: IPricing = initialState,
  action?: ActionPricing,
): IPricing => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Pricing.clear:
      return clear(state);

    case Pricing.fetching:
      return fetching(state);

    case Pricing.receive:
      return receive(state, action.payload.pricing);

    case Pricing.fetchingDone:
      return fetchingDone(state);

    case Pricing.saving:
      return saving(state);

    case Pricing.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
