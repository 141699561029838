import deepFreeze from 'deep-freeze';

import IRingGroup, { APIRingGroup } from '../../interfaces/RingGroup';

import { ActionInterface } from '../../helpers/actionBuilder';
import { RingGroup } from '../actions';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: IRingGroup = {
  error: '',
  fetching: false,
  ringGroupsById: {},
  saving: false,
  valid: {
    cascadeDelay: true,
    memberTimeout: true,
  },
};

const clear = (state: IRingGroup = initialState): IRingGroup => {
  return {
    ...state,
    ringGroupsById: {},
  };
};

const fetching = (state: IRingGroup = initialState): IRingGroup => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: IRingGroup = initialState,
  groups: APIRingGroup[] = [],
): IRingGroup => {
  const processedGroups = processCreated(groups);
  const { idMap } = arrayToIdMapAndOrdering(processedGroups, 'id');

  return {
    ...state,
    ringGroupsById: {
      ...state.ringGroupsById,
      ...idMap,
    },
  };
};

const fetchingDone = (state: IRingGroup = initialState): IRingGroup => {
  return {
    ...state,
    fetching: false,
  };
};

const saving = (state: IRingGroup = initialState): IRingGroup => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: IRingGroup = initialState): IRingGroup => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: IRingGroup = initialState,
  group: APIRingGroup,
): IRingGroup => {
  const { [group.id]: _, ...rest } = state.ringGroupsById;
  return {
    ...state,
    ringGroupsById: rest,
    // TODO check
  };
};

const updateValidity = (
  state: IRingGroup = initialState,
  validity: Validity<APIRingGroup>,
) => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};
interface CallgroupsAction extends ActionInterface {
  payload: {
    items?: APIRingGroup[];
    item?: APIRingGroup;
    validity?: Validity<APIRingGroup>;
  };
}

export default (
  state: IRingGroup = initialState,
  action?: CallgroupsAction,
): IRingGroup => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case RingGroup.clear:
      return clear(state);

    case RingGroup.fetching:
      return fetching(state);

    case RingGroup.fetchingDone:
      return fetchingDone(state);

    case RingGroup.saving:
      return saving(state);

    case RingGroup.savingDone:
      return savingDone(state);

    case RingGroup.receive:
      return receive(state, action.payload.items as APIRingGroup[]);

    case RingGroup.remove:
      return remove(state, action.payload.item);

    case RingGroup.updateValidity:
      return updateValidity(state, action.payload.validity);

    default:
      return state;
  }
};
