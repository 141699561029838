import deepFreeze from 'deep-freeze';

import { Billing } from '../actions';

import IBilling, {
  APIBilling,
  APIBillingEstimate,
  APIBillingPaymentMethod,
  APISetupIntent,
} from '../../interfaces/Billing';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: IBilling = {
  // billing: {},
  creatingSetupIntent: false,
  error: '',
  fetching: false,
  fetchingEstimate: false,
  fetchingPaymentMethods: false,
  paymentMethodsById: {},
  saving: false,
  stripe: {},
};

/**
 * Clear billing
 */
const clear = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    billing: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive billing
 */
const receive = (
  state: IBilling = initialState,
  billing: APIBilling,
): IBilling => {
  return {
    ...{},
    ...state,
    billing,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

const clearEstimate = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    estimate: undefined,
  };
};
/**
 * Set fetching flag to true
 */
const fetchingEstimate = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    fetchingEstimate: true,
  };
};

/**
 * Receive billing
 */
const receiveEstimate = (
  state: IBilling = initialState,
  estimate: APIBillingEstimate,
): IBilling => {
  return {
    ...{},
    ...state,
    estimate,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDoneEstimate = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    fetchingEstimate: false,
  };
};

const clearPaymentMethods = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    paymentMethodsById: {},
  };
};
/**
 * Set fetching flag to true
 */
const fetchingPaymentMethods = (state: IBilling = initialState): IBilling => {
  return {
    ...{},
    ...state,
    fetchingPaymentMethods: true,
  };
};

/**
 * Receive billing
 */
const receivePaymentMethods = (
  state: IBilling = initialState,
  paymentMethods: APIBillingPaymentMethod[] = [],
): IBilling => {
  const { idMap } = arrayToIdMapAndOrdering(paymentMethods, 'id');
  return {
    ...{},
    ...state,
    paymentMethodsById: {
      ...(state.paymentMethodsById || {}),
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDonePaymentMethods = (
  state: IBilling = initialState,
): IBilling => {
  return {
    ...{},
    ...state,
    fetchingPaymentMethods: false,
  };
};

const removePaymentMethod = (
  state: IBilling = initialState,
  paymentMethod: APIBillingPaymentMethod,
): IBilling => {
  const { [paymentMethod.id]: _, ...rest } = state.paymentMethodsById;
  return {
    ...state,
    paymentMethodsById: rest,
  };
};

const creatingSetupIntent = (state: IBilling = initialState): IBilling => {
  return {
    ...state,
    creatingSetupIntent: true,
  };
};
const creatingSetupIntentDone = (state: IBilling = initialState): IBilling => {
  return {
    ...state,
    creatingSetupIntent: false,
  };
};

const receiveSetupIntent = (
  state: IBilling = initialState,
  setupIntent: APISetupIntent,
): IBilling => {
  return {
    ...state,
    stripe: {
      setupIntent,
    },
  };
};

interface ActionBilling extends ActionInterface {
  payload: {
    billing: APIBilling;
    estimate: APIBillingEstimate;
    paymentMethods: APIBillingPaymentMethod[];
    paymentMethod: APIBillingPaymentMethod;
    setupIntent: APISetupIntent;
  };
}

export default (
  state: IBilling = initialState,
  action?: ActionBilling,
): IBilling => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Billing.clear:
      return clear(state);

    case Billing.fetching:
      return fetching(state);

    case Billing.receive:
      return receive(state, action.payload.billing);

    case Billing.fetchingDone:
      return fetchingDone(state);

    case Billing.estimate.clear:
      return clearEstimate(state);

    case Billing.estimate.fetching:
      return fetchingEstimate(state);

    case Billing.estimate.receive:
      return receiveEstimate(state, action.payload.estimate);

    case Billing.estimate.fetchingDone:
      return fetchingDoneEstimate(state);

    case Billing.paymentMethods.clear:
      return clearPaymentMethods(state);

    case Billing.paymentMethods.fetching:
      return fetchingPaymentMethods(state);

    case Billing.paymentMethods.receive:
      return receivePaymentMethods(state, action.payload.paymentMethods);

    case Billing.paymentMethods.fetchingDone:
      return fetchingDonePaymentMethods(state);

    case Billing.paymentMethods.remove:
      return removePaymentMethod(state, action.payload.paymentMethod);

    case Billing.stripe.creating:
      return creatingSetupIntent(state);
    case Billing.stripe.creatingDone:
      return creatingSetupIntentDone(state);
    case Billing.stripe.receive:
      return receiveSetupIntent(state, action.payload.setupIntent);

    default:
      return state;
  }
};
