import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { RoutingRules } from '../actions';
import * as toastActions from '../toasts/actions';

import { APIRoutingRule } from '../../interfaces/RoutingRules';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { nvFetch } from '../../helpers/nvFetch';
import {
  createMsg,
  fetchMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(RoutingRules.clear).json;
};

export const getRoutingRules = (incomingNumberId: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(RoutingRules.fetching).json);
    const { accessToken, activeCustomerId } = getState().user;
    let data;
    try {
      const requestUrl = `customers/${activeCustomerId}/phonenumbers/${incomingNumberId}/routingrules`;
      data = (await nvFetch(requestUrl, accessToken, 'GET')) as Things<
        APIRoutingRule
      >;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('routing rules', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    if (!data || !data.items) {
      data.items = [];
    }
    dispatch(
      new SuccessAction(RoutingRules.receive, {
        incomingNumberId,
        items: data.items,
      }).json,
    );
    dispatch(new SuccessAction(RoutingRules.fetchingDone).json);
  };
};

export const saveRoutingRule = (
  incomingNumberId: string,
  routingRule: APIRoutingRule,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(RoutingRules.saving).json);
    let data;
    try {
      const requestUrl = `customers/${activeCustomerId}/phonenumbers/${incomingNumberId}/routingrules/${routingRule.id}`;
      data = (await nvFetch(
        requestUrl,
        accessToken,
        'PUT',
        routingRule,
      )) as APIRoutingRule;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(
            'routing rule',
            true,
            error.validationErrors
              ?.map((err: any) => `${err.field} ${err.error}`)
              ?.join(',') || error.message,
          ),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(RoutingRules.savingDone).json);
    }
    dispatch(new SuccessAction(RoutingRules.savingDone).json);
    if (!data) {
      return;
    }

    dispatch(
      new SuccessAction(RoutingRules.update, {
        incomingNumberId,
        item: data,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('routing rule'),
        type: 'success',
      }),
    );
  };
};

export const removeRoutingRule = (
  incomingNumberId: string,
  routingRule: APIRoutingRule,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { id } = routingRule;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(RoutingRules.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/phonenumbers/${incomingNumberId}/routingrules/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg('routing rule', true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(RoutingRules.savingDone).json);
    }
    dispatch(new SuccessAction(RoutingRules.savingDone).json);
    dispatch(
      new SuccessAction(RoutingRules.remove, {
        incomingNumberId,
        item: routingRule,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg('routing rule'),
        type: 'success',
      }),
    );
  };
};

export const createRoutingRule = (
  incomingNumberId: string,
  routingRule: Partial<APIRoutingRule>,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(RoutingRules.saving).json);
    let resultingRoutingRule;
    try {
      const requesturl = `customers/${activeCustomerId}/phonenumbers/${incomingNumberId}/routingrules`;
      resultingRoutingRule = await nvFetch(
        requesturl,
        accessToken,
        'POST',
        routingRule,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(
            'routing rule',
            true,
            error.validationErrors
              ?.map((err: any) => `${err.field} ${err.error}`)
              ?.join(',') || error.message,
          ),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(RoutingRules.savingDone).json);
    }
    dispatch(new SuccessAction(RoutingRules.savingDone).json);
    if (!resultingRoutingRule) {
      return;
    }

    dispatch(
      new SuccessAction(RoutingRules.update, {
        incomingNumberId,
        item: resultingRoutingRule,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg('routing rule'),
        type: 'success',
      }),
    );
  };
};
