import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { ForwardingRules } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APIForwardingRule } from '../../interfaces/ForwardingRules';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { forwardingRulesSelector } from '../../selectors/forwardingRules';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction } from '../helpers';

export const clear = (): ActionInterface => {
  return new SuccessAction(ForwardingRules.clear).json;
};

export const getForwardingRules = (endpointId: string): ThunkFunction =>
  completelyFetchResourceIntoStore(
    `endpoints/${endpointId}/forwardingrules`,
    (terms) => terms.forwardingRule.other,
    {
      fetching: ForwardingRules.fetching,
      fetchingDone: ForwardingRules.fetchingDone,
      receive: ForwardingRules.receive,
    },
    { pageSize: 200 },
  );

export const saveForwardingRule = (
  endpointId: string,
  forwardingRule: APIForwardingRule,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(ForwardingRules.saving).json);
    let data;
    try {
      const requestUrl = `customers/${activeCustomerId}/endpoints/${endpointId}/forwardingrules/${forwardingRule.id}`;
      data = (await nvFetch(
        requestUrl,
        accessToken,
        'PUT',
        forwardingRule,
      )) as Things<APIForwardingRule>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(
            'forwarding rule',
            true,
            error.validationErrors
              ?.map((err: any) => `${err.field} ${err.error}`)
              ?.join(',') || error.message,
          ),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(ForwardingRules.savingDone).json);
    }
    dispatch(new SuccessAction(ForwardingRules.savingDone).json);

    if (!data) {
      return;
    }

    const newForwardingRules = forwardingRulesSelector(getState());
    const foundIdx = newForwardingRules.findIndex(
      (x) => x.id === forwardingRule.id,
    );
    newForwardingRules[foundIdx] = forwardingRule;

    dispatch(
      new SuccessAction(ForwardingRules.receive, {
        items: newForwardingRules,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('forwarding rule'),
        type: 'success',
      }),
    );
  };
};

export const removeForwardingRule = (
  endpointId: string,
  forwardingRule: APIForwardingRule,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { id } = forwardingRule;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(ForwardingRules.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/endpoints/${endpointId}/forwardingrules/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg('forwarding rule', true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(ForwardingRules.savingDone).json);
    }
    dispatch(new SuccessAction(ForwardingRules.savingDone).json);

    dispatch(
      new SuccessAction(ForwardingRules.remove, {
        item: forwardingRule,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg('forwarding rule'),
        type: 'success',
      }),
    );
  };
};

export const createForwardingRule = (
  endpointId: string,
  forwardingRule: Partial<APIForwardingRule>,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(ForwardingRules.saving).json);
    let resultingForwardingRule;
    try {
      const requesturl = `customers/${activeCustomerId}/endpoints/${endpointId}/forwardingrules`;
      resultingForwardingRule = await nvFetch(
        requesturl,
        accessToken,
        'POST',
        forwardingRule,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(
            'forwarding rule',
            true,
            error.validationErrors
              ?.map((err: any) => `${err.field} ${err.error}`)
              ?.join(',') || error.message,
          ),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(ForwardingRules.savingDone).json);
    }
    dispatch(new SuccessAction(ForwardingRules.savingDone).json);

    if (!resultingForwardingRule) return;
    const newForwardingRules = [
      ...forwardingRulesSelector(getState()),
      resultingForwardingRule,
    ];

    dispatch(
      new SuccessAction(ForwardingRules.receive, {
        items: newForwardingRules,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg('forwarding rule'),
        type: 'success',
      }),
    );
  };
};
