import deepFreeze from 'deep-freeze';

import { Branding } from '../actions';

import { ActionInterface } from '../../helpers/actionBuilder';
import { BrandingState, BrandingAPI } from '../../interfaces/Branding';
import { initialBranding } from '../../helpers/branding';

export const initialState: BrandingState = {
  branding: {
    ...initialBranding,
  },
  hasBranding: false,
};

const receiveBranding = (
  state = initialState,
  { branding }: { branding: BrandingAPI },
) => {
  return {
    ...state,
    branding,
    hasBranding: true,
  };
};

export default (state = initialState, action?: ActionInterface) => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Branding.receive:
      return receiveBranding(state, action.payload);
    default:
      return state;
  }
};
