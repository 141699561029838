import Thing from './Thing';
import { IdMap } from './interfaces';

export enum AccountType {
  BUSINESS = 'BUSINESS',
  RESIDENTIAL_PLUS = 'RESIDENTIAL',
  RESIDENTIAL = 'WLR',
  UNKNOWN = 'UNKNOWN',
}

export interface APICustomer extends Thing {
  type: 'customer';
  accountType: AccountType;
  id: string;
  created: Date;
  company: string;
  currency: string;
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  enabled: boolean;
  partnerId?: string;
  partnerCompany?: string;
  userEmailUpdatable: boolean;
  country: string;
}

export default interface StateCustomers {
  error: string;
  customersById: IdMap<APICustomer>;
  fetching: boolean;
  saving: boolean;
}
