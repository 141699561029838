/* eslint-disable sort-keys */
import { BrandingAPI } from '../interfaces/Branding';
import { bindNvFetch } from './nvFetch';
import { X_PARTNER_ID } from './xPartnerId';

const jsToCSSVariable = (variables: { key: string; value: string }[]) => {
  variables.forEach((v) => {
    (document.querySelector(':root') as HTMLElement).style.setProperty(
      `--${v.key}`,
      v.value,
    );
  });
};

// FIXME temporarily added this for development, remove
const testBranding = {
  apiRoot: '',
  brand: 'Nimvelo',
  partnerId: '5',
  website: 'https://nimvelo.com',
  companyLogoSrc: '',

  branding: {
    supportEmail: '',
    supportPhone: '',
    termsUrl: '',
    refundsUrl: '',
    contactsUrl: '',
    helpUrl: '',
    twitter: '',
    twitterUrl: '',
    forgotPasswordLocation: '',

    // styles
    fontFamily: 'Open Sans',
    fontImportUrl:
      'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap',
    colorPrimary: '#4444ee',
    colorSecondary: '#1111aa',
    colorSuccess: 'green',
    colorDanger: 'red',
    colorWarning: 'orange',
    colorInfo: 'blue',
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700,
      bold: 700,
    },
    topbarInverted: false,
  },

  apps: {
    manager: {
      enabled: true,
      color1: '#4444ee',
      color2: '#1111aa',
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      favicons: [],
      url: '',
    },
  },
};

export const fetchBranding = async () => {
  const brandingAPI =
    process.env.NODE_ENV === 'production'
      ? '/branding_api'
      : 'https://api.branding.partner.sipcentric.com/branding';

  const headers = {};
  headers['X-Partner-Id'] = X_PARTNER_ID.get();

  try {
    const request = await fetch(brandingAPI, {
      headers,
    });
    let brand = await request.json();
    brand = brand.data;
    if (brand) {
      return brand;
    }
    return testBranding;
  } catch {}
};

const faviconsToHead = (favicons: { name: string; location: string }[]) => {
  const faviconToElementMap = new Map([
    [
      'apple-touch-icon.png',
      `<link rel="apple-touch-icon" sizes="57x57" href="`,
    ],
    [
      'android-chrome-512x512.png',
      `<link rel="icon" type="image/png" sizes="512x512" href="`,
    ],
    [
      'android-chrome-192x192.png',
      `<link rel="icon" type="image/png" sizes="192x192" href="`,
    ],
    [
      'favicon-16x16.png',
      `<link rel="icon" type="image/png" sizes="16x16" href="`,
    ],
    [
      'favicon-32x32.png',
      `<link rel="icon" type="image/png" sizes="32x32" href="`,
    ],
    ['mstile-150x150.png', `<meta name="msapplication-TileImage" content="`],
    ['site.webmanifest', `<link rel="manifest" href="`],
    ['browserconfig.xml', `<meta name="msapplication-config" content="`],
    // * omit these because they aren't defined in browserconfig.xml
    // [ 'mstile-70x70.png', `<meta name="msapplication-TileImage" content="` ],
    // [ 'mstile-144x144.png', `<meta name="msapplication-TileImage" content="` ],
    // [ 'mstile-310x150.png', `<meta name="msapplication-TileImage" content="` ],
    // [ 'mstile-310x310.png', `<meta name="msapplication-TileImage" content="` ],
  ]);
  let faviconElements = `
  <meta name="msapplication-TileColor" content="#FFFFFF">
  <meta name="theme-color" content="#FFFFFF">`;

  favicons.forEach((favicon) => {
    const faviconElement = faviconToElementMap.get(favicon.name)
      ? faviconToElementMap.get(favicon.name) + favicon.location + `">`
      : undefined;
    faviconElements += faviconElement || '';
  });

  document.head.innerHTML += faviconElements;
};

export const rebrand = (brand: BrandingAPI) => {
  const { branding } = brand;
  brand.apiRoot = `${brand.apiRoot}/api/v1`;
  if (process.env.NODE_ENV !== 'development') {
    bindNvFetch(brand.apiRoot, brand.branding.loginRedirectUrl);
  } else {
    brand.apiRoot = process.env.REACT_APP_PBX_API;
  }
  let fontStyle = `<link href="${branding.fontImportUrl}" rel="stylesheet">`;
  if (branding.fontTabularImportUrl) {
    fontStyle += `\n<link href="${branding.fontTabularImportUrl}" rel="stylesheet">`;
  }
  document.head.innerHTML += fontStyle;

  const variables = [
    { key: 'font-family', value: branding.fontFamily },
    {
      key: 'font-family-tabular',
      value: branding.fontTabularFamily || branding.fontFamily,
    },
    { key: 'font-weight-light', value: branding.fontWeights.light },
    { key: 'font-weight-regular', value: branding.fontWeights.regular },
    { key: 'font-weight-semibold', value: branding.fontWeights.semibold },
    { key: 'font-weight-bold', value: branding.fontWeights.bold },
    { key: 'primary', value: branding.colorPrimary },
    { key: 'secondary', value: branding.colorSecondary },
    { key: 'success', value: branding.colorSuccess },
    { key: 'danger', value: branding.colorDanger },
    { key: 'info', value: branding.colorInfo },
    { key: 'warning', value: branding.colorWarning },
    { key: 'light-copy-colour', value: '#4d4d4f' },
    { key: 'dark-copy-colour', value: '#ffffff' },
    { key: 'light-background-colour', value: '#ffffff' },
    { key: 'dark-background-colour', value: '#4d4d4f' },
    { key: 'warning', value: branding.colorWarning },
    { key: 'off-black-colour', value: '#363531' },
    { key: 'off-black-colour-copy', value: '#ffffff' },
    { key: 'darker-grey-colour', value: '#424140' },
    { key: 'darker-grey-colour-copy', value: '#ffffff' },
    { key: 'dark-grey-colour', value: '#797876' },
    { key: 'dark-grey-colour-copy', value: '#ffffff' },
    { key: 'medium-grey-colour', value: '#bbbbbb' },
    { key: 'medium-grey-colour-copy', value: '#ffffff' },
    { key: 'light-grey-colour', value: '#dadada' },
    { key: 'light-grey-colour-copy', value: '#4d4d4f' },
    { key: 'lighter-grey-colour', value: '#efefef' },
    { key: 'lighter-grey-colour-copy', value: '#4d4d4f' },
    { key: 'off-white-colour', value: '#efefef' },
    { key: 'off-white-colour-copy', value: '#4d4d4f' },
  ];
  jsToCSSVariable(variables);
  faviconsToHead(brand.apps.manager.favicons);
};

export const appsObjectToArray = (apps: BrandingAPI['apps']) =>
  Object.keys(apps)
    .map((appName) => {
      const app = apps[appName];
      if (!!app.hideFromMenu) return null;
      // * capitalise label e.g. manager -> Manager
      const label = appName[0].toUpperCase() + appName.slice(1);
      // * decide app href
      let href;
      if (app.url) {
        href = app.url;
      } else {
        switch (appName) {
          case 'analytics':
            href = '/apps/analytics';
            break;
          case 'manager':
            href = '/';
            break;
          case 'communicator':
            href = '/apps/phone';
            break;
          default:
            break;
        }
      }
      // * icon
      const iconRoot =
        process.env.NODE_ENV === 'production' ? '' : 'https://my.nimvelo.com';
      // * basic icon with color1
      let icon = `${iconRoot}/branding/icons?name=${appName}&color1=${app.color1.replace(
        '#',
        '',
      )}`;
      // * add color2 if it exists
      icon += app.color2 ? `&color2=${app.color2.replace('#', '')}` : '';
      return {
        href,
        icon,
        label,
      };
    })
    .filter((app) => app !== null);

export const initialBranding: BrandingAPI = {
  apiRoot: '',
  brand: '',
  companyLogoSrc: '',
  productLogoSrc: '',
  partnerId: '',
  website: '',
  branding: {
    fontWeights: {
      light: '300',
      regular: '400',
      semibold: '500',
      bold: '700',
    },
    fontFamily: 'Open Sans , sans-serif',
    fontImportUrl:
      'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap',
    colorPrimary: '#aaa',
    colorSecondary: '#ddd',
    topbarInverted: false,
    colorSuccess: '#53ca6f',
    colorDanger: '#dc3545',
    colorWarning: '#ffc107',
    colorInfo: '#17a2b8',
    supportEmail: '',
    supportPhone: '',
    termsUrl: '',
    refundsUrl: '',
    contactsUrl: '',
    helpUrl: '',
    forgotPasswordLocation: '/faces/forgotPassword.xhtml',
    twitter: '',
    twitterUrl: '',
    signUpUrl: '',
  },
  apps: {
    analytics: {
      color1: '#aaa',
      color2: '',
      favicons: [],
      url: '',
    },
    communicator: {
      color1: '#aaa',
      color2: '',
      favicons: [],
      url: '',
    },
    manager: {
      color1: '#aaa',
      color2: '',
      favicons: [],
      url: '',
    },
  },
};
