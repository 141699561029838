import { DateTime } from 'luxon';
import { APIBillingInvoice } from '../interfaces/Billing';
import { APICallRecording } from '../interfaces/CallRecordings';
import { APICall } from '../interfaces/Calls';
import { APICallsSummary } from '../interfaces/CallsSummary';
import { APICustomer } from '../interfaces/Customer';
import { APIExtension } from '../interfaces/Extensions';
import { APIOutgoingCLI } from '../interfaces/OutgoingCallerId';
import { APIRecordingSummary } from '../interfaces/RecordingSummary';
import { APISMS } from '../interfaces/SMS';
import { APISMSSummary } from '../interfaces/SMSSummary';
import { APISoundMusic, APISoundPrompt } from '../interfaces/Sounds';
import Things from '../interfaces/Things';
import { completelyFetchResource } from '../store/fetchHelpers';
import { nvFetch } from './nvFetch';
import { chooseUnusedExtension } from './utils';

const NUM_RETRIES = 5;

const isErrorExtensionAlreadyInUse = (e: Error) =>
  e.message === 'The short number specified is already in use.';

export const createExtension = async (
  extensions: APIExtension[],
  customerId: string,
  accessToken: string,
) => {
  const extensionNumbers = extensions.map((e) => e.shortNumber);

  for (let i = 0; i < NUM_RETRIES; i += 1) {
    const unusedExtension = chooseUnusedExtension(extensionNumbers);

    try {
      const resultingExtension = (await nvFetch(
        `customers/${customerId}/endpoints`,
        accessToken,
        'POST',
        {
          callRecording: 'OFF',
          name: `Extension ${unusedExtension}`,
          shortNumber: unusedExtension,
          timeout: 30,
          type: 'phone',
        },
      )) as APIExtension;

      if (resultingExtension) {
        return resultingExtension;
      }
    } catch (err) {
      if (!isErrorExtensionAlreadyInUse(err)) {
        throw err;
      }
      extensionNumbers.push(unusedExtension);
    }
  }

  throw new Error(
    `Cannot find an available extension after ${NUM_RETRIES} retries`,
  );
};

type RequestOptions = {
  pageIndex: number;
  pageSize: number;
  sortKey?: string;
  sortAscending: boolean;
  type?: string;
};

export const getCallsInInterval = async (
  requestOptions: RequestOptions,
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
  extraParams?: { from?: string; to?: string; createdBefore?: string },
) => {
  const { from, to, createdBefore } = extraParams ?? {};
  let requestUrl = `customers/${customerId}/calls?page=${requestOptions.pageIndex +
    1}&pageSize=${requestOptions.pageSize}`;
  if (intervalStart && intervalEnd) {
    requestUrl += `&startedBefore=${intervalEnd}&startedAfter=${intervalStart}`;
  }
  requestUrl += createdBefore ? `&createdBefore=${createdBefore}` : '';
  requestUrl += from ? `&from=*${from}*` : '';
  requestUrl += to ? `&to=*${to}*` : '';
  requestUrl += '&includeLocal=true';
  if (requestOptions.sortKey) {
    const { sortKey, sortAscending } = requestOptions;
    const key = sortAscending ? sortKey : `-${sortKey}`;
    requestUrl += `&sort=${key}`;
  }

  const callsList = (await nvFetch(requestUrl, accessToken, 'GET')) as Things<
    APICall
  >;
  return callsList;
};
export const getCallsInIntervalForTable = async (
  requestOptions: RequestOptions,
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
  extraParams?: { from?: string; to?: string; createdBefore?: string },
) => {
  const calls = await getCallsInInterval(
    requestOptions,
    customerId,
    accessToken,
    intervalStart,
    intervalEnd,
    extraParams,
  );
  return {
    data: calls.items || [],
    pageCount: Math.ceil(calls.totalItems / calls.pageSize) || 1,
  };
};

export const getRecentCalls = async (
  requestOptions: RequestOptions,
  customerId: string,
  accessToken: string,
) => {
  const now = DateTime.utc();
  const startOfMonth = now.startOf('month').toISO();
  const endOfMonth = now
    .startOf('month')
    .plus({ months: 1 })
    .toISO();

  return getCallsInIntervalForTable(
    requestOptions,
    customerId,
    accessToken,
    startOfMonth,
    endOfMonth,
  );
};

export const getCallsSummary = async (
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
) => {
  const requestUrl = `customers/${customerId}/calls/summary?startedBefore=${intervalEnd}&startedAfter=${intervalStart}`;

  const callsSummary = ((await nvFetch(
    requestUrl,
    accessToken,
    'GET',
  )) as unknown) as APICallsSummary;
  return callsSummary;
};

export const getCustomers = async (
  requestOptions: { pageIndex: number; pageSize: number; accountType?: string },
  accessToken: string,
) => {
  const page = requestOptions.pageIndex + 1;
  let requestUrl = `customers?page=${page}&pageSize=${requestOptions.pageSize}`;
  if (requestOptions.accountType) {
    requestUrl += `&accountType=${requestOptions.accountType}`;
  }
  const customersList = (await nvFetch(
    requestUrl,
    accessToken,
    'GET',
  )) as Things<APICustomer>;
  const pageCount = Math.ceil(
    customersList.totalItems / customersList.pageSize,
  );
  return {
    data: customersList.items,
    pageCount,
  };
};

export const getSounds = async (
  accessToken: string,
  customerId: string,
  requestOptions: { pageIndex: number; pageSize: number } = {
    pageIndex: 0,
    pageSize: 200,
  },
  type?: 'prompt' | 'music',
) => {
  const page = requestOptions.pageIndex + 1;
  const requestUrl = `customers/${customerId}/sounds?pageSize=${requestOptions.pageSize}&page=${page}&type=${type}`;

  const sounds = (await nvFetch(requestUrl, accessToken, 'GET')) as Things<
    APISoundMusic | APISoundPrompt
  >;

  return sounds;
};

export const createEndpoint = async (
  accessToken: string,
  customerId: string,
  body: any,
) => {
  const requestUrl = `customers/${customerId}/endpoints`;
  const res = await nvFetch(requestUrl, accessToken, 'POST', body);
  return res;
};

export const getOutgoingCallerIds = async (
  accessToken: string,
  customerId: string,
) => {
  const resItems: APIOutgoingCLI[] = [];
  await completelyFetchResource<APIOutgoingCLI>(
    'outgoingcallerids',
    '',
    { pageSize: 200 },
    accessToken,
    customerId,
    (items) => {
      resItems.push(...items);
    },
  );

  return resItems;
};

export const getBillingInvoices = async (
  accessToken: string,
  customerId: string,
  requestOptions: {
    pageIndex: number;
    pageSize: number;
    paymentMethodAdded?: boolean;
  } = {
    pageIndex: 0,
    pageSize: 200,
  },
) => {
  const page = requestOptions.pageIndex + 1;
  let requestUrl = `customers/${customerId}/billing/invoices?page=${page}&pageSize=${requestOptions.pageSize}`;
  if (requestOptions.paymentMethodAdded !== undefined) {
    requestUrl += `&paymentMethodAdded=${requestOptions.paymentMethodAdded}`;
  }
  const invoices = (await nvFetch(requestUrl, accessToken)) as Things<
    APIBillingInvoice
  >;
  return invoices;
};

export const getBillingInvoicesForTable = async (
  accessToken: string,
  customerId: string,
  pageIndex: number,
  pageSize: number,
  paymentMethodAdded?: boolean,
) => {
  const invoices = await getBillingInvoices(accessToken, customerId, {
    pageIndex,
    pageSize,
    paymentMethodAdded,
  });
  return {
    data: invoices.items,
    pageCount: Math.ceil(invoices.totalItems / invoices.pageSize),
  };
};

export const getRecentInvoices = async (
  accessToken: string,
  customerId: string,
) => {
  const invoices = await getBillingInvoices(accessToken, customerId, {
    pageIndex: 0,
    pageSize: 5,
  });
  return invoices.items;
};

export const generateAuthToken = async (accessToken: string, quantity = 1) => {
  const tokens: string[] = [];
  const res = (await nvFetch(
    `tokengenerator/multi?qty=${quantity}`,
    accessToken,
  )) as any;
  res.forEach((token: { id: string }) => tokens.push(token.id));
  return tokens;
};

export const getRecordingSummary = async (
  customerId: string,
  accessToken: string,
) => {
  const requestUrl = `customers/${customerId}/recordings/summary`;
  const summary = ((await nvFetch(
    requestUrl,
    accessToken,
  )) as unknown) as APIRecordingSummary;
  return summary;
};

export const getRecordings = async (
  requestOptions: RequestOptions,
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
  extraParams?: { partyId?: string; createdBefore?: string },
) => {
  const { partyId, createdBefore } = extraParams ?? {};
  let requestUrl = `customers/${customerId}/recordings?page=${requestOptions.pageIndex +
    1}&pageSize=${requestOptions.pageSize}`;
  if (intervalStart && intervalEnd) {
    requestUrl += `&startedBefore=${intervalEnd}&startedAfter=${intervalStart}`;
  }
  requestUrl += partyId ? `&partyId=*${partyId}*` : '';
  requestUrl += createdBefore ? `&createdBefore=${createdBefore}` : '';
  requestUrl += '&includeLocal=true';
  if (requestOptions.sortKey) {
    const { sortKey, sortAscending } = requestOptions;
    const key = sortAscending ? sortKey : `-${sortKey}`;
    requestUrl += `&sort=${key}`;
  }

  const recordings = (await nvFetch(requestUrl, accessToken, 'GET')) as Things<
    APICallRecording
  >;
  return recordings;
};

export const addCredits = async (
  customerId: string,
  accessToken: string,
  amount: number,
) => {
  const requestUrl = `customers/${customerId}/creditstatus/credits`;
  await nvFetch(requestUrl, accessToken, 'POST', {
    amount,
    type: 'callcredit',
  });
};

export const getSMS = async (
  requestOptions: RequestOptions,
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
  extraParams?: { from?: string; to?: string },
) => {
  const { from, to } = extraParams ?? {};
  let requestUrl = `customers/${customerId}/sms?page=${requestOptions.pageIndex +
    1}&pageSize=${requestOptions.pageSize}`;
  if (intervalStart && intervalEnd) {
    requestUrl += `&createdBefore=${intervalEnd}&createdAfter=${intervalStart}`;
  }
  requestUrl += from ? `&from=*${from}*` : '';
  requestUrl += to ? `&to=*${to}*` : '';
  requestUrl += '&includeLocal=true';
  if (requestOptions.sortKey) {
    const { sortKey, sortAscending } = requestOptions;
    const key = sortAscending ? sortKey : `-${sortKey}`;
    requestUrl += `&sort=${key}`;
  }

  const sms = (await nvFetch(requestUrl, accessToken, 'GET')) as Things<APISMS>;
  return sms;
};
export const getSMSSummary = async (
  customerId: string,
  accessToken: string,
  intervalStart: string,
  intervalEnd: string,
) => {
  const requestUrl = `customers/${customerId}/sms/summary?createdBefore=${intervalEnd}&createdAfter=${intervalStart}`;

  const smsSummary = ((await nvFetch(
    requestUrl,
    accessToken,
    'GET',
  )) as unknown) as APISMSSummary;
  return smsSummary;
};
