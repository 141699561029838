import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Registrations } from '../actions';
import * as toastActions from '../toasts/actions';

import { APIRegistration } from '../../interfaces/Registrations';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { nvFetch } from '../../helpers/nvFetch';
import { fetchMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(Registrations.clear).json;
};

export const getRegistrations = (endpointId: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Registrations.fetching).json);

    let data: Things<APIRegistration>;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/endpoints/${endpointId}/sip/registrations`,
        accessToken,
      )) as Things<APIRegistration>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('connected devices', true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(Registrations.fetchingDone).json);

      return;
    }

    dispatch(new SuccessAction(Registrations.fetchingDone).json);

    if (!data) {
      return;
    }

    dispatch(
      new SuccessAction(Registrations.receive, {
        items: data.items || [],
      }).json,
    );
  };
};
