import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { BargeGroups } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APIBargeGroup } from '../../interfaces/BargeGroups';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction } from '../helpers';

/**
 * Clear barge groups
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(BargeGroups.clear).json;
};

/**
 * Get barge groups from pbx API
 */
export const getBargeGroups = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'bargegroups',
    (terms) => terms.team.other,
    {
      fetching: BargeGroups.fetching,
      fetchingDone: BargeGroups.fetchingDone,
      receive: BargeGroups.receive,
    },
    { pageSize: 200 },
  );

/**
 * Save the customer's barge groups from pbx API
 */
export const saveBargeGroups = (bargeGroup: APIBargeGroup): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(BargeGroups.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/bargegroups/${bargeGroup.id}`,
        accessToken,
        'PUT',
        bargeGroup,
      )) as Things<APIBargeGroup>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.bargegroup.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BargeGroups.savingDone).json);
    }

    dispatch(new SuccessAction(BargeGroups.savingDone).json);
    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(BargeGroups.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.bargegroup.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Delete the customer's barge group from pbx API
 */
export const removeBargeGroup = (bargeGroup: APIBargeGroup): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = bargeGroup;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(BargeGroups.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/bargegroups/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.bargegroup.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BargeGroups.savingDone).json);
    }
    dispatch(new SuccessAction(BargeGroups.savingDone).json);
    dispatch(
      new SuccessAction(BargeGroups.remove, {
        item: bargeGroup,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.bargegroup.one),
        type: 'success',
      }),
    );
  };
};

export const createBargeGroup = (name: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(BargeGroups.saving).json);
    let resultingBargeGroup;
    try {
      resultingBargeGroup = (await nvFetch(
        `customers/${activeCustomerId}/bargegroups`,
        accessToken,
        'POST',
        {
          managers: [],
          members: [],
          name,
          readOnly: false,
          type: 'bargegroup',
        },
      )) as Things<APIBargeGroup>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.bargegroup.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BargeGroups.savingDone).json);
    }
    dispatch(new SuccessAction(BargeGroups.savingDone).json);
    if (!resultingBargeGroup) return;

    dispatch(
      new SuccessAction(BargeGroups.receive, {
        items: [resultingBargeGroup],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.bargegroup.one),
        type: 'success',
      }),
    );
  };
};
