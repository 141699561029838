import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Sounds } from '../actions';
import { getSounds as getSoundsHelper } from '../../helpers/api';

import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { APISoundMusic, APISoundPrompt } from '../../interfaces/Sounds';
import { nvFetch } from '../../helpers/nvFetch';
import { encodeBase64Sound } from '../../helpers/utils';
import { accountConfigSelector } from '../../selectors/customer';
import {
  createMsg,
  fetchMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';

export const clearPrompts = (): ActionInterface => {
  return new SuccessAction(Sounds.clearPrompts).json;
};
export const clearMusic = (): ActionInterface => {
  return new SuccessAction(Sounds.clearMusic).json;
};

const getSounds = (
  type: 'prompt' | 'music',
  searchParams: { pageIndex: number; pageSize: number } = {
    pageIndex: 0,
    pageSize: 200,
  },
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(Sounds.fetching).json);
    const { activeCustomerId, accessToken } = getState().user;
    let data;
    try {
      data = await getSoundsHelper(
        accessToken,
        activeCustomerId,
        searchParams,
        type,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(
            `${terms.sound.other} (${type})`,
            true,
            error.message,
          ),
          type: 'danger',
        }),
      );

      return;
    }

    if (!data || !data.items) {
      dispatch(new SuccessAction(Sounds.fetchingDone).json);

      return;
    }
    if (type === 'music') {
      dispatch(
        new SuccessAction(Sounds.receiveMusic, {
          items: data.items,
        }).json,
      );
    } else {
      dispatch(
        new SuccessAction(Sounds.receivePrompts, {
          items: data.items,
        }).json,
      );
    }

    if (data.nextPage) {
      getSounds(type, {
        pageIndex: data.page,
        pageSize: searchParams.pageSize,
      })(dispatch, getState);
    } else {
      dispatch(new SuccessAction(Sounds.fetchingDone).json);
    }
  };
};

const removeSound = (
  type: 'music' | 'prompt',
  sound: APISoundMusic | APISoundPrompt,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = sound;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Sounds.saving).json);
    try {
      await nvFetch(
        `customers/${activeCustomerId}/sounds/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(
            `${terms.sound.one} (${type})`,
            true,
            error.message,
          ),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(Sounds.savingDone).json);
    }
    dispatch(new SuccessAction(Sounds.savingDone).json);
    if (type === 'music') {
      dispatch(
        new SuccessAction(Sounds.removeMusic, {
          item: sound as APISoundMusic,
        }).json,
      );
    } else {
      dispatch(
        new SuccessAction(Sounds.removePrompt, {
          item: sound as APISoundPrompt,
        }).json,
      );
    }

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(`${terms.sound.one} (${type})`),
        type: 'success',
      }),
    );
  };
};

const saveSound = (
  type: 'music' | 'prompt',
  sound: APISoundMusic | APISoundPrompt,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Sounds.saving).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${activeCustomerId}/sounds/${sound.id}`,
        accessToken,
        'PUT',
        sound,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(`${terms.sound.one} (${type})`, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Sounds.savingDone).json);
    }
    dispatch(new SuccessAction(Sounds.savingDone).json);
    if (!data) {
      return;
    }
    if (type === 'music') {
      dispatch(
        new SuccessAction(Sounds.receiveMusic, {
          items: [data],
        }).json,
      );
    } else {
      dispatch(
        new SuccessAction(Sounds.receivePrompts, {
          items: [data],
        }).json,
      );
    }
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(`${terms.sound.one} (${type})`),
        type: 'success',
      }),
    );
  };
};

export const getPromptSounds = () => getSounds('prompt');
export const getMusicSounds = () => getSounds('music');

export const removePromptSound = (prompt: APISoundPrompt) =>
  removeSound('prompt', prompt);
export const removeMusicSound = (music: APISoundMusic) =>
  removeSound('music', music);

export const savePromptSound = (prompt: APISoundPrompt) =>
  saveSound('prompt', prompt);
export const saveMusicSound = (music: APISoundMusic) =>
  saveSound('music', music);

export const createSound = (type: 'music' | 'prompt', sound: File): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Sounds.saving).json);
    let resultingSound: any;
    let uploadedSound: any;
    const name =
      sound.name.substr(0, sound.name.lastIndexOf('.')) || sound.name;
    const body =
      type === 'music' ? { enabled: true, name, type } : { name, type };

    try {
      resultingSound = await nvFetch(
        `customers/${activeCustomerId}/sounds`,
        accessToken,
        'POST',
        body,
      );
    } catch (error) {
      const err = error as any;
      const errorMessage = err.validationErrors?.[0]?.error || err.message;
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(
            `${terms.sound.one} (${type})`,
            true,
            errorMessage,
          ),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Sounds.savingDone).json);
    }

    dispatch(new SuccessAction(Sounds.savingDone).json);
    if (!resultingSound) return;
    try {
      const b64Sound = await encodeBase64Sound(sound);
      uploadedSound = await nvFetch(
        `customers/${activeCustomerId}/sounds/${resultingSound.id}`,
        accessToken,
        'PUT',
        b64Sound,
        {
          Accept: '*/*',
          'Content-Type': sound.type,
          'X-Content-Transfer-Encoding': 'base64',
          'X-Relationship-Key': 'id',
        },
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: `There was a problem uploading this ${terms.sound.one} (${type}) [${error.message}]`,
          type: 'danger',
        }),
      );

      return;
    }

    if (!uploadedSound)
      return dispatch(new SuccessAction(Sounds.savingDone).json);
    if (type === 'music') {
      dispatch(
        new SuccessAction(Sounds.receiveMusic, {
          items: [uploadedSound],
        }).json,
      );
    } else {
      dispatch(
        new SuccessAction(Sounds.receivePrompts, {
          items: [uploadedSound],
        }).json,
      );
    }

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(`${terms.sound.one} (${type})`),
        type: 'success',
      }),
    );
  };
};
