import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { BlockingRules } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APIBlockingRule } from '../../interfaces/BlockingRules';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { blockInvalid, ThunkFunction, validityAction } from '../helpers';

export const clear = (): ActionInterface => {
  return new SuccessAction(BlockingRules.clear).json;
};

export const getBlockingRules = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'blockingrules',
    (terms) => terms.team.other,
    {
      fetching: BlockingRules.fetching,
      fetchingDone: BlockingRules.fetchingDone,
      receive: BlockingRules.receive,
    },
    { pageSize: 200 },
  );

export const saveBlockingRule = (blockingRule: APIBlockingRule): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    try {
      blockInvalid(getState().blockingrules.valid, dispatch, toastActions.push);
    } catch {
      return;
    }
    const { terms } = accountConfigSelector(getState());
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(BlockingRules.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/blockingrules/${blockingRule.id}`,
        accessToken,
        'PUT',
        {
          ...blockingRule,
          matchString:
            blockingRule.matchRule !== 'ANONYMOUS'
              ? blockingRule.matchString
              : undefined,
        },
      )) as Things<APIBlockingRule>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.blockingRules.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BlockingRules.savingDone).json);
    }

    dispatch(new SuccessAction(BlockingRules.savingDone).json);
    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(BlockingRules.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.blockingRules.one),
        type: 'success',
      }),
    );
  };
};

export const removeBlockingRule = (blockingRule: APIBlockingRule): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = blockingRule;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(BlockingRules.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/blockingrules/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.blockingRules.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BlockingRules.savingDone).json);
    }
    dispatch(new SuccessAction(BlockingRules.savingDone).json);
    dispatch(
      new SuccessAction(BlockingRules.remove, {
        item: blockingRule,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.blockingRules.one),
        type: 'success',
      }),
    );
  };
};

export const createBlockingRule = (body: Partial<APIBlockingRule>): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(BlockingRules.saving).json);
    let newBlockingRule;
    try {
      newBlockingRule = (await nvFetch(
        `customers/${activeCustomerId}/blockingrules`,
        accessToken,
        'POST',
        {
          type: body.type,
          matchRule: body.matchRule,
          matchString: body.matchString,
        },
      )) as Things<APIBlockingRule>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.blockingRules.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(BlockingRules.savingDone).json);
    }
    dispatch(new SuccessAction(BlockingRules.savingDone).json);
    if (!newBlockingRule) return;

    dispatch(
      new SuccessAction(BlockingRules.receive, {
        items: [newBlockingRule],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.blockingRules.one),
        type: 'success',
      }),
    );
  };
};

export const updateValidity = (
  property: keyof APIBlockingRule,
  valid: boolean,
) =>
  validityAction(
    property,
    valid,
    'blockingrules',
    BlockingRules.updateValidity,
  );
