import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { Phonebook } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { APIPhonebook } from '../../interfaces/Phonebook';
import Things from '../../interfaces/Things';
import {
  createMsg,
  fetchMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { accountConfigSelector } from '../../selectors/customer';

export const clear = (): ActionInterface => {
  return new SuccessAction(Phonebook.clear).json;
};

export const getPhonebook = (requestOptions: {
  pageIndex: number;
  pageSize: number;
  sortKey?: string;
  sortAscending: boolean;
}): any => {
  return async (
    dispatch: (Action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(Phonebook.fetching).json);
    const { activeCustomerId, accessToken } = getState().user;
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/phonebook?page=${requestOptions.pageIndex +
          1}&pageSize=${requestOptions.pageSize}`,
        accessToken,
      )) as Things<APIPhonebook>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(terms.phonebook.other, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(Phonebook.fetchingDone).json);
      return;
    }
    dispatch(new SuccessAction(Phonebook.fetchingDone).json);

    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(Phonebook.receive, {
        pageCount: Math.ceil(data.totalItems / data.pageSize),
        pageSize: data.pageSize,
        phonebook: data.items || [],
      }).json,
    );
  };
};

export const createPhonebookEntry = (phonebook: APIPhonebook): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;

    dispatch(new SuccessAction(Phonebook.saving).json);
    let createdEntry;
    try {
      createdEntry = (await nvFetch(
        `customers/${activeCustomerId}/phonebook`,
        accessToken,
        'POST',
        phonebook,
      )) as APIPhonebook;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.phonebook.one + ' entry', true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(Phonebook.savingDone).json);
    }
    dispatch(new SuccessAction(Phonebook.savingDone).json);
    if (!createdEntry) return;

    // Append new phonebook to existing phonebook array if the table isnt full
    const { phonebookForTable, pageCount, pageSize } = getState().phonebook;
    if (phonebookForTable.length !== pageSize) {
      const newPhonebook = [...phonebookForTable, createdEntry];
      dispatch(
        new SuccessAction(Phonebook.receive, {
          pageCount,
          pageSize,
          phonebook: newPhonebook,
        }).json,
      );
    }

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.phonebook.one + ' entry'),
        type: 'success',
      }),
    );
  };
};

export const savePhonebookEntry = (phonebook: APIPhonebook): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Phonebook.saving).json);
    let phonebookEntry: APIPhonebook;
    try {
      phonebookEntry = (await nvFetch(
        `customers/${activeCustomerId}/phonebook/${phonebook.id}`,
        accessToken,
        'PUT',
        phonebook,
      )) as APIPhonebook;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.phonebook.one + ' entry', true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(Phonebook.savingDone).json);
    }
    dispatch(new SuccessAction(Phonebook.savingDone).json);
    if (!phonebookEntry) return;

    const { phonebookForTable, pageCount } = getState().phonebook;
    const newPhonebook = [...phonebookForTable];

    // find phonebook that has been changed and replace it
    const foundIndex = phonebookForTable.findIndex(
      (pb) => pb.id === phonebookEntry.id,
    );
    if (foundIndex === -1) return;
    newPhonebook.splice(foundIndex, 1, phonebookEntry);

    dispatch(
      new SuccessAction(Phonebook.receive, {
        pageCount,
        phonebook: newPhonebook,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: saveMsg(terms.phonebook.one + ' entry'),
        type: 'success',
      }),
    );
  };
};

export const removePhonebookEntry = (phonebook: APIPhonebook): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Phonebook.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/phonebook/${phonebook.id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(
            terms.phonebook.one + ` entry (${phonebook.name})`,
            true,
            error.message,
          ),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(Phonebook.savingDone).json);
    }
    dispatch(new SuccessAction(Phonebook.savingDone).json);
    dispatch(
      new SuccessAction(Phonebook.remove, {
        item: phonebook,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.phonebook.one + ' entry'),
        type: 'success',
      }),
    );
  };
};
