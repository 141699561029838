import deepFreeze from 'deep-freeze';

import { LinkedUser } from '../actions';

import StateLinkedUser, { APILinkedUser } from '../../interfaces/LinkedUsers';
import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: StateLinkedUser = {
  activeLinkedUser: undefined,
  error: '',
  fetching: false,
  linkedUsersById: {},
  saving: false,
  valid: {
    email: true,
  },
};

/**
 * Clear linked user
 */
const clear = (state: StateLinkedUser = initialState): StateLinkedUser => {
  return { ...state, linkedUsersById: {} };
};

/**
 * Clear active linked user
 */
const clearActive = (
  state: StateLinkedUser = initialState,
): StateLinkedUser => {
  return { ...state, activeLinkedUser: undefined };
};

/**
 * Fetch all fields for current linkeduser
 */
const fetching = (state: StateLinkedUser = initialState): StateLinkedUser => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Fetch all fields for current linkeduser
 */
const fetchingActive = (
  state: StateLinkedUser = initialState,
): StateLinkedUser => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive all fields for active linkeduser
 */
const receive = (
  state: StateLinkedUser = initialState,
  receivedLinkedUsers: APILinkedUser[] = [],
): StateLinkedUser => {
  const ProcessedLinkedUsers = receivedLinkedUsers.map((linkedUser) => ({
    ...linkedUser,
    created: new Date(linkedUser.created),
  }));
  const { idMap } = arrayToIdMapAndOrdering(ProcessedLinkedUsers, 'id');

  return {
    ...state,
    linkedUsersById: {
      ...state.linkedUsersById,
      ...idMap,
    },
  };
};

const saving = (state: StateLinkedUser = initialState): StateLinkedUser => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: StateLinkedUser = initialState): StateLinkedUser => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Receive all fields for current linkeduser
 */
const receiveActive = (
  state: StateLinkedUser = initialState,
  activeLinkedUser: APILinkedUser,
): StateLinkedUser => {
  const processedLinkedUser = {
    ...activeLinkedUser,
    created: new Date(activeLinkedUser.created),
  };
  return {
    ...state,
    activeLinkedUser: processedLinkedUser,
    // linkedUsersById: {
    //   ...state.linkedUsersById,
    //   [processedLinkedUser.id]: processedLinkedUser,
    // },
  };
};

const savingActive = (
  state: StateLinkedUser = initialState,
): StateLinkedUser => {
  return {
    ...state,
    saving: true,
  };
};

const savingDoneActive = (
  state: StateLinkedUser = initialState,
): StateLinkedUser => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: StateLinkedUser = initialState,
  receivedLinkedUsers: APILinkedUser,
): StateLinkedUser => {
  const { [receivedLinkedUsers.id]: _, ...rest } = state.linkedUsersById;
  return {
    ...state,
    linkedUsersById: rest,
    saving: false,
  };
};

/**
 * Complete fetching, success
 */
const fetchingDone = (state = initialState): StateLinkedUser => {
  return { ...{}, ...state, fetching: false };
};

/**
 * Complete fetching, active success
 */
const fetchingDoneActive = (state = initialState): StateLinkedUser => {
  return { ...{}, ...state, fetching: false };
};

export default (state = initialState, action?: ActionInterface) => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case LinkedUser.clear:
      return clear(state);

    case LinkedUser.fetching:
      return fetching(state);

    case LinkedUser.receive:
      return receive(state, action.payload.items);

    case LinkedUser.fetchingDone:
      return fetchingDone(state);

    case LinkedUser.saving:
      return saving(state);

    case LinkedUser.savingDone:
      return savingDone(state);

    case LinkedUser.remove:
      return remove(state, action.payload);

    case LinkedUser.active.clear:
      return clearActive(state);

    case LinkedUser.active.fetching:
      return fetchingActive(state);

    case LinkedUser.active.receive:
      return receiveActive(state, action.payload);

    case LinkedUser.active.fetchingDone:
      return fetchingDoneActive(state);

    case LinkedUser.active.saving:
      return savingActive(state);

    case LinkedUser.active.savingDone:
      return savingDoneActive(state);

    default:
      return state;
  }
};
