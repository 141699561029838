import deepFreeze from 'deep-freeze';

import { Toasts } from '../actions';

import Toast from '../../interfaces/Toast';

import { ActionInterface } from '../../helpers/actionBuilder';

let nextId = 0;

export const initialState: Toast[] = [];

const defaults: Toast = {
  delay: 0, // Permanent
  message: '',
  type: 'primary',
};

/**
 * Clear toasts
 */
const clear = (): Toast[] => {
  return [...[], ...initialState];
};

/**
 * Add a new toast
 */
const push = (toasts: Toast[], toast: Toast): Toast[] => {
  const toastWithID: Toast = { ...defaults, ...toast };
  toastWithID.id = nextId++;

  return toasts.concat(toastWithID);
};

/**
 * Mark a toast as ready for deletion
 */
const preremove = (toasts: Toast[], id?: number): Toast[] => {
  // Mark a specific toast
  if (typeof id === 'number' && id >= 0) {
    return toasts.map(
      (toast: Toast): Toast => {
        if (toast.id === id) {
          return { ...toast, removing: true };
        } else {
          return toast;
        }
      },
    );
  }

  // Mark the front toast
  const markedToasts: Toast[] = [];
  return markedToasts
    .concat([{ ...toasts[0], removing: true }])
    .concat(toasts.slice(1));
};

/**
 * Remove from front of queue
 */
const shift = (toasts: Toast[]): Toast[] => {
  return toasts.slice(1);
};

/**
 * Remove a specific toast
 */
const remove = (toasts: Toast[], id: number): Toast[] => {
  return toasts.filter((toast: Toast): boolean => toast.id !== id);
};

interface ToastsAction extends ActionInterface {
  payload: {
    id?: number;
    toast?: Toast;
  };
}

export default (
  state: Toast[] = initialState,
  action?: ToastsAction,
): Toast[] => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return [...[], ...state];
  }

  switch (action.type) {
    case Toasts.clear:
      return clear();

    case Toasts.push:
      return push(state, action.payload.toast as Toast);

    case Toasts.preremove:
      return preremove(state, action.payload.id);

    case Toasts.shift:
      return shift(state);

    case Toasts.remove:
      return remove(state, action.payload.id as number);

    default:
      return state;
  }
};
