import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Billing } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { APIBillingPaymentMethod } from '../../interfaces/Billing';
import Things from '../../interfaces/Things';
import {
  createMsg,
  fetchMsg,
  removeMsg,
} from '../../helpers/actionMessageCreator';

/**
 * Clear billing
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(Billing.clear).json;
};

/**
 * Get billing from pbx API
 */
export const getBilling = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Billing.fetching).json);

    let data; // : Things<APIBilling>;
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/billing`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(new SuccessAction(Billing.fetchingDone).json);
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('billing', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(Billing.fetchingDone).json);
    dispatch(
      new SuccessAction(Billing.receive, {
        billing: data,
      }).json,
    );
  };
};

export const clearBillingEstimate = () => {
  return new SuccessAction(Billing.estimate.clear).json;
};

export const getBillingEstimate = () => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Billing.estimate.fetching).json);

    let data; // : Things<APIBilling>;
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/billing/estimate`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(new SuccessAction(Billing.estimate.fetchingDone).json);
      // Do not display the error toast
      // dispatch(
      //   toastActions.push({
      //     delay: 10000,
      //     message:
      //       'There was a problem fetching billing estimate: ' + error.message,
      //     type: 'danger',
      //   }),
      // );

      return;
    }

    dispatch(new SuccessAction(Billing.estimate.fetchingDone).json);
    dispatch(
      new SuccessAction(Billing.estimate.receive, {
        estimate: data,
      }).json,
    );
  };
};

export const clearBillingPaymentMethods = () => {
  return new SuccessAction(Billing.paymentMethods.clear).json;
};

export const getBillingPaymentMethods = (
  searchParams: { page: number; pageSize: number } = {
    page: 1,
    pageSize: 200,
  },
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Billing.paymentMethods.fetching).json);

    let data; // : Things<APIBillingPaymentMethod>;
    try {
      data = (await nvFetch(
        `customers/${
          getState().user.activeCustomerId
        }/billing/paymentmethods?page=${searchParams.page}&pageSize=${
          searchParams.pageSize
        }`,
        getState().user.accessToken,
      )) as Things<APIBillingPaymentMethod>;
    } catch (error) {
      dispatch(new SuccessAction(Billing.paymentMethods.fetchingDone).json);
      // Do not display the error toast
      // dispatch(
      //   toastActions.push({
      //     delay: 10000,
      //     message:
      //       'There was a problem fetching billing payment methods: ' +
      //       error.message,
      //     type: 'danger',
      //   }),
      // );

      return;
    }

    if (!data || !data.items) {
      dispatch(new SuccessAction(Billing.paymentMethods.fetchingDone).json);

      return;
    }
    dispatch(
      new SuccessAction(Billing.paymentMethods.receive, {
        paymentMethods: data.items,
      }).json,
    );

    if (data.nextPage) {
      getBillingPaymentMethods({
        page: data.page + 1,
        pageSize: searchParams.pageSize,
      })(dispatch, getState);
    } else {
      dispatch(new SuccessAction(Billing.paymentMethods.fetchingDone).json);
    }
  };
};

export const removeBillingPaymentMethod = (
  paymentMethod: APIBillingPaymentMethod,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { id } = paymentMethod;
    const { accessToken, activeCustomerId } = getState().user;

    try {
      await nvFetch(
        `customers/${activeCustomerId}/billing/paymentmethods/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg('payment method', true, error.message),
          type: 'danger',
        }),
      );
      return;
    }

    dispatch(
      new SuccessAction(Billing.paymentMethods.remove, {
        paymentMethod: paymentMethod,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg('payment method'),
        type: 'success',
      }),
    );
  };
};

export const createStripePaymentMethod = (paymentMethodId: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { accessToken, activeCustomerId } = getState().user;

    let paymentMethod;
    try {
      paymentMethod = await nvFetch(
        `customers/${activeCustomerId}/billing/paymentmethods`,
        accessToken,
        'POST',
        { stripeId: paymentMethodId, type: 'stripe' },
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg('payment method', true, error.message),
          type: 'danger',
        }),
      );
      return;
    }

    dispatch(
      new SuccessAction(Billing.paymentMethods.receive, {
        paymentMethods: [paymentMethod],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg('payment method'),
        type: 'success',
      }),
    );
  };
};

export const createSetupIntent = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Billing.stripe.creating).json);
    let setupIntent;
    try {
      const requestUrl = `customers/${activeCustomerId}/billing/stripe/setupintents`;
      setupIntent = await nvFetch(requestUrl, accessToken, 'POST', {});
    } catch {}
    dispatch(new SuccessAction(Billing.stripe.creatingDone).json);
    if (setupIntent) {
      dispatch(new SuccessAction(Billing.stripe.receive, { setupIntent }).json);
    }
  };
};
