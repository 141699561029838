import deepFreeze from 'deep-freeze';

import { User } from '../actions';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState = {
  accessLevel: 'EXTENSION',
  accessToken: '',
  activeCustomerId: '',
  authenticating: false,
  created: new Date(),
  displayName: '',
  email: '',
  expires: new Date(),
  firstName: '',
  id: '',
  lastName: '',
  locale: 'en',
  message: '',
  rememberMe: false,
  type: 'user',
  uri: '',
  userId: '',
  username: '',
};

/**
 * Clear toasts
 */
const clear = () => {
  return { ...{}, ...initialState };
};

/**
 * Auth succeeded, store auth details
 */
const authenticationSucceeded = (
  user = initialState,
  accessToken = initialState.accessToken,
  expires = initialState.expires,
  rememberMe = initialState.rememberMe,
) => {
  const nextUser = { ...{}, ...user };

  nextUser.accessToken = accessToken;
  nextUser.authenticating = false;
  nextUser.expires = expires;
  nextUser.rememberMe = rememberMe;
  nextUser.message = initialState.message;

  return nextUser;
};

const setActiveCustomer = (user = initialState, customerId: string) => {
  return {
    ...user,
    activeCustomerId: customerId ?? '',
  };
};

/**
 * Fetch all fields for currently authed user
 */
const fetching = (user = initialState) => {
  const nextUser = { ...{}, ...user, fetching: true };

  return nextUser;
};

/**
 * Fetch all fields for currently authed user
 */
const receiveUser = (user = initialState, fetchedUser = initialState) => {
  const nextUser = { ...{}, ...user };

  nextUser.accessLevel = fetchedUser.accessLevel;
  nextUser.created = fetchedUser.created;
  nextUser.email = fetchedUser.email;
  nextUser.firstName = fetchedUser.firstName;
  nextUser.id = fetchedUser.id;
  nextUser.lastName = fetchedUser.lastName;
  nextUser.locale = fetchedUser.locale;
  nextUser.message = '';
  nextUser.uri = fetchedUser.uri;
  nextUser.username = fetchedUser.username;
  nextUser.userId = fetchedUser.userId;

  if (!fetchedUser.displayName) {
    nextUser.displayName = fetchedUser.firstName + ' ' + fetchedUser.lastName;
  }

  return nextUser;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (user = initialState) => {
  return { ...{}, ...user, fetching: false };
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  user = initialState,
  message = initialState.message,
) => {
  return { ...{}, ...user, fetching: false, message };
};

/**
 * Log out, clear out the user
 */
const logout = () => {
  return { ...{}, ...initialState };
};

export default (state = initialState, action?: ActionInterface) => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case User.clear:
      return clear();

    case User.authenticatingDone:
      return authenticationSucceeded(
        state,
        action.payload.accessToken,
        action.payload.expires,
        action.payload.rememberMe,
      );

    case User.setActiveCustomer:
      return setActiveCustomer(state, action.payload.customerId);

    case User.fetching:
      return fetching(state);

    case User.receive:
      return receiveUser(state, action.payload);

    case User.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case User.logout:
      return logout();

    default:
      return state;
  }
};
