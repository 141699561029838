import React from 'react';
import { useRouteMatch } from 'react-router';
import { useAccountConfig } from '../../hooks/useAccountConfig';

import { Props } from './interfaces';

/**
 * Manage document title
 */
const DocumentTitle: React.FC<Props> = ({ company }) => {
  const { documentTitles, titles } = useAccountConfig();
  const routes = Array.from(documentTitles.keys());
  const match = useRouteMatch({ /*exact: true, */ path: routes });
  // pathname of matched route
  const matchedRoute = match?.path;
  // use pathname to get title
  const value = documentTitles.get(matchedRoute);
  let title = titles[value?.titleRef]?.other || value?.label || '';

  if (matchedRoute === '/c' && !match?.isExact) {
    // route of /c must be exact
    title = '';
  }
  const documentTitle = [];
  if (company) {
    documentTitle.push(company);
  }
  if (title) {
    documentTitle.push(title);
  }
  document.title = documentTitle.join(' | ');
  return <></>;
};
export default DocumentTitle;
