import React, { ErrorInfo } from 'react';
import { NvErrorPage } from '@simwood/nv-react-components-v2';
import { Props, State } from './interfaces';

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error Boundary Caught:', error, errorInfo);
  }

  render() {
    const { error, hasError } = this.state;
    const errorProps = {
      message: 'An error has occurred, please refresh and try again.',
      title: 'Oops',
      buttonText: '',
      buttonOnClick: () => {
        /** do something */
      },
    };
    if (hasError) {
      if (error.name === 'ChunkLoadError') {
        errorProps.title = '';
        errorProps.message =
          'This application has been updated, please refresh your browser to see the latest content.';
        errorProps.buttonText = 'Reload';
        errorProps.buttonOnClick = () => {
          window.location.reload();
        };
      }
      if (error.message.includes(`'branding' of undefined`)) {
        errorProps.message = `Branding not found`;
        errorProps.title = 'Uh Oh!';
      }
      return <NvErrorPage {...errorProps} />;
    }
    return this.props.children;
  }
}
