export const X_PARTNER_ID = {
  get: () => {
    const partnerIdFromSession = Number(
      window.sessionStorage?.getItem('partnerId'),
    );
    return (partnerIdFromSession || 1).toString();
  },
  set: (partnerId?: string) => {
    if (partnerId && window.sessionStorage) {
      window.sessionStorage.setItem('partnerId', partnerId);
    }
  },
};
