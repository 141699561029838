import deepFreeze from 'deep-freeze';

import CreditStatusState, {
  APICreditStatus,
} from '../../interfaces/CreditStatus';
import { ActionInterface } from '../../helpers/actionBuilder';
import { CreditStatus } from '../actions';

export const initialState: CreditStatusState = {
  // creditstatus: {},
  error: '',
  fetching: false,
  saving: false,
};

/**
 * Clear credit status
 */
const clear = (state: CreditStatusState = initialState): CreditStatusState => {
  return {
    ...state,
    creditstatus: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (
  state: CreditStatusState = initialState,
): CreditStatusState => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive credit status
 */
const receive = (
  state: CreditStatusState = initialState,
  creditstatus: APICreditStatus,
): CreditStatusState => {
  return {
    ...state,
    creditstatus,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: CreditStatusState = initialState,
): CreditStatusState => {
  return {
    ...state,
    fetching: false,
  };
};

export default (
  state: CreditStatusState = initialState,
  action?: ActionInterface,
): CreditStatusState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case CreditStatus.clear:
      return clear(state);

    case CreditStatus.fetching:
      return fetching(state);

    case CreditStatus.receive:
      return receive(state, action.payload.item);

    case CreditStatus.fetchingDone:
      return fetchingDone(state);
    default:
      return state;
  }
};
