import deepFreeze from 'deep-freeze';

import { Countries } from '../actions';

import { ActionInterface } from '../../helpers/actionBuilder';
import CountryState, { Country } from '../../interfaces/Countries';

export const initialState: CountryState = {
  fetching: false,
  data: [],
};

const fetching = (state: CountryState): CountryState => {
  return {
    ...state,
    fetching: true,
  };
};
const fetchingDone = (state: CountryState): CountryState => {
  return {
    ...state,
    fetching: false,
  };
};
const receive = (state: CountryState, payload: Country[]): CountryState => {
  return {
    ...state,
    data: payload,
  };
};

export default (state = initialState, action?: ActionInterface) => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Countries.fetching:
      return fetching(state);
    case Countries.fetchingDone:
      return fetchingDone(state);
    case Countries.receive:
      return receive(state, action.payload);
    default:
      return state;
  }
};
