export const fetchMsg = (feature: string, showError = false, errMsg = '') => {
  return showError
    ? `There was a problem fetching ${feature} [${errMsg}]`
    : `Successfully fetched ${feature}`;
};

export const saveMsg = (feature: string, showError = false, errMsg = '') => {
  return showError
    ? `There was a problem saving this ${feature} [${errMsg}]`
    : `Successfully updated ${feature}`;
};

export const removeMsg = (feature: string, showError = false, errMsg = '') => {
  return showError
    ? `There was a problem deleting this ${feature} [${errMsg}]`
    : `Successfully removed ${feature}`;
};

export const createMsg = (feature: string, showError = false, errMsg = '') => {
  return showError
    ? `There was a problem creating this ${feature} [${errMsg}]`
    : `Successfully added ${feature}`;
};
