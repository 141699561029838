import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { CallQueue } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { createEndpoint } from '../../helpers/api';
import hydrateEndpointBody from '../../helpers/hydrateEndpointBody';
import { nvFetch } from '../../helpers/nvFetch';
import { APICallQueue } from '../../interfaces/CallQueue';
import State from '../../interfaces/State';
import Thing from '../../interfaces/Thing';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { blockInvalid, ThunkFunction, validityAction } from '../helpers';

/**
 * Clear CallQueue menus
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(CallQueue.clear).json;
};

/**
 * Get the customer's CallQueues from pbx API
 */
export const getCallQueues = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'endpoints',
    (terms) => terms.queue.other,
    {
      fetching: CallQueue.fetching,
      fetchingDone: CallQueue.fetchingDone,
      receive: CallQueue.receive,
    },
    { pageSize: 200 },
    { type: 'queue' },
  );

/**
 * Save the customer's call queue from pbx API
 */
export const saveCallQueue = (_queue: APICallQueue): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const queue = { ..._queue };
    if (!queue.notifyEmail) {
      delete queue.notifyEmail;
    }
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(getState().queues.valid, dispatch, toastActions.push);
    } catch {
      return;
    }
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(CallQueue.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/endpoints/${queue.id}`,
        accessToken,
        'PUT',
        queue,
      )) as Things<APICallQueue>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.queue.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(CallQueue.savingDone).json);
    }
    dispatch(new SuccessAction(CallQueue.savingDone).json);
    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(CallQueue.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.queue.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Delete the customer's call queue from pbx API
 */
export const removeCallQueue = (queue: APICallQueue): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = queue;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(CallQueue.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/endpoints/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.queue.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(CallQueue.savingDone).json);
    }
    dispatch(new SuccessAction(CallQueue.savingDone).json);
    dispatch(
      new SuccessAction(CallQueue.remove, {
        item: queue,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.queue.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Create the customer's call queue to pbx API
 */

export const createCallQueue = (_queue: Partial<APICallQueue>) => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const queue = { ..._queue };
    if (!queue.notifyEmail) {
      delete queue.notifyEmail;
    }
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(CallQueue.saving).json);
    let resultingQueue;
    try {
      resultingQueue = (await createEndpoint(
        accessToken,
        activeCustomerId,
        hydrateEndpointBody(queue),
      )) as Thing;
      console.log(resultingQueue);
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.queue.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(CallQueue.savingDone).json);
    }
    dispatch(new SuccessAction(CallQueue.savingDone).json);
    if (!resultingQueue) return;
    dispatch(
      new SuccessAction(CallQueue.receive, {
        items: [resultingQueue],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.queue.one),
        type: 'success',
      }),
    );
    return resultingQueue as APICallQueue;
  };
};

export const updateValidity = (property: keyof APICallQueue, valid: boolean) =>
  validityAction(property, valid, 'queues', CallQueue.updateValidity);
