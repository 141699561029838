import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { App } from '../actions';

export const modalOpened = (): ActionInterface => {
  return new SuccessAction(App.modalOpened).json;
};

export const modalClosed = (): ActionInterface => {
  return new SuccessAction(App.modalClosed).json;
};

export const openSidebar = (): ActionInterface => {
  return new SuccessAction(App.openSidebar).json;
};

export const closeSidebar = (): ActionInterface => {
  return new SuccessAction(App.closeSidebar).json;
};

export const toggleSidebar = (): ActionInterface => {
  return new SuccessAction(App.toggleSidebar).json;
};
