import deepFreeze from 'deep-freeze';

import { Sounds } from '../actions';

import ISounds, {
  APISoundPrompt,
  APISoundMusic,
} from '../../interfaces/Sounds';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';

export const initialState: ISounds = {
  error: '',
  fetching: false,
  musicById: {},
  promptsById: {},
  saving: false,
};

/**
 * Clear prompts
 */
const clearPrompts = (state: ISounds = initialState): ISounds => {
  return {
    ...state,
    promptsById: {},
  };
};
/**
 * Clear music
 */
const clearMusic = (state: ISounds = initialState): ISounds => {
  return {
    ...state,
    musicById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: ISounds = initialState): ISounds => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive prompts
 */
const receivePrompts = (
  state: ISounds = initialState,
  sounds: APISoundPrompt[] = [],
): ISounds => {
  const processedSounds = processCreated(sounds);
  const { idMap } = arrayToIdMapAndOrdering(processedSounds, 'id');
  return {
    ...state,
    promptsById: { ...state.promptsById, ...idMap },
    saving: false,
  };
};
/**
 * Receive prompts
 */
const receiveMusic = (
  state: ISounds = initialState,
  sounds: APISoundMusic[] = [],
): ISounds => {
  const processedSounds = processCreated(sounds);
  const { idMap } = arrayToIdMapAndOrdering(processedSounds, 'id');
  return {
    ...state,
    musicById: { ...state.musicById, ...idMap },
    saving: false,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: ISounds = initialState): ISounds => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: ISounds = initialState): ISounds => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: ISounds = initialState): ISounds => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a prompt
 */
const removePrompt = (
  state: ISounds = initialState,
  prompt: APISoundPrompt,
): ISounds => {
  const { [prompt.id]: _, ...rest } = state.promptsById;
  return {
    ...state,
    promptsById: rest,
    // TODO check
    saving: false,
  };
};
/**
 * Remove music
 */
const removeMusic = (
  state: ISounds = initialState,
  music: APISoundMusic,
): ISounds => {
  const { [music.id]: _, ...rest } = state.musicById;
  return {
    ...state,
    musicById: rest,
    // TODO check
    saving: false,
  };
};

interface ActionSound extends ActionInterface {
  payload: {
    items?: APISoundPrompt[] | APISoundMusic[];
    item?: APISoundPrompt | APISoundMusic;
  };
}

export default (
  state: ISounds = initialState,
  action?: ActionSound,
): ISounds => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Sounds.clearPrompts:
      return clearPrompts(state);
    case Sounds.clearMusic:
      return clearMusic(state);

    case Sounds.fetching:
      return fetching(state);

    case Sounds.receivePrompts:
      return receivePrompts(state, action.payload.items as APISoundPrompt[]);
    case Sounds.receiveMusic:
      return receiveMusic(state, action.payload.items as APISoundMusic[]);

    case Sounds.fetchingDone:
      return fetchingDone(state);

    case Sounds.removePrompt:
      return removePrompt(state, action.payload.item as APISoundPrompt);
    case Sounds.removeMusic:
      return removeMusic(state, action.payload.item as APISoundMusic);

    case Sounds.saving:
      return saving(state);

    case Sounds.savingDone:
      return savingDone(state);

    default:
      return state;
  }
};
