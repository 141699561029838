import State from '../interfaces/State';
import { createSimpleArraySelector } from '../store/helpers';

const forwardingRulesByIdSelector = (state: State) =>
  state.forwardingrules.forwardingRulesById;

export const forwardingRulesSelector = createSimpleArraySelector(
  forwardingRulesByIdSelector,
  'id',
);
