export const getDisplayName = <
  User extends {
    firstName?: string;
    lastName?: string;
    username?: string;
    email: string;
  }
>(
  linkedUser: User,
) => {
  const { firstName, lastName, username, email } = linkedUser;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return username || email;
};
