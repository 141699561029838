import deepFreeze from 'deep-freeze';

import TimeIntervalsState, {
  APITimeInterval,
} from '../../interfaces/TimeIntervals';
import { ActionInterface } from '../../helpers/actionBuilder';
import { TimeIntervals } from '../actions';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { sortIncludedTimes } from '../../helpers/timeIntervals';

export const initialState: TimeIntervalsState = {
  error: '',
  fetching: false,
  saving: false,
  timeIntervalsById: {},
};

/**
 * Clear time interval
 */
const clear = (
  state: TimeIntervalsState = initialState,
): TimeIntervalsState => {
  return {
    ...state,
    timeIntervalsById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (
  state: TimeIntervalsState = initialState,
): TimeIntervalsState => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive time interval
 */
const receive = (
  state: TimeIntervalsState = initialState,
  timeIntervals: APITimeInterval[] = [],
): TimeIntervalsState => {
  const processedTimeIntervals = processCreated(
    timeIntervals.map(sortIncludedTimes),
  );
  const { idMap } = arrayToIdMapAndOrdering(processedTimeIntervals, 'id');
  return {
    ...state,
    timeIntervalsById: {
      ...state.timeIntervalsById,
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: TimeIntervalsState = initialState,
): TimeIntervalsState => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (
  state: TimeIntervalsState = initialState,
): TimeIntervalsState => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (
  state: TimeIntervalsState = initialState,
): TimeIntervalsState => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a time interval
 */
const remove = (
  state: TimeIntervalsState = initialState,
  timeInterval: APITimeInterval,
): TimeIntervalsState => {
  const { [timeInterval.id]: _, ...rest } = state.timeIntervalsById;
  return {
    ...state,
    timeIntervalsById: rest,
  };
};

export default (
  state: TimeIntervalsState = initialState,
  action?: ActionInterface,
): TimeIntervalsState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case TimeIntervals.clear:
      return clear(state);

    case TimeIntervals.fetching:
      return fetching(state);

    case TimeIntervals.receive:
      return receive(state, action.payload.items as APITimeInterval[]);

    case TimeIntervals.fetchingDone:
      return fetchingDone(state);

    case TimeIntervals.remove:
      return remove(state, action.payload.item as APITimeInterval);

    case TimeIntervals.saving:
      return saving(state);

    case TimeIntervals.savingDone:
      return savingDone(state);

    default:
      return state;
  }
};
