import { EndpointTypes } from '../interfaces/Endpoints';

const defaultProperties: { [key in EndpointTypes]: object } = {
  group: {
    memberTimeout: 30,
    members: [],
  },
  ivr: {
    timeout: 5,
  },
  mailbox: {}, // no additional properties to add
  phone: {
    callRecording: 'OFF',
    timeout: 30,
  },
  queue: {
    maxWait: 300,
    memberRetryInterval: 3,
    memberTimeout: 20,
    members: [],
    strategy: 'RINGALL',
  },
  trunk: {
    timeout: 30,
  },
  virtual: {
    timeout: 30,
  },
};
const hydrate = (endpoint: { type?: EndpointTypes }) => {
  return {
    ...(defaultProperties[endpoint.type] || {}),
    ...endpoint,
  };
};

export default hydrate;
