// Map of routes to their corresponding document titles

import { AccountType } from '../interfaces/Customer';

// routes are matched in the same way that React Router would
const baseCustomerTitles = new Map([
  ['/login', { label: 'Login' }],
  ['/user', { label: 'User' }],
  ['/404', { label: 'Page Not Found' }],
  ['/acceptinvite', { label: 'Sign up' }],
  ['/c/:id/dashboard', { label: 'Dashboard' }],
  [
    '/c/:id/phonenumbers',
    { label: 'Incoming Numbers', titleRef: 'phoneNumbers' },
  ],
  ['/c/:id/extensions', { label: 'Phones' }],
  ['/c/:id/groups', { label: 'Ring Groups', titleRef: 'groups' }],
  ['/c/:id/queues', { label: 'Call Queues', titleRef: 'queues' }],
  ['/c/:id/ivrs', { label: 'IVRs', titleRef: 'ivrs' }],
  ['/c/:id/mailboxes', { label: 'Mailboxes', titleRef: 'mailboxes' }],
  ['/c/:id/trunks', { label: 'Trunks', titleRef: 'trunks' }],
  [
    '/c/:id/account/recordings',
    { label: 'Call Recordings', titleRef: 'callRecordings' },
  ],
  [
    '/c/:id/account/callhistory',
    { label: 'Call History', titleRef: 'callHistory' },
  ],
  [
    '/c/:id/account/smshistory',
    { label: 'SMS History', titleRef: 'smsHistory' },
  ],
  ['/c/:id/account/sounds/music', { label: 'Music', titleRef: 'musics' }],
  [
    '/c/:id/account/sounds/announcements',
    { label: 'Prompts', titleRef: 'prompts' },
  ],
  [
    '/c/:id/account/timeintervals',
    { label: 'Time Intervals', titleRef: 'timeIntervals' },
  ],
  ['/c/:id/account/contact', { label: 'Contact', titleRef: 'contact' }],
  ['/c/:id/account/billing', { label: 'Billing', titleRef: 'billing' }],
  ['/c/:id/account/credit', { label: 'Credit', titleRef: 'credit' }],
  ['/c/:id/account/phonebook', { label: 'Phone Book', titleRef: 'phonebook' }],
  ['/c/:id/account/team', { label: 'Team', titleRef: 'team' }],
  [
    '/c/:id/account/bargegroups',
    { label: 'Call Barge Groups', titleRef: 'bargegroups' },
  ],
  ['/c/:id/account/groups', { label: 'Ring Groups', titleRef: 'groups' }],
  ['/c', { label: 'Accounts' }], // should always be placed last
]);

export const documentTitles: {
  [key in AccountType]: Map<string, { label: string; titleRef?: string }>;
} = {
  BUSINESS: new Map([...Array.from(baseCustomerTitles)]),
  RESIDENTIAL: new Map([...Array.from(baseCustomerTitles)]),
  UNKNOWN: new Map([...Array.from(baseCustomerTitles)]),
  WLR: new Map([...Array.from(baseCustomerTitles)]),
};
