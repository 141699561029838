import State from '../../interfaces/State';

import * as toastActions from '../toasts/actions';
import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';
import { APICustomer } from '../../interfaces/Customer';
import { Customers } from '../actions';
import Things from '../../interfaces/Things';
import { nvFetch } from '../../helpers/nvFetch';
import { saveMsg } from '../../helpers/actionMessageCreator';

/**
 * Clear Customer object
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(Customers.clear).json;
};

/**
 * Get customers
 */
export const getCustomers = (customerId = ''): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ): Promise<APICustomer[]> => {
    dispatch(new SuccessAction(Customers.fetching).json);

    let data;
    try {
      data = (await nvFetch(
        `customers/${customerId}`,
        getState().user.accessToken,
      )) as APICustomer | Things<APICustomer>;
    } catch (error) {
      return [];
    }

    dispatch(new SuccessAction(Customers.fetchingDone).json);
    if (!data) {
      return [];
    }
    const items = data.hasOwnProperty('items')
      ? (data as Things<APICustomer>).items
      : [data as APICustomer];

    dispatch(
      new SuccessAction(Customers.receive, {
        items,
      }).json,
    );

    return items;
  };
};
export const saveCustomer = (customer: APICustomer): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ): Promise<APICustomer[]> => {
    const { accessToken } = getState().user;
    const { id } = customer;
    dispatch(new SuccessAction(Customers.saving).json);

    let data;
    try {
      data = (await nvFetch(
        `customers/${id}`,
        accessToken,
        'PUT',
        customer,
      )) as APICustomer;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg('customer information', true, error.message),
          type: 'danger',
        }),
      );
    }

    dispatch(new SuccessAction(Customers.savingDone).json);
    if (!data) {
      return [];
    }
    const items = [data as APICustomer];

    dispatch(
      new SuccessAction(Customers.receive, {
        items,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('customer information'),
        type: 'success',
      }),
    );

    return items;
  };
};
