import { DateTime, Duration } from 'luxon';
import { EndpointTypes } from '../interfaces/Endpoints';
import { IdMap } from '../interfaces/interfaces';
import queryString from 'query-string';
import { history } from './history';

const restrictedExtensions = ['112', '999'];

export const chooseUnusedExtension = (extensions: string[]) => {
  const allExtensions = [...extensions, ...restrictedExtensions]
    .map((e) => Number(e))
    .sort((a, b) => a - b);
  const nextExtension =
    allExtensions.find(
      (e, i) => i === allExtensions.length - 1 || allExtensions[i + 1] > e + 1,
    ) + 1;
  return String(nextExtension).padStart(3, '0');
};

export const secondsToDuration = (seconds: number) => {
  const d = Duration.fromMillis(seconds * 1000);
  return d.toFormat('hh:mm:ss');
};

export const getAllMonthsFrom = (date: Date) => {
  let start = DateTime.fromJSDate(date);
  const result: {
    [k: string]: {
      start: string;
      end: string;
      name: string;
    };
  } = {};
  let i = 0;
  const now = Date.now();
  while (true) {
    const end = start.endOf('month');
    result[i] = {
      end: end.toISO({ includeOffset: false }) + 'Z',
      name: start.toFormat('MMMM yyyy'),
      start: start.toISO({ includeOffset: false }) + 'Z',
    };
    if (end.toMillis() > now) {
      break;
    }
    i += 1;
    start = end.plus(1);
  }
  return result;
};

export const getNextAvailableNumber = (
  allShortNumbers: string[],
  currentEndpointItems: { shortNumber: string }[],
  type: EndpointTypes,
) => {
  const blacklistedNumbers = [112, 999];
  const defaultShortNumber = {
    group: 300,
    ivr: 500,
    mailbox: 600,
    phone: 100,
    queue: 400,
    trunk: 700,
    virtual: 200,
  };
  let shortNumber;
  // converting arrays to number arrays
  const currentEndpointShortNumbers: number[] = currentEndpointItems.map(
    (item) => Number(item.shortNumber),
  );

  const allExistingShortNumbers = allShortNumbers.map((shortNumber) =>
    Number(shortNumber),
  );

  if (currentEndpointShortNumbers.length === 0) {
    shortNumber = defaultShortNumber[type];
  } else {
    const [highestShortNumber] = currentEndpointShortNumbers
      .sort(function(a, b) {
        return a - b;
      })
      .slice(-1);
    shortNumber = highestShortNumber + 1;
  }

  while (
    allExistingShortNumbers.includes(shortNumber) ||
    blacklistedNumbers.includes(shortNumber)
  ) {
    shortNumber++;
    if (shortNumber >= 10000) {
      shortNumber = 1;
    }
  }
  const suggestedShortNumber = shortNumber.toString().padStart(3, '0');
  return suggestedShortNumber;
};

export function arrayToIdMapAndOrdering<T, K extends keyof T>(
  array: T[],
  key: K | ((obj: T) => string),
) {
  const idMap: IdMap<T> = array.reduce((obj: IdMap<T>, element) => {
    let id: string | number;
    let ekey;
    if (typeof key === 'function') {
      ekey = key(element);
    } else {
      ekey = element[key];
    }
    if (typeof ekey === 'string' || typeof ekey === 'number') {
      id = ekey;
    } else {
      id = String(ekey);
    }
    obj[id] = element;
    return obj;
  }, {});
  const ordering = array.map((element) =>
    typeof key === 'function' ? key(element) : element[key],
  );
  return {
    idMap,
    ordering,
  };
}

export const toCapitalCase = (str: string) => {
  const lower = str.toLowerCase();
  return lower[0].toUpperCase() + lower.slice(1);
};

export const encodeBase64Sound = (sound: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(sound);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const processCreated = <T extends any[]>(arr: T) =>
  arr.map((x) => ({ ...x, created: new Date(x.created) })) as T;

export const formatSize = (size: number) => {
  const siPrefix = ['B', 'KB', 'MB', 'GB', 'TB'];
  let result = size;
  let siPrefixIndex = 0;
  while (result > 1024) {
    result /= 1024;
    siPrefixIndex++;
  }
  return result.toFixed(1) + ` ${siPrefix[siPrefixIndex]}`;
};

/**
 * Updates query value if key existed before, if value provided is '', will remove query from URL
 *
 * Otherwise, will add a new query
 * @param baseUrl
 * @param currentParsedQuery object representation of query string
 * @param queries [queryName: string, queryValue: string][]
 * @param debounceLocationHref (For debounced requests) Ensures we don't update query if url has changed since we called this function
 */
export const updateQuery = (
  baseUrl: string,
  currentParsedQuery: queryString.ParsedQuery<string>,
  queries: [string, string][],
  debounceLocationHref?: string,
) => {
  // if the user quickly navigates between two pages which call this function,
  // will incur a loop of redirects between them
  // FIX: only update query if we are still on the page which called the function
  if (debounceLocationHref && window.location.href !== debounceLocationHref)
    return;
  if (!window.location.pathname.includes(baseUrl)) return;

  const newQuery = { ...currentParsedQuery };
  queries.forEach(([key, val]) => {
    newQuery[key] = val;
    if (val === '') {
      delete newQuery[key];
    }
  });
  history.push(`${baseUrl}?${queryString.stringify(newQuery)}`);
};

export const stringCompareCaseSensitive = (a: string, b: string) => {
  if (a.length === 0) {
    return -1;
  }
  if (b.length === 0) {
    return 1;
  }
  const au = a[0] === a[0].toUpperCase();
  const bu = b[0] === b[0].toUpperCase();
  if ((au && bu) || (!au && !bu)) {
    return a.localeCompare(b);
  }
  if (au && !bu) {
    return -1;
  }
  return 1;
};

export const getSoundDropdownValueOrRemoved = (
  options: { label: string; value: string }[],
  valueToFind: string | undefined,
) => {
  let found = options.find((opt) => opt.value === valueToFind);
  if (!found) {
    // feature was probably removed elsewhere
    found = { label: 'Sound removed - please update.', value: undefined };
  }
  return found;
};

export const sleep = (time: number) =>
  new Promise<void>((res) => {
    console.log('sleeping');
    setTimeout(() => {
      console.log('done');
      res();
    }, time);
  });
