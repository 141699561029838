import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { AreaCodes } from '../actions';
import * as toastActions from '../toasts/actions';

import { APIAreaCode } from '../../interfaces/AreaCodes';
import { fetchMsg } from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';

/**
 * Clear numbers
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(AreaCodes.clear).json;
};

/**
 * Get the area codes from pbx API
 */
export const getAreaCodes = (
  searchParams: { page: number; pageSize: number; dialCode: number } = {
    page: 1,
    pageSize: 1000,
    dialCode: 44,
  },
): any => {
  return async (dispatch: (action: ActionInterface) => void) => {
    dispatch(new SuccessAction(AreaCodes.fetching).json);

    let data: any;
    try {
      data = await nvFetch(
        `areacodes?page=${searchParams.page}&pageSize=${searchParams.pageSize}&country=${searchParams.dialCode}`,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('area codes', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    if (!data || !data.items) {
      dispatch(new SuccessAction(AreaCodes.fetchingDone).json);

      return;
    }

    const areaCodes: APIAreaCode[] = data.items;

    dispatch(
      new SuccessAction(AreaCodes.receive, {
        areaCodes,
      }).json,
    );

    if (data.nextPage) {
      getAreaCodes({
        page: data.page + 1,
        pageSize: searchParams.pageSize,
        dialCode: searchParams.dialCode,
      })(dispatch);
    } else {
      dispatch(new SuccessAction(AreaCodes.fetchingDone).json);
    }
  };
};
