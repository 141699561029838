import State from '../../interfaces/State';
import IUser, { APIUser } from '../../interfaces/User';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { nvFetch } from '../../helpers/nvFetch';

import { User } from '../actions';
import * as toastActions from '../toasts/actions';

import { initialState } from './reducers';

/**
 * Clear user object
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(User.clear).json;
};

/**
 * Log in
 */
export const authenticationSuccess = (
  accessToken: string = initialState.accessToken as string,
  expires: Date = initialState.expires as Date,
  rememberMe: boolean = initialState.rememberMe as boolean,
): any => {
  return (dispatch: (action: ActionInterface) => void) => {
    dispatch(
      new SuccessAction(User.authenticatingDone, {
        accessToken,
        expires,
        rememberMe,
      }).json,
    );
  };
};

/**
 * Fetch user object in context of team
 */
export const fetchUser = (teamId = ''): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(User.fetching).json);

    let user;
    try {
      user = (await nvFetch(`users/me`, getState().user.accessToken)) as IUser;
    } catch (error) {
      dispatch(new ErrorAction(User.fetchingDone, error).json);

      return;
    }

    if (!user) {
      return;
    }

    dispatch(new SuccessAction(User.receive, user).json);

    dispatch(new SuccessAction(User.fetchingDone).json);
  };
};

/**
 * active customer id
 */
export const setActiveCustomer = (customerId?: string): ActionInterface =>
  new SuccessAction(User.setActiveCustomer, { customerId }).json;

/**
 * Update user object
 */
export const updateUser = (user: APIUser): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(User.fetching).json);
    let updatedUser;
    try {
      updatedUser = (await nvFetch(
        `users/me`,
        getState().user.accessToken,
        'PUT',
        user,
      )) as IUser;
    } catch (error) {
      dispatch(new ErrorAction(User.fetchingDone, error).json);
      dispatch(
        toastActions.push({
          delay: 10000,
          message:
            `We were unable to update your ${
              user.newPassword ? 'password' : 'details'
            }, please check and try again. - ` +
            error.validationErrors
              .map((err: any) => `${err.field} ${err.error}`)
              .join(','),
          type: 'danger',
        }),
      );

      return;
    }

    if (!updatedUser) {
      return;
    }

    dispatch(new SuccessAction(User.receive, updatedUser).json);

    dispatch(new SuccessAction(User.fetchingDone).json);
    dispatch(
      toastActions.push({
        delay: 5000,
        message: `Successfully updated ${
          user.newPassword ? 'password' : 'details'
        }`,
        type: 'success',
      }),
    );
  };
};

/**
 * Purge stored credentials / session details
 */
export const logout = (silent = false): any => {
  // TODO incorporate these into credential system
  delete localStorage['nv-token'];
  delete localStorage['nv-token-expires'];
  delete localStorage['nv-token-remember'];

  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    // TODO kind of dirty
    const { logoutRedirectUrl } = getState().branding.branding.branding;
    if (logoutRedirectUrl) {
      window.location.href =
        logoutRedirectUrl + '?rurl=' + window.location.origin + '/login';
    } else {
      // no need to do it otherwise since local state will be cleared when navigating to another page
      try {
        // clear nv_jwt cookie
        await nvFetch(
          'authenticate/logout',
          getState().user.accessToken,
          'POST',
          {},
        );
      } catch {
        // allowed to fail
      }
      dispatch(new SuccessAction(User.logout).json);
    }

    // dispatch(new SuccessAction(Teams.clear).json);

    if (!silent) {
      dispatch(
        toastActions.push({
          delay: 5000,
          message: 'Successfully logged out',
          type: 'success',
        }),
      );
    }
  };
};
