import deepFreeze from 'deep-freeze';

import { ForwardingRules } from '../actions';

import IForwardingRules, {
  APIForwardingRule,
} from '../../interfaces/ForwardingRules';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';

export const initialState: IForwardingRules = {
  error: '',
  fetching: false,
  forwardingRulesById: {},
  saving: false,
};

const clear = (state: IForwardingRules = initialState): IForwardingRules => {
  return {
    ...{},
    ...state,
    forwardingRulesById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IForwardingRules = initialState): IForwardingRules => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive forwarding rule
 */
const receive = (
  state: IForwardingRules = initialState,
  receivedForwardingRule: APIForwardingRule[] = [],
): IForwardingRules => {
  const processedForwardingRules = processCreated(receivedForwardingRule);

  const { idMap } = arrayToIdMapAndOrdering(processedForwardingRules, 'id');

  return {
    ...{},
    ...state,
    forwardingRulesById: idMap,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: IForwardingRules = initialState,
): IForwardingRules => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IForwardingRules = initialState): IForwardingRules => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (
  state: IForwardingRules = initialState,
): IForwardingRules => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

/**
 * Remove a forwarding rule
 */
const remove = (
  state: IForwardingRules = initialState,
  forwardingRule: APIForwardingRule,
): IForwardingRules => {
  const { [forwardingRule.id]: _, ...rest } = state.forwardingRulesById;
  return {
    ...state,
    forwardingRulesById: rest,
    // TODO check
  };
};

interface ForwardingRuleAction extends ActionInterface {
  payload: {
    item?: APIForwardingRule;
    items?: APIForwardingRule[];
  };
}

export default (
  state: IForwardingRules = initialState,
  action?: ForwardingRuleAction,
): IForwardingRules => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case ForwardingRules.clear:
      return clear(state);

    case ForwardingRules.fetching:
      return fetching(state);

    case ForwardingRules.receive:
      return receive(state, action.payload.items);

    case ForwardingRules.fetchingDone:
      return fetchingDone(state);

    case ForwardingRules.remove:
      return remove(state, action.payload.item);

    case ForwardingRules.saving:
      return saving(state);

    case ForwardingRules.savingDone:
      return savingDone(state);

    default:
      return state;
  }
};
