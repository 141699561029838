// [quality, price in GBP]
export const qualityPriceMap = new Map([
  [1, 0],
  [2, 0],
  [3, 0],
  [4, 0],
  [5, 0],
  [6, 150],
  [7, 350],
  [8, 650],
  [9, 1050],
  [10, 1550],
]);

export const HELP_MENU = [
  {
    href:
      'http://kb.nimvelo.com/article/173-getting-started-with-nimvelo-phone-communicator',
    label: 'Knowledge Base',
    target: '_blank',
  },
];

export const SMS_STATUS_INFO = {
  deliveryStatus: {
    1: {
      color: 'var(--success)',
      icon: 'check-circle',
      tooltip: 'Delivered to handset',
    },
    2: {
      color: 'var(--danger)',
      icon: 'times-circle',
      tooltip: 'Rejected from handset',
    },
    4: {
      color: 'grey',
      icon: 'check-circle',
      tooltip: 'Buffered in transit (phone probably off / out of reception)',
    },
    8: {
      color: 'grey',
      icon: 'dot-circle',
      tooltip: 'Accepted by SMS carrier',
    },
    16: {
      color: 'var(--danger)',
      icon: 'times-circle',
      tooltip: 'Rejected by SMS carrier',
    },
    default: {
      color: 'lightgrey',
      icon: 'circle',
      tooltip: 'Delivery status unknown',
    },
  },
  sendStatus: {
    FAILED: {
      color: 'var(--danger)',
      icon: 'times-circle',
      tooltip: 'Sending failed',
    },
    PARTIAL: {
      color: 'grey',
      icon: 'dot-circle',
      tooltip: 'Partially sent message',
    },
    PENDING: { color: 'lightgrey', icon: 'circle', tooltip: 'Message pending' },
    SENT: {
      color: 'var(--success)',
      icon: 'check-circle',
      tooltip: 'Message sent',
    },
    default: {
      color: 'lightgrey',
      icon: 'circle',
      tooltip: 'Send status unknown',
    },
  },
};
