import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { CallBundles } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  fetchMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APICallBundle } from '../../interfaces/CallBundles';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction } from '../helpers';

export const clear = (): ActionInterface => {
  return new SuccessAction(CallBundles.clear).json;
};

export const getActiveCallBundles = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'callbundles',
    (terms) => terms.team.other,
    {
      fetching: CallBundles.fetchingActive,
      fetchingDone: CallBundles.fetchingActiveDone,
      receive: CallBundles.receiveActive,
    },
    { pageSize: 200 },
    { status: 'ACTIVE' },
  );

export const getExpiredCallBundles = (page = 1, pageSize = 6): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(CallBundles.fetchingExpired).json);
    let data: Things<APICallBundle>;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/callbundles?page=${page}&pageSize=${pageSize}&status=EXPIRED`,
        accessToken,
      )) as Things<APICallBundle>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(
            'expired ' + terms.callBundle.other,
            true,
            error.message,
          ),
          type: 'danger',
        }),
      );
    }

    dispatch(new SuccessAction(CallBundles.fetchingExpiredDone).json);

    if (!data) {
      return null;
    }
    dispatch(
      new SuccessAction(CallBundles.receiveExpired, {
        items: data.items,
        page: page,
        pageCount: Math.ceil(data.totalItems / data.pageSize),
      }).json,
    );
    return data;
  };
};

export const createCallBundle = (createFrom: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(CallBundles.saving).json);
    let resultingBundle;
    try {
      resultingBundle = ((await nvFetch(
        `customers/${activeCustomerId}/callbundles`,
        accessToken,
        'POST',
        { autoRenew: false, createFrom, type: 'customerbundle' },
      )) as any) as APICallBundle;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.callBundle.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(CallBundles.savingDone).json);
    }
    dispatch(new SuccessAction(CallBundles.savingDone).json);
    if (!resultingBundle) return;

    dispatch(
      new SuccessAction(CallBundles.receiveActive, {
        items: [resultingBundle],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.callBundle.one),
        type: 'success',
      }),
    );
  };
};

export const saveCallBundle = (callBundle: APICallBundle): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    const { id } = callBundle;
    dispatch(new SuccessAction(CallBundles.saving).json);
    let resultingBundle;
    try {
      resultingBundle = ((await nvFetch(
        `customers/${activeCustomerId}/callbundles/${id}`,
        accessToken,
        'PUT',
        callBundle,
      )) as any) as APICallBundle;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.callBundle.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(CallBundles.savingDone).json);
    }
    dispatch(new SuccessAction(CallBundles.savingDone).json);
    if (!resultingBundle) return;

    dispatch(
      new SuccessAction(CallBundles.receiveActive, {
        items: [resultingBundle],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: saveMsg(terms.callBundle.one),
        type: 'success',
      }),
    );
  };
};
