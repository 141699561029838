import { Dispatch } from 'redux';
import { SuccessAction } from '../../helpers/actionBuilder';
import { Countries } from '../actions';
import { nvFetch } from '../../helpers/nvFetch';
import Things from '../../interfaces/Things';
import { Country } from '../../interfaces/Countries';

export const fetching = () => {
  return new SuccessAction(Countries.fetching).json;
};
export const fetchingDone = () => {
  return new SuccessAction(Countries.fetchingDone).json;
};
export const fetchCountries = (): any => {
  return async (dispatch: Dispatch) => {
    dispatch(fetching());
    try {
      const response = (await nvFetch('countries?pageSize=1000')) as Things<
        Country
      >;
      const data = response.items;
      dispatch(new SuccessAction(Countries.receive, data).json);
    } finally {
      dispatch(fetchingDone());
    }
  };
};
