import State from '../../interfaces/State';

import { LinkedUser } from '../actions';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { nvFetch } from '../../helpers/nvFetch';

import {
  fetchMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import {
  APICreateLinkedUser,
  APILinkedUser,
} from '../../interfaces/LinkedUsers';
import { accountConfigSelector } from '../../selectors/customer';
import { linkedUsersSelector } from '../../selectors/linkedUsers';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction, blockInvalid, validityAction } from '../helpers';
import * as toastActions from '../toasts/actions';
/**
 * Clear linked user object
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(LinkedUser.clear).json;
};

/**
 * Clear active linked user object
 */
export const fetchActiveLinkedUser = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(LinkedUser.active.fetching).json);
    const { activeCustomerId } = getState().user;
    let linkeduser;
    try {
      linkeduser = (await nvFetch(
        `customers/${activeCustomerId}/linkedusers/me`,
        getState().user.accessToken,
      )) as APILinkedUser;
    } catch (error) {
      dispatch(new ErrorAction(LinkedUser.active.fetchingDone).json);
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('active linked user', true, error.message),
          type: 'danger',
        }),
      );
      return;
    }
    dispatch(new SuccessAction(LinkedUser.active.fetchingDone).json);

    if (!linkeduser) {
      return;
    }

    dispatch(new SuccessAction(LinkedUser.active.receive, linkeduser).json);
    dispatch(new SuccessAction(LinkedUser.savingDone).json);
  };
};

export const getLinkedUsers = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'linkedusers',
    (terms) => terms.team.other,
    {
      fetching: LinkedUser.fetching,
      fetchingDone: LinkedUser.fetchingDone,
      receive: LinkedUser.receive,
    },
    { pageSize: 200 },
  );

export const saveLinkedUser = (
  linkedUser: APILinkedUser,
  silent = false,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(getState().linkeduser.valid, dispatch, toastActions.push);
    } catch {
      return;
    }
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(LinkedUser.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/linkedusers/${linkedUser.id}`,
        accessToken,
        'PUT',
        linkedUser,
      )) as APILinkedUser;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.team.one, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(LinkedUser.savingDone).json);
      return;
    }

    if (!data) {
      dispatch(new SuccessAction(LinkedUser.savingDone).json);

      return;
    }
    dispatch(new SuccessAction(LinkedUser.receive, { items: [data] }).json);
    dispatch(new SuccessAction(LinkedUser.savingDone).json);
    if (!silent) {
      dispatch(
        toastActions.push({
          delay: 5000,
          message: saveMsg(terms.team.one),
          type: 'success',
        }),
      );
    }
  };
};

export const createLinkedUser = (
  email: string,
  associatedExtensionId?: string,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const linkedUser: APICreateLinkedUser = {
      accessLevel: 'EXTENSION',
      activateUrl: window.location.origin + '/acceptinvite',
      email,
      enabled: true,
      owner: false,
      pending: false,
      recordingAccess: 'NONE',
      type: 'linkeduser',
    };
    if (associatedExtensionId) {
      linkedUser.defaultExtension = associatedExtensionId;
    }
    // try {
    //   blockInvalid(getState().linkeduser.valid, dispatch, toastActions.push);
    // } catch {
    //   return;
    // }
    const { activeCustomerId, accessToken } = getState().user;
    // Auto set owner to true if the invitee is the first to be invited
    const isFirstToBeInvited = linkedUsersSelector(getState()).length === 0;
    if (isFirstToBeInvited) {
      linkedUser.owner = true;
    }
    dispatch(new SuccessAction(LinkedUser.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/linkedusers`,
        accessToken,
        'POST',
        linkedUser,
      )) as APILinkedUser;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: `There was a problem inviting a new ${terms.team.one} [${error.message}]`,
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(LinkedUser.savingDone).json);
      return;
    }

    if (!data) {
      dispatch(new SuccessAction(LinkedUser.savingDone).json);

      return;
    }
    dispatch(new SuccessAction(LinkedUser.receive, { items: [data] }).json);
    dispatch(new SuccessAction(LinkedUser.savingDone).json);
    dispatch(
      toastActions.push({
        delay: 5000,
        message: 'Invite successfully sent',
        type: 'success',
      }),
    );
  };
};
/**
 * Remove Linked User
 */

export const removeLinkedUser = (linkedUser: APILinkedUser): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(LinkedUser.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/linkedusers/${linkedUser.id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.team.one, true, error.message),
          type: 'danger',
        }),
      );
      return;
    }
    dispatch(new SuccessAction(LinkedUser.remove, linkedUser).json);

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.team.one),
        type: 'success',
      }),
    );
  };
};

export const updateValidity = (property: keyof APILinkedUser, valid: boolean) =>
  validityAction(property, valid, 'queues', LinkedUser.updateValidity);
