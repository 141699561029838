import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { SharedMailboxes } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { createEndpoint } from '../../helpers/api';
import hydrateEndpointBody from '../../helpers/hydrateEndpointBody';
import { nvFetch } from '../../helpers/nvFetch';
import { APISharedMailbox } from '../../interfaces/SharedMailboxes';
import State from '../../interfaces/State';
import Thing from '../../interfaces/Thing';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { blockInvalid, ThunkFunction, validityAction } from '../helpers';

/**
 * Clear mailboxes
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(SharedMailboxes.clear).json;
};

/**
 * Get the customer's mailboxes from pbx API
 */
export const getSharedMailboxes = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'endpoints',
    (terms) => terms.mailbox.other,
    {
      fetching: SharedMailboxes.fetching,
      fetchingDone: SharedMailboxes.fetchingDone,
      receive: SharedMailboxes.receive,
    },
    { pageSize: 200 },
    { type: 'mailbox' },
  );

/**
 * Save the customer's shared mailbox from pbx API
 */
export const saveSharedMailbox = (sharedMailbox: APISharedMailbox): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(getState().mailboxes.valid, dispatch, toastActions.push);
    } catch {
      return;
    }
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(SharedMailboxes.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/endpoints/${sharedMailbox.id}`,
        accessToken,
        'PUT',
        sharedMailbox,
      )) as Things<APISharedMailbox>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.mailbox.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(SharedMailboxes.savingDone).json);
    }
    dispatch(new SuccessAction(SharedMailboxes.savingDone).json);

    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(SharedMailboxes.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.mailbox.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Delete the customer's mailboxes from pbx API
 */
export const removeSharedMailbox = (sharedMailbox: APISharedMailbox): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = sharedMailbox;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(SharedMailboxes.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/endpoints/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.mailbox.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(SharedMailboxes.savingDone).json);
    }
    dispatch(new SuccessAction(SharedMailboxes.savingDone).json);

    dispatch(
      new SuccessAction(SharedMailboxes.remove, {
        item: sharedMailbox,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.mailbox.one),
        type: 'success',
      }),
    );
  };
};

export const createSharedMailbox = (
  sharedMailbox: Partial<APISharedMailbox>,
) => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(SharedMailboxes.saving).json);
    let resultingMailbox;
    try {
      resultingMailbox = (await createEndpoint(
        accessToken,
        activeCustomerId,
        hydrateEndpointBody(sharedMailbox),
      )) as Thing;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.mailbox.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(SharedMailboxes.savingDone).json);
    }
    dispatch(new SuccessAction(SharedMailboxes.savingDone).json);

    if (!resultingMailbox) return;

    dispatch(
      new SuccessAction(SharedMailboxes.receive, {
        items: [resultingMailbox],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.mailbox.one),
        type: 'success',
      }),
    );
    return resultingMailbox as APISharedMailbox;
  };
};

export const updateValidity = (
  property: keyof APISharedMailbox,
  valid: boolean,
) =>
  validityAction(property, valid, 'mailboxes', SharedMailboxes.updateValidity);
