/**
 * Template for a Flux Standard Action
 * More info: https://github.com/acdlite/flux-standard-action
 *            (https://archive.fo/xQ5Mi)
 */
export interface ActionInterface {
  error: boolean;
  meta: {
    timestamp: Date;
  };
  payload: any;
  type: string;
}

interface ErrorActionInterface extends ActionInterface {
  payload: Error;
}

export class SuccessAction implements ActionInterface {
  public error = false;
  public meta: {
    timestamp: Date;
  };
  public payload: any;
  public type: string;

  constructor(type = '', payload: any = {}) {
    this.meta = {
      timestamp: new Date(),
    };
    this.payload = payload;
    this.type = type;
  }

  public get json(): ActionInterface {
    return {
      error: this.error,
      meta: this.meta,
      payload: this.payload,
      type: this.type,
    };
  }
}

export class ErrorAction extends SuccessAction implements ErrorActionInterface {
  public error = true;
  public payload: Error;

  constructor(type = '', payload: string | Error = new Error('Unknown error')) {
    super(type, payload);

    if (typeof payload === 'string') {
      this.payload = new Error(payload);
    } else {
      this.payload = payload;
    }
  }
}
