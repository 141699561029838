import deepFreeze from 'deep-freeze';

import ICallQueue, { APICallQueue } from '../../interfaces/CallQueue';

import { ActionInterface } from '../../helpers/actionBuilder';
import { CallQueue } from '../actions';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: ICallQueue = {
  callQueuesById: {},
  error: '',
  fetching: false,
  saving: false,
  valid: {
    abandonedThreshold: true,
    announcePositionInterval: true,
    maxWait: true,
    memberRetryInterval: true,
    memberTimeout: true,
    notifyEmail: true,
    periodicAnnounceInterval: true,
    serviceLevelThreshold: true,
  },
};

const clear = (state: ICallQueue = initialState): ICallQueue => {
  return {
    ...state,
    callQueuesById: {},
  };
};

const fetching = (state: ICallQueue = initialState): ICallQueue => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: ICallQueue = initialState,
  queues: APICallQueue[] = [],
): ICallQueue => {
  const processedQueues = processCreated(queues);
  const { idMap } = arrayToIdMapAndOrdering(processedQueues, 'id');

  return {
    ...state,
    callQueuesById: {
      ...state.callQueuesById,
      ...idMap,
    },
  };
};

const fetchingDone = (state: ICallQueue = initialState): ICallQueue => {
  return {
    ...state,
    fetching: false,
  };
};

const saving = (state: ICallQueue = initialState): ICallQueue => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: ICallQueue = initialState): ICallQueue => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: ICallQueue = initialState,
  queue: APICallQueue,
): ICallQueue => {
  const { [queue.id]: _, ...rest } = state.callQueuesById;
  return {
    ...state,
    callQueuesById: rest,
    // TODO check if this is correct
  };
};

const updateValidity = (
  state: ICallQueue = initialState,
  validity: Validity<APICallQueue>,
) => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};
interface CallQueuesAction extends ActionInterface {
  payload: {
    items?: APICallQueue[];
    item?: APICallQueue;
    validity?: Validity<APICallQueue>;
  };
}

export default (
  state: ICallQueue = initialState,
  action?: CallQueuesAction,
): ICallQueue => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case CallQueue.clear:
      return clear(state);

    case CallQueue.fetching:
      return fetching(state);

    case CallQueue.fetchingDone:
      return fetchingDone(state);

    case CallQueue.saving:
      return saving(state);

    case CallQueue.savingDone:
      return savingDone(state);

    case CallQueue.receive:
      return receive(state, action.payload.items as APICallQueue[]);

    case CallQueue.remove:
      return remove(state, action.payload.item);
    case CallQueue.updateValidity:
      return updateValidity(state, action.payload.validity);

    default:
      return state;
  }
};
