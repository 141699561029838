import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { PhoneNumbers } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APIPhoneNumber } from '../../interfaces/PhoneNumbers';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction, blockInvalid, validityAction } from '../helpers';
import { getSinglePhoneExtension } from '../phoneextensions/actions';

/**
 * Clear numbers
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(PhoneNumbers.clear).json;
};

/**
 * Get the customer's phone numbers from pbx API
 */
export const getPhoneNumbers = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'phonenumbers',
    (terms) => terms.phoneNumber.other,
    {
      fetching: PhoneNumbers.fetching,
      fetchingDone: PhoneNumbers.fetchingDone,
      receive: PhoneNumbers.receive,
    },
    { pageSize: 200 },
  );

/**
 * Save the customer's phone numbers from pbx API
 */
export const savePhoneNumber = (
  customerId: string,
  phoneNumber: APIPhoneNumber,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(getState().phonenumbers.valid, dispatch, toastActions.push);
    } catch {
      return;
    }
    dispatch(new SuccessAction(PhoneNumbers.saving).json);

    // If we don't have an identifier, remove it
    if (!phoneNumber.identifier) {
      delete phoneNumber.identifier;
    }
    if (phoneNumber.faxEnabled && !phoneNumber.faxNotificationEmail) {
      phoneNumber.faxNotificationEmail = getState().customers.customersById[
        customerId
      ].email;
    }

    let data;
    try {
      data = (await nvFetch(
        `customers/${customerId}/phonenumbers/${phoneNumber.id}`,
        getState().user.accessToken,
        'PUT',
        phoneNumber,
      )) as Things<APIPhoneNumber>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.phoneNumber.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(PhoneNumbers.savingDone).json);
    }
    dispatch(new SuccessAction(PhoneNumbers.savingDone).json);

    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(PhoneNumbers.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.phoneNumber.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Delete the customer's phone numbers from pbx API
 */
export const removePhoneNumber = (
  customerId: string,
  phoneNumber: APIPhoneNumber,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(PhoneNumbers.saving).json);

    try {
      await nvFetch(
        `customers/${customerId}/phonenumbers/${phoneNumber.id}`,
        getState().user.accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.phoneNumber.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(PhoneNumbers.savingDone).json);
    }

    dispatch(new SuccessAction(PhoneNumbers.savingDone).json);

    dispatch(
      new SuccessAction(PhoneNumbers.remove, {
        item: phoneNumber,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.phoneNumber.one),
        type: 'success',
      }),
    );
  };
};

export const createPhoneNumber = (
  customerId: string,
  phoneNumber: { createFrom: string },
  fetchDestination = false,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(PhoneNumbers.saving).json);

    let resultingPhoneNumber;
    try {
      resultingPhoneNumber = (await nvFetch(
        `customers/${customerId}/phonenumbers`,
        getState().user.accessToken,
        'POST',
        { ...phoneNumber, type: 'did' },
      )) as APIPhoneNumber;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.phoneNumber.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(PhoneNumbers.savingDone).json);
    }
    dispatch(new SuccessAction(PhoneNumbers.savingDone).json);

    if (!resultingPhoneNumber) {
      return;
    }
    dispatch(
      new SuccessAction(PhoneNumbers.receive, {
        items: [resultingPhoneNumber],
      }).json,
    );

    if (fetchDestination) {
      dispatch(
        getSinglePhoneExtension(customerId, resultingPhoneNumber.destination),
      );
    }

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.phoneNumber.one),
        type: 'success',
      }),
    );
  };
};

export const updateValidity = (
  property: keyof APIPhoneNumber,
  valid: boolean,
) =>
  validityAction(property, valid, 'phonenumbers', PhoneNumbers.updateValidity);
