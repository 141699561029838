import {
  APIProvisioningResponseDevice,
  APIProvisioningResponseTemplates,
} from '../interfaces/Provisioning';

export class FetchError extends Error {
  public status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export const fetchProvisioning = async <T>(
  urlPart: string,
  accessToken: string,
  body?: any,
  method?: string,
) => {
  const provisioningApi =
    process.env.REACT_APP_PROVISIONING_API || '/provisioning_api';
  let url: string;
  if (!provisioningApi.endsWith('/') && !urlPart.startsWith('/')) {
    url = `${provisioningApi}/${urlPart}`;
  } else if (provisioningApi.endsWith('/') && urlPart.startsWith('/')) {
    url = `${provisioningApi}${urlPart.substring(1)}`;
  } else {
    url = `${provisioningApi}${urlPart}`;
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (body) {
    headers['Content-Type'] = 'application/json';
  }
  const request = await fetch(url, {
    body: body ? JSON.stringify(body) : undefined,
    headers,
    method: method || (body ? 'POST' : 'GET'),
  });
  if (!request.ok) {
    // const errorJson = await request.json();
    throw new FetchError(request.status, request.statusText);
  }
  const result = await request.json();
  const data: T = result;
  return data;
};

export const getDevices = (endpointId: string, accessToken: string) =>
  fetchProvisioning<APIProvisioningResponseDevice>(endpointId, accessToken);
export const getTemplates = (accessToken: string) =>
  fetchProvisioning<APIProvisioningResponseTemplates>('templates', accessToken);
export const createDevice = (
  macAddress: string,
  accessToken: string,
  device: any,
) =>
  fetchProvisioning<APIProvisioningResponseDevice>('/', accessToken, {
    ...device,
    mac: macAddress,
  });
export const editDevice = (
  macAddress: string,
  accessToken: string,
  device: any,
) =>
  fetchProvisioning<APIProvisioningResponseDevice>(
    macAddress,
    accessToken,
    device,
    'PUT',
  );
export const removeDevice = (macAddress: string, accessToken: string) =>
  fetchProvisioning<APIProvisioningResponseDevice>(
    macAddress,
    accessToken,
    undefined,
    'DELETE',
  );
