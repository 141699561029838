import Things from '../interfaces/Things';
import Thing from '../interfaces/Thing';
import { ActionInterface, SuccessAction } from '../helpers/actionBuilder';
import State from '../interfaces/State';
import Toast from '../interfaces/Toast';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';
import { IdMap, Validity } from '../interfaces/interfaces';

export type FetchAction = (
  dispatch: (action: ActionInterface) => void,
  getState: () => State,
  page: number,
  params?: any,
) => Promise<Things<Thing> | null>;

export type DefaultThunkAction = ThunkAction<any, State, null, ActionInterface>;

export type ThunkFunction = (...params: any[]) => DefaultThunkAction;

export const createSimpleArraySelector = <T extends { id: string }>(
  mapSelector: (state: State) => IdMap<T>,
  sortField: keyof T = 'id',
  converToNumber = true,
) =>
  createSelector(mapSelector, (itemsById) =>
    Object.keys(itemsById)
      .map((id) => itemsById[id])
      .sort((a, b) => {
        if (converToNumber) {
          return Number(a[sortField]) - Number(b[sortField]);
        }
        if (a === b) {
          return 0;
        }
        return a > b ? 1 : -1;
      }),
  );

const hasKey = <O>(obj: O, key: keyof any): key is keyof O => {
  return key in obj;
};

export const validityAction = <T extends object>(
  property: keyof T,
  valid: boolean,
  stateKey: keyof State,
  actionType: string,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const state = getState()[stateKey];
    if ('valid' in state && hasKey(state.valid, property)) {
      if (state.valid[property] === valid) return;
      actionType &&
        dispatch(
          new SuccessAction(actionType, {
            validity: { [property]: valid },
          }).json,
        );
    }
  };
};

/**
 * Tests to see if an object is invalid
 *
 * Pushes a toast detailing invalid fields and throws an error if found to be invalid
 *
 * Otherwise returns `undefined`
 * @param validState
 * @param dispatch
 * @param pushToastAction
 */
export const blockInvalid = <T extends object>(
  validState: Validity<T>,
  dispatch: (action: ActionInterface) => void,
  pushToastAction: (toast: string | Toast) => ActionInterface,
) => {
  const valid = Object.values(validState);
  const isInvalid = valid.some((validity) => !validity);
  if (isInvalid) {
    const invalidFields = Object.entries(validState)
      .filter(([_, valid]) => {
        return !valid;
      })
      .map(([field]) => field)
      .join(', ');
    dispatch(
      pushToastAction({
        delay: 4000,
        message:
          'Please fix the validation issues on the following fields: ' +
          invalidFields,
        type: 'danger',
      }),
    );
    throw new Error(
      'Please fix the validation issues on the following fields: ' +
        invalidFields,
    );
  }
};
