import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { CallCharging } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import {
  APICallCharging,
  APIChargingPlan,
} from '../../interfaces/CallCharging';
import Things from '../../interfaces/Things';
import { activeCustomerSelector } from '../../selectors/customer';
import { fetchMsg, saveMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(CallCharging.clear).json;
};

export const clearChargingPlans = (): ActionInterface => {
  return new SuccessAction(CallCharging.chargingPlans.clear).json;
};

export const getCallCharging = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(CallCharging.fetching).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/callcharging`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('call charging', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(CallCharging.fetchingDone).json);
    dispatch(
      new SuccessAction(CallCharging.receive, {
        callCharging: data,
      }).json,
    );
  };
};

export const getChargingPlans = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(CallCharging.chargingPlans.fetching).json);
    const partnerId = activeCustomerSelector(getState()).partnerId;
    let requestUrl = `chargingplans`;
    if (partnerId) {
      requestUrl += `?partnerId=${partnerId}`;
    }
    let data;
    try {
      data = (await nvFetch(requestUrl, getState().user.accessToken)) as Things<
        APIChargingPlan
      >;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('charging plans', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(CallCharging.chargingPlans.fetchingDone).json);
    dispatch(
      new SuccessAction(CallCharging.chargingPlans.receive, {
        chargingPlans: data.items,
      }).json,
    );
  };
};

export const saveCallCharging = (callCharging: APICallCharging): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(CallCharging.saving).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${activeCustomerId}/callcharging`,
        accessToken,
        'PUT',
        callCharging,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg('call charge', true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(CallCharging.savingDone).json);
    }

    dispatch(
      new SuccessAction(CallCharging.receive, {
        callCharging: data,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('call charging'),
        type: 'success',
      }),
    );
  };
};
