import deepFreeze from 'deep-freeze';

import { Limits } from '../actions';

import ILimits, { APILimits } from '../../interfaces/Limits';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState: ILimits = {
  fetching: false,
  limits: undefined,
  saving: false,
};

/**
 * Clear limits
 */
const clear = (state: ILimits = initialState): ILimits => {
  return {
    ...{},
    ...state,
    limits: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: ILimits = initialState): ILimits => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive limits
 */
const receive = (state: ILimits = initialState, limits: APILimits): ILimits => {
  return {
    ...{},
    ...state,
    limits,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: ILimits = initialState): ILimits => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: ILimits = initialState): ILimits => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: ILimits = initialState): ILimits => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

interface ActionLimits extends ActionInterface {
  payload: {
    limits: APILimits;
  };
}

export default (
  state: ILimits = initialState,
  action?: ActionLimits,
): ILimits => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Limits.clear:
      return clear(state);

    case Limits.fetching:
      return fetching(state);

    case Limits.receive:
      return receive(state, action.payload.limits);

    case Limits.fetchingDone:
      return fetchingDone(state);

    case Limits.saving:
      return saving(state);

    case Limits.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
