import deepFreeze from 'deep-freeze';

import { Provisioning } from '../actions';

import IProvisioning, {
  APIProvisioning,
  ProvDevice,
  ValidationError,
} from '../../interfaces/Provisioning';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: IProvisioning = {
  availableDevices: [],
  fetching: false,
  provisioningByMac: {},
  saving: false,
  validationErrors: undefined,
};

const clear = (state: IProvisioning = initialState): IProvisioning => {
  return {
    ...{},
    ...state,
    provisioningByMac: {},
    validationErrors: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IProvisioning = initialState): IProvisioning => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive provisioning
 */
const receive = (
  state: IProvisioning = initialState,
  receivedProvisioning: APIProvisioning[] = [],
): IProvisioning => {
  const { idMap } = arrayToIdMapAndOrdering(receivedProvisioning, 'mac');

  return {
    ...{},
    ...state,
    provisioningByMac: idMap,
    validationErrors: undefined,
  };
};

const receiveDevices = (
  state: IProvisioning = initialState,
  receivedDevices: ProvDevice[] = [],
): IProvisioning => ({ ...state, availableDevices: receivedDevices });

const removeDevice = (
  state: IProvisioning = initialState,
  macAddress: string,
): IProvisioning => {
  const { [macAddress]: _, ...restOfDevices } = state.provisioningByMac;
  return { ...state, provisioningByMac: restOfDevices };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IProvisioning = initialState): IProvisioning => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

const validationFailed = (
  state: IProvisioning = initialState,
  validationErrors: { [param: string]: ValidationError },
): IProvisioning => {
  return {
    ...{},
    ...state,
    fetching: false,
    validationErrors,
  };
};

interface ProvisioningAction extends ActionInterface {
  payload: {
    item?: APIProvisioning;
    items?: APIProvisioning[];
    validationErrors?: { [param: string]: ValidationError };
  };
}

export default (
  state: IProvisioning = initialState,
  action?: ProvisioningAction,
): IProvisioning => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Provisioning.clear:
      return clear(state);

    case Provisioning.fetching:
      return fetching(state);

    case Provisioning.receive:
      return receive(state, action.payload.items);

    case Provisioning.fetchingDone:
      return fetchingDone(state);

    case Provisioning.receiveDevices:
      return receiveDevices(state, action.payload.items);

    case Provisioning.removeDeviceSuccess:
      return removeDevice(state, action.payload.item.mac);

    case Provisioning.validationFailed:
      return validationFailed(state, action.payload.validationErrors);

    default:
      return state;
  }
};
