import { useSelector } from 'react-redux';
import { activeAccountTypeSelector } from '../selectors/customer';
import { terms as allTerms, titles as allTitles } from '../helpers/titles';
import { documentTitles as allDocumentTitles } from '../helpers/documentTitle';
import { useMemo } from 'react';
import { EndpointTypes } from '../interfaces/Endpoints';
import { AccountType } from '../interfaces/Customer';

export const useAccountConfig = () => {
  const activeAccountType = useSelector(activeAccountTypeSelector);
  const terms = useMemo(() => allTerms[activeAccountType], [activeAccountType]);
  const titles = useMemo(() => allTitles[activeAccountType], [
    activeAccountType,
  ]);
  const documentTitles = useMemo(() => allDocumentTitles[activeAccountType], [
    activeAccountType,
  ]);

  const isUnknown = activeAccountType === AccountType.UNKNOWN;
  const isBusiness = activeAccountType === AccountType.BUSINESS;
  const isResidentialPlus =
    activeAccountType === AccountType.RESIDENTIAL_PLUS ||
    activeAccountType === AccountType.RESIDENTIAL;
  const isResidential = activeAccountType === AccountType.RESIDENTIAL;

  const disabledFeatures: EndpointTypes[] = [];
  if (isResidentialPlus) {
    disabledFeatures.push('queue', 'trunk', 'ivr');
  }
  if (isResidential) {
    disabledFeatures.push('virtual', 'group', 'mailbox');
  }

  return {
    activeAccountType,
    documentTitles,
    terms,
    titles,
    disabledFeatures,
    isBusiness,
    isResidentialPlus,
    isResidential,
    isUnknown,
  };
};
