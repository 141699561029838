import deepFreeze from 'deep-freeze';

import { BargeGroups } from '../actions';

import BargeGroupState, { APIBargeGroup } from '../../interfaces/BargeGroups';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';

export const initialState: BargeGroupState = {
  bargeGroupsById: {},
  error: '',
  fetching: false,
  saving: false,
};

/**
 * Clear bargegroup
 */
const clear = (state: BargeGroupState = initialState): BargeGroupState => {
  return {
    ...state,
    bargeGroupsById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: BargeGroupState = initialState): BargeGroupState => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive barge group
 */
const receive = (
  state: BargeGroupState = initialState,
  receivedBargeGroup: APIBargeGroup[] = [],
): BargeGroupState => {
  const processedBargeGroups = processCreated(receivedBargeGroup);
  const { idMap } = arrayToIdMapAndOrdering(processedBargeGroups, 'id');

  return {
    ...state,
    bargeGroupsById: {
      ...state.bargeGroupsById,
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: BargeGroupState = initialState,
): BargeGroupState => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: BargeGroupState = initialState): BargeGroupState => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: BargeGroupState = initialState): BargeGroupState => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a barge group
 */
const remove = (
  state: BargeGroupState = initialState,
  bargeGroup: APIBargeGroup,
): BargeGroupState => {
  const { [bargeGroup.id]: _, ...rest } = state.bargeGroupsById;
  return {
    ...state,
    // TODO check
    bargeGroupsById: rest,
  };
};

interface BargeGroupAction extends ActionInterface {
  payload: {
    item?: APIBargeGroup;
    items?: APIBargeGroup[];
  };
}

export default (
  state: BargeGroupState = initialState,
  action?: BargeGroupAction,
): BargeGroupState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case BargeGroups.clear:
      return clear(state);

    case BargeGroups.fetching:
      return fetching(state);

    case BargeGroups.receive:
      return receive(state, action.payload.items);

    case BargeGroups.fetchingDone:
      return fetchingDone(state);

    case BargeGroups.remove:
      return remove(state, action.payload.item);

    case BargeGroups.saving:
      return saving(state);

    case BargeGroups.savingDone:
      return savingDone(state);

    default:
      return state;
  }
};
