import deepFreeze from 'deep-freeze';

import { Registrations } from '../actions';

import RegistrationState, {
  APIRegistration,
} from '../../interfaces/Registrations';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: RegistrationState = {
  error: '',
  fetching: false,
  registrationsById: {},
  saving: false,
};

const clear = (state: RegistrationState = initialState): RegistrationState => {
  return {
    ...{},
    ...state,
    registrationsById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (
  state: RegistrationState = initialState,
): RegistrationState => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive forwarding rule
 */
const receive = (
  state: RegistrationState = initialState,
  registrations: APIRegistration[] = [],
): RegistrationState => {
  const processedRegistrations = registrations.map((registration) => ({
    ...registration,
    expires: new Date(registration.expires),
    updated: new Date(registration.updated),
  }));

  const { idMap } = arrayToIdMapAndOrdering(processedRegistrations, 'id');

  return {
    ...{},
    ...state,
    registrationsById: idMap,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: RegistrationState = initialState,
): RegistrationState => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

interface RegsitrationAction extends ActionInterface {
  payload: {
    items?: APIRegistration[];
  };
}

export default (
  state: RegistrationState = initialState,
  action?: RegsitrationAction,
): RegistrationState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Registrations.clear:
      return clear(state);

    case Registrations.fetching:
      return fetching(state);

    case Registrations.receive:
      return receive(state, action.payload.items);

    case Registrations.fetchingDone:
      return fetchingDone(state);
    default:
      return state;
  }
};
