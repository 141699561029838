import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { CreditStatus } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { fetchMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(CreditStatus.clear).json;
};

export const getCreditStatus = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(CreditStatus.fetching).json);

    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/creditstatus`,
        accessToken,
      )) as any;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('credit status', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(CreditStatus.fetchingDone).json);

    if (!data) {
      return;
    }

    dispatch(
      new SuccessAction(CreditStatus.receive, {
        item: data,
      }).json,
    );
  };
};
