import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { CallRecordings } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { getRecordings } from '../../helpers/api';
import { APICallRecording } from '../../interfaces/CallRecordings';
import { nvFetch } from '../../helpers/nvFetch';
import { accountConfigSelector } from '../../selectors/customer';
import { fetchMsg, removeMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(CallRecordings.clear).json;
};

export const getCallRecordings = (
  requestOptions: {
    pageIndex: number;
    pageSize: number;
    sortKey?: string;
    sortAscending: boolean;
  },
  intervalStart: string,
  intervalEnd: string,
  extraParams?: { partyId?: string; createdBefore?: string },
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(CallRecordings.fetching).json);

    let data;
    try {
      data = await getRecordings(
        requestOptions,
        activeCustomerId,
        accessToken,
        intervalStart,
        intervalEnd,
        extraParams,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(terms.callRecording.other, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(CallRecordings.fetchingDone).json);
      return;
    }

    dispatch(new SuccessAction(CallRecordings.fetchingDone).json);

    dispatch(
      new SuccessAction(CallRecordings.receive, {
        callRecordings: data?.items || [],
        pageCount: Math.ceil(data.totalItems / data.pageSize),
      }).json,
    );
  };
};

export const deleteCallRecording = (callRecording: APICallRecording): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = callRecording;
    const { accessToken, activeCustomerId } = getState().user;

    try {
      await nvFetch(
        `customers/${activeCustomerId}/recordings/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.callRecording.one, true, error.message),
          type: 'danger',
        }),
      );
      return;
    }

    dispatch(
      new SuccessAction(CallRecordings.remove, {
        callRecording,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.callRecording.one),
        type: 'success',
      }),
    );
  };
};
