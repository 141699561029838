import deepFreeze from 'deep-freeze';

import CallBundlesState, { APICallBundle } from '../../interfaces/CallBundles';
import { ActionInterface } from '../../helpers/actionBuilder';
import { CallBundles } from '../actions';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';

export const initialState: CallBundlesState = {
  active: { callBundlesById: {}, fetching: false },
  error: '',
  expired: { callBundlesByPage: {}, fetching: false, pageCount: 1 },
  saving: false,
};

const clear = (state: CallBundlesState = initialState): CallBundlesState => {
  return {
    ...state,
    active: {
      ...state.active,
      callBundlesById: {},
    },
    expired: {
      ...state.expired,
      callBundlesByPage: {},
    },
  };
};

const saving = (state: CallBundlesState = initialState): CallBundlesState => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (
  state: CallBundlesState = initialState,
): CallBundlesState => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Set fetching flag to true
 */
const fetchingActive = (
  state: CallBundlesState = initialState,
): CallBundlesState => {
  return {
    ...state,
    active: { ...state.active, fetching: true },
  };
};

const receiveActive = (
  state: CallBundlesState = initialState,
  callBundles: APICallBundle[] = [],
): CallBundlesState => {
  const processedBundles = processCreated(callBundles);
  const { idMap } = arrayToIdMapAndOrdering(processedBundles, 'id');
  return {
    ...state,
    active: {
      ...state.active,
      callBundlesById: {
        ...state.active.callBundlesById,
        ...idMap,
      },
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingActiveDone = (
  state: CallBundlesState = initialState,
): CallBundlesState => {
  return {
    ...state,
    active: { ...state.active, fetching: false },
  };
};

/**
 * Set fetching flag to true
 */
const fetchingExpired = (
  state: CallBundlesState = initialState,
): CallBundlesState => {
  return {
    ...state,
    expired: { ...state.expired, fetching: true },
  };
};

const receiveExpired = (
  state: CallBundlesState = initialState,
  callBundles: APICallBundle[] = [],
  page: number,
  pageCount: number,
): CallBundlesState => {
  const processedBundles = processCreated(callBundles);
  return {
    ...state,
    expired: {
      ...state.expired,
      callBundlesByPage: {
        ...state.expired.callBundlesByPage,
        [page]: processedBundles,
      },
      pageCount,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingExpiredDone = (
  state: CallBundlesState = initialState,
): CallBundlesState => {
  return {
    ...state,
    expired: { ...state.expired, fetching: false },
  };
};

export default (
  state: CallBundlesState = initialState,
  action?: ActionInterface,
): CallBundlesState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case CallBundles.clear:
      return clear(state);

    case CallBundles.fetchingActive:
      return fetchingActive(state);

    case CallBundles.receiveActive:
      return receiveActive(state, action.payload.items);

    case CallBundles.fetchingActiveDone:
      return fetchingActiveDone(state);

    case CallBundles.fetchingExpired:
      return fetchingExpired(state);

    case CallBundles.receiveExpired:
      return receiveExpired(
        state,
        action.payload.items,
        action.payload.page,
        action.payload.pageCount,
      );

    case CallBundles.fetchingExpiredDone:
      return fetchingExpiredDone(state);

    case CallBundles.saving:
      return saving(state);

    case CallBundles.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
