import deepFreeze from 'deep-freeze';

import IVirtual, { APIVirtual } from '../../interfaces/Virtual';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';
import { Virtual } from '../actions';

export const initialState: IVirtual = {
  error: '',
  fetching: false,
  saving: false,
  valid: { destination: true, timeout: true },
  virtualExtensionsById: {},
};

const clear = (state: IVirtual = initialState): IVirtual => {
  return {
    ...state,
    virtualExtensionsById: {},
  };
};

const fetching = (state: IVirtual = initialState): IVirtual => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: IVirtual = initialState,
  virtuals: APIVirtual[] = [],
): IVirtual => {
  const processedVirtuals = processCreated(virtuals);
  const { idMap } = arrayToIdMapAndOrdering(processedVirtuals, 'id');

  return {
    ...state,
    virtualExtensionsById: {
      ...state.virtualExtensionsById,
      ...idMap,
    },
  };
};

const fetchingDone = (state: IVirtual = initialState): IVirtual => {
  return {
    ...state,
    fetching: false,
  };
};

const saving = (state: IVirtual = initialState): IVirtual => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: IVirtual = initialState): IVirtual => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: IVirtual = initialState,
  virtual: APIVirtual,
): IVirtual => {
  const { [virtual.id]: _, ...rest } = state.virtualExtensionsById;
  return {
    ...state,
    // TODO check
    virtualExtensionsById: rest,
  };
};
const updateValidity = (
  state: IVirtual = initialState,
  validity: Validity<APIVirtual>,
) => {
  return {
    ...state,
    valid: {
      ...state.valid,
      ...validity,
    },
  };
};

interface VirtualAction extends ActionInterface {
  payload: {
    items?: APIVirtual[];
    item?: APIVirtual;
    validity?: Validity<APIVirtual>;
  };
}

export default (
  state: IVirtual = initialState,
  action?: VirtualAction,
): IVirtual => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Virtual.clear:
      return clear(state);

    case Virtual.fetching:
      return fetching(state);

    case Virtual.fetchingDone:
      return fetchingDone(state);

    case Virtual.saving:
      return saving(state);

    case Virtual.savingDone:
      return savingDone(state);

    case Virtual.receive:
      return receive(state, action.payload.items);

    case Virtual.remove:
      return remove(state, action.payload.item);
    case Virtual.updateValidity:
      return updateValidity(state, action.payload.validity);
    default:
      return state;
  }
};
