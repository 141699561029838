import deepFreeze from 'deep-freeze';

import { RoutingRules } from '../actions';

import IRoutingRules, { APIRoutingRule } from '../../interfaces/RoutingRules';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: IRoutingRules = {
  error: '',
  fetching: false,
  routingRuleIdsByPhoneNumberId: {},
  routingRulesById: {},
  saving: false,
};

const clear = (state: IRoutingRules = initialState): IRoutingRules => {
  return {
    ...state,
    routingRuleIdsByPhoneNumberId: {},
    routingRulesById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IRoutingRules = initialState): IRoutingRules => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive routing rules
 */
const receive = (
  state: IRoutingRules = initialState,
  receivedRoutingRules: APIRoutingRule[] = [],
  phoneNumberId: string,
): IRoutingRules => {
  const processedRoutingRules = receivedRoutingRules.map((routingrule) => ({
    ...routingrule,
    created: new Date(routingrule.created),
  }));

  const { idMap, ordering } = arrayToIdMapAndOrdering(
    processedRoutingRules,
    'id',
  );

  return {
    ...state,
    routingRuleIdsByPhoneNumberId: {
      ...state.routingRuleIdsByPhoneNumberId,
      [phoneNumberId]: ordering,
    },
    routingRulesById: {
      ...state.routingRulesById,
      ...idMap,
    },
  };
};

/**
 * Receive an updated/created routing rule
 */
const update = (
  state: IRoutingRules = initialState,
  receivedRoutingRule: APIRoutingRule,
  phoneNumberId: string,
): IRoutingRules => {
  const processedRoutingRule = {
    ...receivedRoutingRule,
    created: new Date(receivedRoutingRule.created),
  };

  const stateMod: Partial<IRoutingRules> = {
    routingRulesById: {
      ...state.routingRulesById,
      [receivedRoutingRule.id]: processedRoutingRule,
    },
  };
  if (
    !state.routingRuleIdsByPhoneNumberId[phoneNumberId].includes(
      receivedRoutingRule.id,
    )
  ) {
    stateMod.routingRuleIdsByPhoneNumberId = {
      ...state.routingRuleIdsByPhoneNumberId,
      [phoneNumberId]: [
        ...state.routingRuleIdsByPhoneNumberId[phoneNumberId],
        receivedRoutingRule.id,
      ],
    };
  }

  return {
    ...state,
    ...stateMod,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IRoutingRules = initialState): IRoutingRules => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IRoutingRules = initialState): IRoutingRules => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: IRoutingRules = initialState): IRoutingRules => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a routing rule
 */
const remove = (
  state: IRoutingRules = initialState,
  routingRule: APIRoutingRule,
  phoneNumberId: string,
): IRoutingRules => {
  const { [routingRule.id]: _, ...rest } = state.routingRulesById;
  const newArray = state.routingRuleIdsByPhoneNumberId[phoneNumberId].filter(
    (e) => e !== routingRule.id,
  );
  return {
    ...state,
    routingRuleIdsByPhoneNumberId: {
      ...state.routingRuleIdsByPhoneNumberId,
      [phoneNumberId]: newArray,
    },
    routingRulesById: rest,
    // TODO check
  };
};

interface RoutingRuleAction extends ActionInterface {
  payload: {
    item?: APIRoutingRule;
    items?: APIRoutingRule[];
    incomingNumberId?: string;
  };
}

export default (
  state: IRoutingRules = initialState,
  action?: RoutingRuleAction,
): IRoutingRules => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case RoutingRules.clear:
      return clear(state);

    case RoutingRules.fetching:
      return fetching(state);

    case RoutingRules.receive:
      return receive(
        state,
        action.payload.items,
        action.payload.incomingNumberId,
      );

    case RoutingRules.update:
      return update(
        state,
        action.payload.item,
        action.payload.incomingNumberId,
      );

    case RoutingRules.fetchingDone:
      return fetchingDone(state);

    case RoutingRules.remove:
      return remove(
        state,
        action.payload.item,
        action.payload.incomingNumberId,
      );

    case RoutingRules.saving:
      return saving(state);

    case RoutingRules.savingDone:
      return savingDone(state);

    default:
      return state;
  }
};
