import React, { useEffect } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Loading from '../Loading';
import Toast from '../Toast';

import { history } from '../../helpers/history';

import { Props } from './interfaces';
import DocumentTitle from '../DocumentTitle';
import PageNotFound from '../PageNotFound';
import { useAccountConfig } from '../../hooks/useAccountConfig';

const AcceptInvite = React.lazy(() => import('../AcceptInvite'));
const Customer = React.lazy(() => import('../Customer'));
const Customers = React.lazy(() => import('../Customers'));
const Login = React.lazy(() => import('../Login'));
const Logout = React.lazy(() => import('../Logout'));
const User = React.lazy(() => import('../User'));
const Recommend = React.lazy(() => import('../Recommend'));

const Root: React.FC<Props> = ({
  toasts,
  shiftToast,
  hasBranding,
  receiveBranding,
  loginRedirectUrl,
  showRecommend,
}) => {
  let toastNode: JSX.Element;

  const { isResidentialPlus } = useAccountConfig();

  useEffect(() => {
    receiveBranding();
  }, [receiveBranding]);

  if (toasts.length > 0) {
    const toast = toasts[0];

    toastNode = (
      <Toast
        buttons={toast.buttons}
        delay={toast.delay}
        id={toast.id}
        message={toast.message}
        removing={toast.removing}
        shift={shiftToast}
        type={toast.type}
      />
    );
  }
  if (!hasBranding) return null;
  return (
    <>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route component={Login} exact path='/login' />

            <Route component={Logout} exact path='/logout' />

            {!loginRedirectUrl && <Route component={User} exact path='/user' />}

            <Route component={Customer} path={'/c/:customerId'} />

            <Route component={Customers} path={'/c'} />

            <Route component={AcceptInvite} path={'/acceptinvite'} />
            {showRecommend && !isResidentialPlus ? (
              <Route component={Recommend} path='/recommend' />
            ) : (
              <Route path='/recommend'>
                <Redirect to='/404' />
              </Route>
            )}
            <Route path='/404'>
              <PageNotFound />
            </Route>
            <Redirect from='*' to='/c' />
          </Switch>
        </React.Suspense>
        <DocumentTitle />
      </ConnectedRouter>

      {toastNode}
    </>
  );
};

export default Root;
