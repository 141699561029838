import React, { useMemo } from 'react';
import { Props } from './interfaces';
import { NvTopbar } from '@simwood/nv-react-components-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appsObjectToArray } from '../../helpers/branding';
import { useHistory } from 'react-router';
import './styles.module.scss';
import { getDisplayName } from '../../helpers/linkedUser';
import { useAccountConfig } from '../../hooks/useAccountConfig';

const TopBar: React.FC<Props> = ({
  user,
  customer,
  logout,
  brand,
  clearCustomers,
  onMenuClick,
  loginRedirectUrl,
  showRecommend,
}) => {
  const { isResidentialPlus, isBusiness, isResidential } = useAccountConfig();
  // Remove analytics from app switcher for residential plus accounts
  const appsByName = useMemo(() => {
    const res = { ...brand.apps };
    if (isResidentialPlus && !!res.analytics) {
      delete res.analytics;
    }
    return res;
  }, [brand.apps, isResidentialPlus]);
  const history = useHistory();
  const apps = isResidential ? [] : appsObjectToArray(appsByName);
  const displayName = getDisplayName(user);

  let productName = 'Manager';
  if (isResidentialPlus && !isResidential) {
    productName = 'Residential Plus';
  } else if (isResidential) {
    productName = 'Residential';
  } else if (isBusiness) {
    productName = 'Business';
  }

  // Hide user profile option in menu if loginRedirectUrl is present
  const profileMenuItems = !!loginRedirectUrl
    ? []
    : [
        {
          icon: <FontAwesomeIcon fixedWidth icon={['far', 'user']} />,
          label: 'User Profile',
          onClick: () => {
            history.push('/user');
          },
        },
      ];

  const recommendMenuItem = showRecommend
    ? [
        {
          icon: <FontAwesomeIcon fixedWidth icon={['far', 'heart']} />,
          label: 'Recommend',
          onClick: () => {
            history.push('/recommend');
          },
        },
      ]
    : [];

  const userMenuItems = [
    {
      label: displayName,
    },
    ...profileMenuItems,
    ...recommendMenuItem,
    {
      icon: <FontAwesomeIcon fixedWidth icon={['far', 'exchange']} />,
      label: `Switch Account`,
      onClick: () => {
        clearCustomers();
        history.push('/c');
      },
    },
    {
      icon: <FontAwesomeIcon fixedWidth icon={['far', 'sign-out']} />,
      label: 'Log Out',
      onClick: logout,
    },
  ];

  let helpMenuItems: any = [
    {
      href: brand.branding.termsUrl,
      label: 'Terms',
    },
    {
      href: brand.branding.refundsUrl,
      label: 'Refunds',
    },
    {
      href: brand.branding.contactsUrl,
      label: 'Contact',
    },
  ];

  if (brand.apps.manager.kbLinkLocation) {
    helpMenuItems = [
      {
        href: brand.apps.manager.kbLinkLocation,
        icon: <FontAwesomeIcon fixedWidth icon={['far', 'graduation-cap']} />,
        label: 'Knowledge Base',
      },
      ...helpMenuItems,
    ];
  }
  const companyString = customer?.partnerCompany
    ? `${customer?.partnerCompany}  >  ${customer?.company}`
    : customer?.company;
  return (
    <NvTopbar
      brandSrc={brand.productLogoSrc || brand.companyLogoSrc}
      product={productName}
      brand={!(brand.productLogoSrc || brand.companyLogoSrc) && brand.brand}
      resellerName={companyString}
      userMenuItems={userMenuItems as any} // remove as any when component library v2 version > 0.6.61
      helpMenuItems={helpMenuItems}
      apps={apps}
      inverted={brand.branding.topbarInverted}
      userEmail={user.email}
      showLeftHamburger
      hamburgerBreakpoint={800}
      onHamburgerClick={onMenuClick}
    />
  );
};

export default TopBar;
