import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Permissions } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { APIPermissions } from '../../interfaces/Permissions';
import { fetchMsg, saveMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(Permissions.clear).json;
};

export const getPermissions = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Permissions.fetching).json);

    let data; //APIPermissions
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/permissions`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('permissions', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(Permissions.fetchingDone).json);
    dispatch(
      new SuccessAction(Permissions.receive, {
        permissions: data,
      }).json,
    );
  };
};

export const savePermissions = (permissions: APIPermissions): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Permissions.saving).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${activeCustomerId}/permissions`,
        accessToken,
        'PUT',
        permissions,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg('permission', true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Permissions.savingDone).json);
    }
    dispatch(new SuccessAction(Permissions.savingDone).json);
    dispatch(
      new SuccessAction(Permissions.receive, {
        permissions: data,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('permissions'),
        type: 'success',
      }),
    );
  };
};
