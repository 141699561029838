import deepFreeze from 'deep-freeze';

import StatePhonebook, { APIPhonebook } from '../../interfaces/Phonebook';

import { ActionInterface } from '../../helpers/actionBuilder';
import { Phonebook } from '../actions';
import { processCreated } from '../../helpers/utils';

export const initialState: StatePhonebook = {
  error: '',
  fetching: false,
  pageCount: 1,
  pageSize: 10,
  phonebookForTable: [],
  saving: false,
};

const clear = (state: StatePhonebook = initialState): StatePhonebook => {
  return {
    ...state,
    phonebookForTable: [],
  };
};

const fetching = (state: StatePhonebook = initialState): StatePhonebook => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: StatePhonebook = initialState,
  phonebook: APIPhonebook[] = [],
  pageCount: number,
  pageSize: number,
): StatePhonebook => {
  const processedPhonebook = processCreated(phonebook);

  return {
    ...state,
    pageCount,
    pageSize,
    phonebookForTable: processedPhonebook,
  };
};

const remove = (
  state: StatePhonebook = initialState,
  phonebook: APIPhonebook,
): StatePhonebook => {
  const removedPhonebook = [...state.phonebookForTable].filter(
    (x) => x.id !== phonebook.id,
  );
  return {
    ...state,
    phonebookForTable: removedPhonebook,
  };
};

const fetchingDone = (state: StatePhonebook = initialState): StatePhonebook => {
  return {
    ...state,
    fetching: false,
  };
};

const saving = (state: StatePhonebook = initialState): StatePhonebook => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: StatePhonebook = initialState): StatePhonebook => {
  return {
    ...state,
    saving: false,
  };
};

export default (
  state: StatePhonebook = initialState,
  action?: ActionInterface,
): StatePhonebook => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Phonebook.clear:
      return clear(state);

    case Phonebook.fetching:
      return fetching(state);

    case Phonebook.receive:
      return receive(
        state,
        action.payload.phonebook,
        action.payload.pageCount,
        action.payload.pageSize,
      );

    case Phonebook.remove:
      return remove(state, action.payload.item);

    case Phonebook.saving:
      return saving(state);

    case Phonebook.savingDone:
      return savingDone(state);

    case Phonebook.fetchingDone:
      return fetchingDone(state);
    default:
      return state;
  }
};
