import { createBrowserHistory } from 'history';

// import { browserHistory } from 'react-router';

export const history = createBrowserHistory({
  basename: '/',
});

/**
 * Auto prepends root for page navigation
 */
export const navigate = (to = '') => {
  // let rootedTo = (root + to).replace(/\/\//g, '/');

  // history.push(rootedTo + window.location.search);

  history.push(to + window.location.search);
};
