import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import TopBar from './component';

import State from '../../interfaces/State';

import { user, customers } from '../../store/actions';
import { activeCustomerSelector } from '../../selectors/customer';
const { logout } = user;

const mapStateToProps = (state: State) => {
  const { branding } = state.branding;
  const allowedUserTypes = ['CUSTOMER', 'EXTENSION'];
  const showRecommend =
    branding.branding.recommendEnabled &&
    allowedUserTypes.includes(state.user.accessLevel);
  return {
    brand: branding,
    customer: activeCustomerSelector(state),
    loginRedirectUrl: branding.branding.loginRedirectUrl,
    showRecommend,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    // TODO also need to clear ivrs, queues, groups etc...
    clearCustomers: () => dispatch(customers.clear()),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
