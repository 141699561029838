import { createSelector } from 'reselect';
import State from '../interfaces/State';
import { getDisplayName } from '../helpers/linkedUser';

const linkedUsersByIdSelector = (state: State) =>
  state.linkeduser.linkedUsersById;
export const activeLinkedUserSelector = (state: State) =>
  state.linkeduser.activeLinkedUser;

export const linkedUsersSelector = createSelector(
  linkedUsersByIdSelector,
  (linkedUsersByUserId) => {
    return Object.keys(linkedUsersByUserId)
      .map((id) => linkedUsersByUserId[id])
      .sort((a, b) => getDisplayName(a).localeCompare(getDisplayName(b)));
  },
);

// export const activeLinkedUserSelector = createSelector(
//   linkedUsersByIdSelector,
//   activeLinkedUserIdSelector,
//   (linkedUsersById, activeLinkedUserId) => linkedUsersById[activeLinkedUserId],
// );

export const activeLinkedUserAccessLevelSelector = createSelector(
  activeLinkedUserSelector,
  (activeLinkedUser) =>
    !activeLinkedUser ? 'EXTENSION' : activeLinkedUser.accessLevel,
);

export const isExtensionUserSelector = createSelector(
  activeLinkedUserSelector,
  (linkeduser) => {
    return !linkeduser || linkeduser.accessLevel === 'EXTENSION';
  },
);

export const isHighLevelLinkedUserSelector = createSelector(
  activeLinkedUserSelector,
  (linkeduser) =>
    linkeduser &&
    ['ADMIN', 'SUPPORT_L2', 'SUPPORT_L1', 'RESELLER'].includes(
      linkeduser.accessLevel,
    ),
);

export const allLinkedUserEmailsSelector = createSelector(
  linkedUsersSelector,
  (linkedUsers) =>
    linkedUsers
      .map((linkedUser) => linkedUser.email?.toLowerCase())
      .filter((email) => !!email),
);
