import deepFreeze from 'deep-freeze';

import ICallRecordings, {
  APICallRecording,
} from '../../interfaces/CallRecordings';
import { ActionInterface } from '../../helpers/actionBuilder';
import { CallRecordings } from '../actions';

export const initialState: ICallRecordings = {
  // creditstatus: {},
  callRecordingsForTable: [],
  fetching: false,
  pageCount: 1,
};

/**
 * Clear credit status
 */
const clear = (state: ICallRecordings = initialState): ICallRecordings => {
  return {
    ...state,
    callRecordingsForTable: [],
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: ICallRecordings = initialState): ICallRecordings => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive call recordings
 */
const receive = (
  state: ICallRecordings = initialState,
  callRecordings: APICallRecording[],
  pageCount: number,
): ICallRecordings => {
  return {
    ...state,
    callRecordingsForTable: callRecordings,
    pageCount,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: ICallRecordings = initialState,
): ICallRecordings => {
  return {
    ...state,
    fetching: false,
  };
};

const remove = (
  state: ICallRecordings = initialState,
  callRecording: APICallRecording,
): ICallRecordings => {
  return {
    ...state,
    callRecordingsForTable: state.callRecordingsForTable.filter(
      (x) => x.id !== callRecording.id,
    ),
  };
};

export default (
  state: ICallRecordings = initialState,
  action?: ActionInterface,
): ICallRecordings => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case CallRecordings.clear:
      return clear(state);

    case CallRecordings.fetching:
      return fetching(state);

    case CallRecordings.receive:
      return receive(
        state,
        action.payload.callRecordings,
        action.payload.pageCount,
      );
    case CallRecordings.fetchingDone:
      return fetchingDone(state);
    case CallRecordings.remove:
      return remove(state, action.payload.callRecording);
    default:
      return state;
  }
};
