import React from 'react';

import { NvResponsiveGrid, NvErrorPage } from '@simwood/nv-react-components-v2';
import TopBar from './TopBar';
import { navigate } from '../helpers/history';
const { Root } = NvResponsiveGrid;

const PageNotFound: React.FC = () => {
  return (
    <Root
      topbar={<TopBar />}
      content={
        <NvErrorPage
          message='Page not found'
          title='404'
          buttonText='Go home'
          buttonOnClick={() => {
            navigate('/c');
          }}
        />
      }
    ></Root>
  );
};

export default PageNotFound;
