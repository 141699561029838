import { ErrorAction, SuccessAction } from '../helpers/actionBuilder';
import { fetchMsg } from '../helpers/actionMessageCreator';
import { nvFetch } from '../helpers/nvFetch';
import { AccountTerms } from '../helpers/titles';
import { sleep } from '../helpers/utils';
import Thing from '../interfaces/Thing';
import Things from '../interfaces/Things';
import { accountConfigSelector } from '../selectors/customer';
import { DefaultThunkAction } from './helpers';
import * as toastActions from './toasts/actions';

const NUM_RETRIES = 1;
const RETRY_DELAY = 500;

export const completelyFetchResource = async <T extends Thing>(
  resourceUrl: string,
  queryString: string,
  params: {
    pageSize: number;
  },
  accessToken: string,
  activeCustomerId: string,
  onPageFetched: (items: T[]) => void,
) => {
  let page = 1;
  let lastError: Error;

  while (true) {
    let data: Things<T>;
    let retriesLeft = NUM_RETRIES;
    while (retriesLeft >= 0) {
      try {
        data = (await nvFetch(
          `customers/${activeCustomerId}/${resourceUrl}?page=${page}&pageSize=${params.pageSize}${queryString}`,
          accessToken,
        )) as Things<T>;
        // dispatch(
        //   new SuccessAction(actionsTypes.receive, {
        //     items: data.items,
        //   }).json,
        // );
        onPageFetched(data.items);
        break;
      } catch (error) {
        retriesLeft -= 1;
        lastError = error as Error;
        if (retriesLeft >= 0) {
          await sleep(RETRY_DELAY);
        }
      }
    }

    if (retriesLeft < 0 && lastError) {
      throw lastError;
    }

    if (data?.nextPage) {
      page += 1;
    } else {
      break;
    }
  }
};

export const completelyFetchResourceIntoStore = <T extends Thing>(
  resourceUrl: string,
  getFeatureName: (terms: AccountTerms) => string,
  actionsTypes: { fetching: string; fetchingDone: string; receive: string },
  params: {
    pageSize: number;
  },
  queryParams?: Record<string, string>,
): DefaultThunkAction => {
  return async (dispatch, getState) => {
    dispatch(new SuccessAction(actionsTypes.fetching).json);
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;

    const queryArray: string[] = [];
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        queryArray.push(`${key}=${value}`);
      });
    }
    const queryString = queryArray.length > 0 ? `&${queryArray.join('&')}` : '';

    try {
      await completelyFetchResource<T>(
        resourceUrl,
        queryString,
        params,
        accessToken,
        activeCustomerId,
        (items) => {
          dispatch(
            new SuccessAction(actionsTypes.receive, {
              items,
            }).json,
          );
        },
      );

      dispatch(new SuccessAction(actionsTypes.fetchingDone).json);
    } catch (err) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(getFeatureName(terms), true, err.message),
          type: 'danger',
        }),
      );

      dispatch(new ErrorAction(actionsTypes.fetchingDone).json);
    }
  };
};
