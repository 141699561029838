import { ActionInterface } from '../../helpers/actionBuilder';

import { Customers } from '../actions';

import ICustomers, { APICustomer } from '../../interfaces/Customer';
import deepFreeze from 'deep-freeze';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';

export const initialState: ICustomers = {
  customersById: {},
  error: '',
  fetching: false,
  saving: false,
};

/**
 * Clear customers
 */
const clear = (state: ICustomers = initialState): ICustomers => {
  return {
    ...state,
    customersById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: ICustomers = initialState): ICustomers => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: ICustomers = initialState): ICustomers => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Receive customers
 */
const receive = (
  state: ICustomers = initialState,
  customers: APICustomer[] = [],
): ICustomers => {
  const processedCustomers: APICustomer[] = processCreated(customers);

  const { idMap } = arrayToIdMapAndOrdering(processedCustomers, 'id');

  return {
    ...state,
    customersById: {
      ...state.customersById,
      ...idMap,
    },
  };
};
/**
 * Set saving flag to true
 */
const saving = (state: ICustomers = initialState): ICustomers => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: ICustomers = initialState): ICustomers => {
  return {
    ...state,
    saving: false,
  };
};

interface CustomersAction extends ActionInterface {
  payload: {
    items?: APICustomer[];
  };
}

export default (
  state: ICustomers = initialState,
  action?: CustomersAction,
): ICustomers => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Customers.clear:
      return clear(state);

    case Customers.fetching:
      return fetching(state);

    case Customers.fetchingDone:
      return fetchingDone(state);

    case Customers.receive:
      return receive(state, action.payload.items as APICustomer[]);

    case Customers.saving:
      return saving(state);

    case Customers.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
