import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { Provider } from 'react-redux';

import Root from './components/Root';
import ErrorBoundary from './components/ErrorBoundary';
import { Tooltip } from '@simwood/nv-react-components-v2';
import 'normalize.css';
import 'react-circular-progressbar/dist/styles.css';
// import 'react-table/react-table.css';
import 'tippy.js/dist/tippy.css';

import './index.scss';
import './brand.scss';

import store from './store';
import { X_PARTNER_ID } from './helpers/xPartnerId';

library.add(fal, far, fas);

const querystring = window.location.search;
const params = new URLSearchParams(querystring);
const partnerId = params.get('partnerId');
const accessToken = params.get('accessToken');
const expires = params.get('expires');
// set partnerId via query
X_PARTNER_ID.set(partnerId);
// set authentication via query
if (accessToken && expires) {
  window.localStorage.setItem('nv-token', JSON.stringify(accessToken));
  window.localStorage.setItem('nv-token-expires', JSON.stringify(expires));
}

ReactDOM.render(
  <Provider store={store}>
    <Tooltip.Provider
      appName='manager'
      partnerId={partnerId}
      globalModalRoot={document.getElementById('modal-root')}
    >
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </Tooltip.Provider>
  </Provider>,
  document.getElementById('root'),
);
