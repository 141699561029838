import deepFreeze from 'deep-freeze';

import { AreaCodes } from '../actions';

import IAreaCodes, { APIAreaCode } from '../../interfaces/AreaCodes';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState: IAreaCodes = {
  areaCodesByDialCode: {},
  error: '',
  fetching: false,
};

/**
 * Clear toasts
 */
const clear = (state: IAreaCodes = initialState): IAreaCodes => {
  return {
    ...{},
    ...state,
    areaCodesByDialCode: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IAreaCodes = initialState): IAreaCodes => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive area codes
 */
const receive = (
  state: IAreaCodes = initialState,
  receivedAreaCodes: APIAreaCode[] = [],
): IAreaCodes => {
  const areaCodesByDialCode = { ...state.areaCodesByDialCode };

  receivedAreaCodes.forEach((newAreaCode) => {
    if (!areaCodesByDialCode[newAreaCode.country]) {
      areaCodesByDialCode[newAreaCode.country] = [];
    }
    const areaCodeExists =
      areaCodesByDialCode[newAreaCode.country].findIndex(
        (ac) => ac.id === newAreaCode.id,
      ) !== -1;
    if (areaCodeExists) {
      // do nothing
    } else {
      areaCodesByDialCode[newAreaCode.country].push(newAreaCode);
    }
  });
  return {
    ...{},
    ...state,
    areaCodesByDialCode,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IAreaCodes = initialState): IAreaCodes => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

interface NumberingAction extends ActionInterface {
  payload: {
    areaCodes?: APIAreaCode[];
  };
}

export default (
  state: IAreaCodes = initialState,
  action?: NumberingAction,
): IAreaCodes => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case AreaCodes.clear:
      return clear(state);

    case AreaCodes.fetching:
      return fetching(state);

    case AreaCodes.receive:
      return receive(state, action.payload.areaCodes);

    case AreaCodes.fetchingDone:
      return fetchingDone(state);

    default:
      return state;
  }
};
