import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { Branding } from '../actions';
import { fetchBranding, rebrand } from '../../helpers/branding';

export const receiveBranding = (): any => {
  return async (dispatch: (action: ActionInterface) => void) => {
    fetchBranding().then((branding) => {
      if (branding) rebrand(branding);
      dispatch(new SuccessAction(Branding.receive, { branding }).json);
    });
  };
};
