import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import Root from './component';

import State from '../../interfaces/State';
// import Toast from '../../interfaces/Toast';

import { toasts, branding } from '../../store/actions';
const { shift } = toasts;

const mapStateToProps = (state: State) => {
  const {
    hasBranding,
    branding: {
      branding: { recommendEnabled },
    },
  } = state.branding;
  const showRecommend = recommendEnabled;
  return {
    hasBranding,
    loginRedirectUrl: state.branding.branding.branding.loginRedirectUrl,
    showRecommend,
    toasts: state.toasts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    // addToast: (toast: Toast) => dispatch(toasts.push(toast)),
    receiveBranding: () => dispatch(branding.receiveBranding()),
    shiftToast: () => dispatch(shift()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
