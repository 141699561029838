import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Provisioning } from '../actions';
import * as toastActions from '../toasts/actions';

import { APIProvisioning, ProvDevice } from '../../interfaces/Provisioning';
import State from '../../interfaces/State';
import { fetchMsg } from '../../helpers/actionMessageCreator';
import {
  createDevice,
  getDevices,
  getTemplates,
  removeDevice,
  editDevice,
} from '../../helpers/provisioning';

export const clear = (): ActionInterface => {
  return new SuccessAction(Provisioning.clear).json;
};

const getErrorMessage = (error: any) => {
  if (error.details) {
    const fields: string[] = [];
    Object.keys(error.details).forEach((k) => {
      fields.push(error.details[k].param);
    });
    const message = `Invalid or missing values for fields ${fields.join(', ')}`;
    return message;
  }
  return error.message;
};

export const getProvisioning = (endpointId: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Provisioning.fetching).json);
    const { accessToken /* , activeCustomerId */ } = getState().user;
    let data;
    try {
      data = await getDevices(endpointId, accessToken);
    } catch (error) {
      // Do nothing since it just means no provisioning info was added yet
      // dispatch(
      //   toastActions.push({
      //     delay: 10000,
      //     message: fetchMsg('provisioning info', true, error.message),
      //     type: 'danger',
      //   }),
      // );
      // return;
    }

    if (!data?.data?.devices) {
      data = {
        data: {
          devices: [],
        },
      };
    }
    dispatch(
      new SuccessAction(Provisioning.receive, {
        items: data.data.devices,
      }).json,
    );
    dispatch(new SuccessAction(Provisioning.fetchingDone).json);
  };
};

export const getAvailableDevices = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Provisioning.fetchingDevices).json);
    const { accessToken /* , activeCustomerId */ } = getState().user;
    let data;
    try {
      data = await getTemplates(accessToken);
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('provisioning info', true, getErrorMessage(error)),
          type: 'danger',
        }),
      );

      // return;
    }

    if (!data?.data?.templates) {
      data = {
        data: {
          templates: [],
        },
      };
    }
    dispatch(
      new SuccessAction(Provisioning.receiveDevices, {
        items: data.data.templates,
      }).json,
    );
    dispatch(new SuccessAction(Provisioning.fetchingDevicesDone).json);
  };
};

export const addDevice = (
  device: ProvDevice & {
    macAddress: string;
    endpointId: string;
    customerId: string;
  },
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Provisioning.addDevice).json);
    const { accessToken /* , activeCustomerId */ } = getState().user;
    let data;
    try {
      data = await createDevice(device.macAddress, accessToken, {
        customerId: device.customerId,
        extension: device.endpointId,
        manufacturer: device.manufacturer,
        model: device.model,
      });
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('provisioning info', true, getErrorMessage(error)),
          type: 'danger',
        }),
      );

      if (error.details) {
        // TODO there's ErrorAction but it doesn't quiet fit this use case
        dispatch(
          new SuccessAction(Provisioning.validationFailed, {
            validationErrors: error.details,
          }).json,
        );
      }
      dispatch(new SuccessAction(Provisioning.addDeviceDone).json);
      return;
    }

    if (!data?.data?.devices) {
      data = {
        data: {
          devices: [],
        },
      };
    }
    const oldProvisioningByMac = getState().provisioning.provisioningByMac;
    dispatch(
      new SuccessAction(Provisioning.receive, {
        items: [
          ...Object.keys(oldProvisioningByMac).map(
            (k) => oldProvisioningByMac[k],
          ),
          ...data.data.devices,
        ],
      }).json,
    );
    dispatch(new SuccessAction(Provisioning.addDeviceDone).json);
  };
};

export const editProvisioning = (
  device: ProvDevice & {
    macAddress: string;
  },
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Provisioning.editDevice).json);
    const { accessToken /* , activeCustomerId */ } = getState().user;
    const { provisioningByMac } = getState().provisioning;
    const { mac: _, requests: __, ...existingDevice } = provisioningByMac[
      device.macAddress
    ];
    const newDevice = { ...existingDevice, ...device };
    let data;
    try {
      data = await editDevice(device.macAddress, accessToken, newDevice);
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('provisioning info', true, getErrorMessage(error)),
          type: 'danger',
        }),
      );

      if (error.details) {
        // TODO there's ErrorAction but it doesn't quiet fit this use case
        dispatch(
          new SuccessAction(Provisioning.validationFailed, {
            validationErrors: error.details,
          }).json,
        );
      }

      dispatch(new SuccessAction(Provisioning.editDeviceDone).json);
      return;
    }

    if (!data?.data?.devices) {
      data = {
        data: {
          devices: [],
        },
      };
    }
    const oldProvisioningByMac = getState().provisioning.provisioningByMac;
    const newProvisioningByMac = { ...oldProvisioningByMac };
    data.data.devices.forEach((d: APIProvisioning) => {
      newProvisioningByMac[d.mac] = d;
    });
    dispatch(
      new SuccessAction(Provisioning.receive, {
        items: [
          ...Object.keys(newProvisioningByMac).map(
            (k) => newProvisioningByMac[k],
          ),
        ],
      }).json,
    );
    dispatch(new SuccessAction(Provisioning.editDeviceDone).json);
  };
};

export const removeProvisioning = (macAddress: string): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Provisioning.removeDevice).json);
    const { accessToken /* , activeCustomerId */ } = getState().user;
    try {
      await removeDevice(macAddress, accessToken);
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('provisioning info', true, getErrorMessage(error)),
          type: 'danger',
        }),
      );

      dispatch(new SuccessAction(Provisioning.removeDeviceDone).json);
      return;
    }
    dispatch(
      new SuccessAction(Provisioning.removeDeviceSuccess, {
        item: { mac: macAddress },
      }).json,
    );
    dispatch(new SuccessAction(Provisioning.removeDeviceDone).json);
  };
};
