import deepFreeze from 'deep-freeze';

import IPhoneExtension, { APIPhoneExtension } from '../../interfaces/Phone';

import { ActionInterface } from '../../helpers/actionBuilder';
import { PhoneExtension } from '../actions';
import { arrayToIdMapAndOrdering, processCreated } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: IPhoneExtension = {
  error: '',
  fetching: false,
  phoneExtensionsById: {},
  saving: false,
  valid: {
    email: true,
    timeout: true,
    voicemailSettings: true,
    hotdeskPin: true,
  },
};

const clear = (state: IPhoneExtension = initialState): IPhoneExtension => {
  return {
    ...state,
    phoneExtensionsById: {},
  };
};

const fetching = (state: IPhoneExtension = initialState): IPhoneExtension => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: IPhoneExtension = initialState,
  phoneExtensions: APIPhoneExtension[] = [],
): IPhoneExtension => {
  const processedPhoneExtensions = processCreated(phoneExtensions);
  const { idMap } = arrayToIdMapAndOrdering(processedPhoneExtensions, 'id');

  return {
    ...state,
    phoneExtensionsById: {
      ...state.phoneExtensionsById,
      ...idMap,
    },
  };
};

const fetchingDone = (
  state: IPhoneExtension = initialState,
): IPhoneExtension => {
  return {
    ...state,
    fetching: false,
  };
};

const saving = (state: IPhoneExtension = initialState): IPhoneExtension => {
  return {
    ...state,
    saving: true,
  };
};

const savingDone = (state: IPhoneExtension = initialState): IPhoneExtension => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: IPhoneExtension = initialState,
  phone: APIPhoneExtension,
): IPhoneExtension => {
  const { [phone.id]: _, ...rest } = state.phoneExtensionsById;
  return {
    ...state,
    phoneExtensionsById: rest,
    // TODO check
  };
};

const updateValidity = (
  state: IPhoneExtension = initialState,
  validity: Validity<APIPhoneExtension>,
) => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};

interface PhoneExtensionAction extends ActionInterface {
  payload: {
    items?: APIPhoneExtension[];
    item?: APIPhoneExtension;
    validity?: Validity<APIPhoneExtension>;
  };
}

export default (
  state: IPhoneExtension = initialState,
  action?: PhoneExtensionAction,
): IPhoneExtension => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case PhoneExtension.clear:
      return clear(state);

    case PhoneExtension.fetching:
      return fetching(state);

    case PhoneExtension.fetchingDone:
      return fetchingDone(state);

    case PhoneExtension.saving:
      return saving(state);

    case PhoneExtension.savingDone:
      return savingDone(state);

    case PhoneExtension.receive:
      return receive(state, action.payload.items as APIPhoneExtension[]);

    case PhoneExtension.remove:
      return remove(state, action.payload.item);

    case PhoneExtension.updateValidity:
      return updateValidity(state, action.payload.validity);

    default:
      return state;
  }
};
