import { createSelector } from 'reselect';
import State from '../interfaces/State';
import { getAllMonthsFrom } from '../helpers/utils';
import { AccountType } from '../interfaces/Customer';
import { terms as allTerms, titles as allTitles } from '../helpers/titles';
import { documentTitles as allDocumentTitles } from '../helpers/documentTitle';
import { countriesSelector } from './countries';

export const activeCustomerIdSelector = (state: State) =>
  state.user.activeCustomerId;
const customersByIdSelector = (state: State) => state.customers.customersById;

export const activeCustomerSelector = createSelector(
  activeCustomerIdSelector,
  customersByIdSelector,
  (activeCustomerId, customersById) => customersById[activeCustomerId],
);

export const activeAccountTypeSelector = createSelector(
  activeCustomerSelector,
  (activeCustomer) =>
    (!activeCustomer || !activeCustomer.accountType
      ? AccountType.UNKNOWN
      : activeCustomer.accountType) as AccountType,
);

export const accountConfigSelector = createSelector(
  activeAccountTypeSelector,
  (activeAccountType) => {
    const terms = allTerms[activeAccountType];
    const titles = allTitles[activeAccountType];
    const documentTitles = allDocumentTitles[activeAccountType];
    return { terms, titles, documentTitles, activeAccountType };
  },
);

export const activeCustomerMonthsSelector = createSelector(
  activeCustomerSelector,
  (activeCustomer) => {
    if (!activeCustomer) {
      return {} as {
        [k: string]: {
          start: string;
          end: string;
          name: string;
        };
      };
    }
    return getAllMonthsFrom(activeCustomer.created);
  },
);

export const customerDialCodeSelector = createSelector(
  activeCustomerSelector,
  countriesSelector,
  (customer, countries) => {
    const customerCountry = customer?.country;
    const dialCode = countries.find(
      (country) => country.isoAlpha2 === customerCountry,
    )?.dialCode;
    return dialCode;
  },
);

export const isUKCustomerSelector = createSelector(
  activeCustomerSelector,
  (customer) => {
    return customer.country === 'GB';
  },
);
