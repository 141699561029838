import deepFreeze from 'deep-freeze';

import { CallCharging } from '../actions';

import ICallCharging, {
  APICallCharging,
  APIChargingPlan,
} from '../../interfaces/CallCharging';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: ICallCharging = {
  callCharging: undefined,
  chargingPlans: {
    chargingPlansById: {},
    fetching: false,
  },
  fetching: false,
  saving: false,
};

const clear = (state: ICallCharging = initialState): ICallCharging => {
  return {
    ...{},
    ...state,
    callCharging: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: ICallCharging = initialState): ICallCharging => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive callCharging
 */
const receive = (
  state: ICallCharging = initialState,
  callCharging: APICallCharging,
): ICallCharging => {
  return {
    ...{},
    ...state,
    callCharging,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: ICallCharging = initialState): ICallCharging => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: ICallCharging = initialState): ICallCharging => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: ICallCharging = initialState): ICallCharging => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

const clearChargingPlans = (
  state: ICallCharging = initialState,
): ICallCharging => {
  return {
    ...{},
    ...state,
    chargingPlans: {
      ...state.chargingPlans,
      chargingPlansById: {},
    },
  };
};

/**
 * Set fetching flag to true
 */
const fetchingChargingPlans = (
  state: ICallCharging = initialState,
): ICallCharging => {
  return {
    ...{},
    ...state,
    chargingPlans: { ...state.chargingPlans, fetching: true },
  };
};

/**
 * Receive chargingPlans
 */
const receiveChargingPlans = (
  state: ICallCharging = initialState,
  chargingPlans: APIChargingPlan[],
): ICallCharging => {
  const { idMap } = arrayToIdMapAndOrdering(chargingPlans, 'id');
  return {
    ...{},
    ...state,
    chargingPlans: {
      ...state.chargingPlans,
      chargingPlansById: idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingChargingPlansDone = (
  state: ICallCharging = initialState,
): ICallCharging => {
  return {
    ...{},
    ...state,
    chargingPlans: {
      ...state.chargingPlans,
      fetching: false,
    },
  };
};

interface ActionCallCharging extends ActionInterface {
  payload: {
    callCharging: APICallCharging;
    chargingPlans: APIChargingPlan[];
  };
}

export default (
  state: ICallCharging = initialState,
  action?: ActionCallCharging,
): ICallCharging => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case CallCharging.clear:
      return clear(state);

    case CallCharging.fetching:
      return fetching(state);

    case CallCharging.receive:
      return receive(state, action.payload.callCharging);

    case CallCharging.fetchingDone:
      return fetchingDone(state);

    case CallCharging.saving:
      return saving(state);

    case CallCharging.savingDone:
      return savingDone(state);

    case CallCharging.chargingPlans.clear:
      return clearChargingPlans(state);

    case CallCharging.chargingPlans.fetching:
      return fetchingChargingPlans(state);

    case CallCharging.chargingPlans.receive:
      return receiveChargingPlans(state, action.payload.chargingPlans);

    case CallCharging.chargingPlans.fetchingDone:
      return fetchingChargingPlansDone(state);

    default:
      return state;
  }
};
