import deepFreeze from 'deep-freeze';

import { Preferences } from '../actions';

import IPreferences, { APIPreferences } from '../../interfaces/Preferences';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState: IPreferences = {
  fetching: false,
  preferences: undefined,
  saving: false,
};

/**
 * Clear Preferences
 */
const clear = (state: IPreferences = initialState): IPreferences => {
  return {
    ...{},
    ...state,
    preferences: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IPreferences = initialState): IPreferences => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive preferences
 */
const receive = (
  state: IPreferences = initialState,
  preferences: APIPreferences,
): IPreferences => {
  return {
    ...{},
    ...state,
    preferences,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IPreferences = initialState): IPreferences => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IPreferences = initialState): IPreferences => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: IPreferences = initialState): IPreferences => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

interface ActionPreferences extends ActionInterface {
  payload: {
    preferences: APIPreferences;
  };
}

export default (
  state: IPreferences = initialState,
  action?: ActionPreferences,
): IPreferences => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Preferences.clear:
      return clear(state);

    case Preferences.fetching:
      return fetching(state);

    case Preferences.receive:
      return receive(state, action.payload.preferences);

    case Preferences.fetchingDone:
      return fetchingDone(state);

    case Preferences.saving:
      return saving(state);

    case Preferences.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
