import deepFreeze from 'deep-freeze';

import { Permissions } from '../actions';

import IPermissions, { APIPermissions } from '../../interfaces/Permissions';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState: IPermissions = {
  fetching: false,
  permissions: undefined,
  saving: false,
};

/**
 * Clear permissions
 */
const clear = (state: IPermissions = initialState): IPermissions => {
  return {
    ...{},
    ...state,
    permissions: undefined,
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IPermissions = initialState): IPermissions => {
  return {
    ...{},
    ...state,
    fetching: true,
  };
};

/**
 * Receive permissions
 */
const receive = (
  state: IPermissions = initialState,
  permissions: APIPermissions,
): IPermissions => {
  return {
    ...{},
    ...state,
    permissions,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IPermissions = initialState): IPermissions => {
  return {
    ...{},
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IPermissions = initialState): IPermissions => {
  return {
    ...{},
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: IPermissions = initialState): IPermissions => {
  return {
    ...{},
    ...state,
    saving: false,
  };
};

interface ActionPermissions extends ActionInterface {
  payload: {
    permissions: APIPermissions;
  };
}

export default (
  state: IPermissions = initialState,
  action?: ActionPermissions,
): IPermissions => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Permissions.clear:
      return clear(state);

    case Permissions.fetching:
      return fetching(state);

    case Permissions.receive:
      return receive(state, action.payload.permissions);

    case Permissions.fetchingDone:
      return fetchingDone(state);

    case Permissions.saving:
      return saving(state);

    case Permissions.savingDone:
      return savingDone(state);
    default:
      return state;
  }
};
