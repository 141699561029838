import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Limits } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { APILimits } from '../../interfaces/Limits';
import { fetchMsg, saveMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(Limits.clear).json;
};

export const getLimits = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Limits.fetching).json);

    let data; //APILimits
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/limits`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('limits', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(Limits.fetchingDone).json);
    dispatch(
      new SuccessAction(Limits.receive, {
        limits: data,
      }).json,
    );
  };
};

export const saveLimits = (limits: APILimits): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Limits.saving).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${activeCustomerId}/limits`,
        accessToken,
        'PUT',
        limits,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg('limit', true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Limits.savingDone).json);
    }
    dispatch(new SuccessAction(Limits.savingDone).json);
    dispatch(
      new SuccessAction(Limits.receive, {
        limits: data,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('limits'),
        type: 'success',
      }),
    );
  };
};
