import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { PhoneExtension } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  fetchMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { createEndpoint } from '../../helpers/api';
import hydrateEndpointBody from '../../helpers/hydrateEndpointBody';
import { nvFetch } from '../../helpers/nvFetch';
import { getDevices, removeDevice } from '../../helpers/provisioning';
import {
  APIPhoneExtension,
  InternalPhoneExtension,
} from '../../interfaces/Phone';
import State from '../../interfaces/State';
import Thing from '../../interfaces/Thing';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { ThunkFunction, blockInvalid, validityAction } from '../helpers';
import { saveLinkedUser } from '../linkeduser/actions';

/**
 * Clear PhoneExtension menus
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(PhoneExtension.clear).json;
};

/**
 * Get the customer's PhoneExtensions from pbx API
 */
// export const getPhoneExtensions = (
//   customerId: string,
//   extractLinkedUsers = false,
//   searchParams: { page: number; pageSize: number } = {
//     page: 1,
//     pageSize: 200,
//   },
// ): any => {
//   return async (
//     dispatch: (action: ActionInterface) => void,
//     getState: () => State,
//   ) => {
//     const { terms } = accountConfigSelector(getState());
//     dispatch(new SuccessAction(PhoneExtension.fetching).json);

//     let data;
//     try {
//       data = (await nvFetch(
//         `customers/${customerId}/endpoints?page=${searchParams.page}&pageSize=${searchParams.pageSize}&type=phone`,
//         getState().user.accessToken,
//       )) as Things<APIPhoneExtension>;
//     } catch (error) {
//       dispatch(
//         toastActions.push({
//           delay: 10000,
//           message: fetchMsg(terms.phone.other, true, error.message),
//           type: 'danger',
//         }),
//       );

//       return;
//     }

//     if (!data || !data.items) {
//       dispatch(new SuccessAction(PhoneExtension.fetchingDone).json);

//       return;
//     }
//     dispatch(
//       new SuccessAction(PhoneExtension.receive, {
//         items: data.items,
//       }).json,
//     );
//     if (extractLinkedUsers) {
//       const linkedUsersById: IdMap<APILinkedUser> = {};
//       // FIXME-RES temporary typing
//       data.items.forEach((phone: InternalPhoneExtension) => {
//         if (phone.linkedUsers) {
//           phone.linkedUsers.forEach((ll) => {
//             linkedUsersById[ll.id] = ll;
//           });
//         }
//       });
//       dispatch(
//         new SuccessAction(LinkedUser.receive, Object.values(linkedUsersById))
//           .json,
//       );
//     }

//     if (data.nextPage) {
//       getPhoneExtensions(customerId, extractLinkedUsers, {
//         page: data.page + 1,
//         pageSize: searchParams.pageSize,
//       })(dispatch, getState);
//     } else {
//       dispatch(new SuccessAction(PhoneExtension.fetchingDone).json);
//     }
//   };
// };

export const getPhoneExtensions = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'endpoints',
    (terms) => terms.phone.other,
    {
      fetching: PhoneExtension.fetching,
      fetchingDone: PhoneExtension.fetchingDone,
      receive: PhoneExtension.receive,
    },
    { pageSize: 200 },
    { type: 'phone' },
  );

export const getSinglePhoneExtension = (
  customerId: string,
  extensionId: string,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(PhoneExtension.fetching).json);

    let data;
    try {
      data = (await nvFetch(
        `customers/${customerId}/endpoints/${extensionId}`,
        getState().user.accessToken,
      )) as Things<APIPhoneExtension>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(terms.phone.one, true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    if (!data) {
      dispatch(new SuccessAction(PhoneExtension.fetchingDone).json);

      return;
    }
    dispatch(
      new SuccessAction(PhoneExtension.receive, {
        items: [data],
      }).json,
    );
    dispatch(new SuccessAction(PhoneExtension.fetchingDone).json);
  };
};

/**
 * Save the customer's PhoneExtension from pbx API
 */
export const savePhoneExtension = (phone: InternalPhoneExtension): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(
        getState().phoneextensions.valid,
        dispatch,
        toastActions.push,
      );
    } catch {
      return;
    }
    const { activeCustomerId, accessToken } = getState().user;
    const { linkedUsersById } = getState().linkeduser;
    const { linkedUsers, ...phoneToSave } = phone;
    dispatch(new SuccessAction(PhoneExtension.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/endpoints/${phone.id}`,
        accessToken,
        'PUT',
        phoneToSave,
      )) as Things<APIPhoneExtension>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.phone.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(PhoneExtension.savingDone).json);
    }
    // FIXME-RES await saves?
    if (linkedUsers) {
      linkedUsers.forEach((linkedUser) => {
        if (linkedUsersById[linkedUser.id].defaultExtension !== phone.id) {
          dispatch(
            saveLinkedUser({ ...linkedUser, defaultExtension: phone.id }, true),
          );
        }
      });
    }
    // FIXME-RES can do better
    Object.values(linkedUsersById).forEach((linkedUser) => {
      if (
        linkedUser.defaultExtension === phone.id &&
        !linkedUsers?.find((lu) => lu.id === linkedUser.id)
      ) {
        dispatch(
          saveLinkedUser({ ...linkedUser, defaultExtension: undefined }, true),
        );
      }
    });
    dispatch(new SuccessAction(PhoneExtension.savingDone).json);

    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(PhoneExtension.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.phone.one),
        type: 'success',
      }),
    );
  };
};

/**
 * Delete the customer's PhoneExtension from pbx API
 */
export const removePhoneExtension = (
  extension: InternalPhoneExtension,
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = extension;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(PhoneExtension.saving).json);

    let deleteFailed = false;
    try {
      const data = await getDevices(id, accessToken);
      if (data?.data?.devices) {
        const { devices } = data.data;
        const promises = devices.map((d) => removeDevice(d.mac, accessToken));
        try {
          await Promise.all(promises);
        } catch (err) {
          console.error(err);
          deleteFailed = true;
        }
      }
    } catch (error) {
      // No provisioning for this extension
    }

    try {
      await nvFetch(
        `customers/${activeCustomerId}/endpoints/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.phone.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(PhoneExtension.savingDone).json);
    }
    dispatch(new SuccessAction(PhoneExtension.savingDone).json);

    dispatch(
      new SuccessAction(PhoneExtension.remove, {
        item: extension,
      }).json,
    );

    let message = removeMsg(terms.phone.one);
    if (deleteFailed) {
      message += '. However, there was a problem deleting provisioning data';
    }

    dispatch(
      toastActions.push({
        delay: 10000,
        message,
        type: deleteFailed ? 'warning' : 'success',
      }),
    );
  };
};

/**
 * Create the customer's PhoneExtension to pbx API
 */

export const createPhoneExtension = (regular: Partial<APIPhoneExtension>) => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(PhoneExtension.saving).json);
    let resultingPhone;
    try {
      resultingPhone = (await createEndpoint(
        accessToken,
        activeCustomerId,
        hydrateEndpointBody(regular),
      )) as Thing;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.phone.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(PhoneExtension.savingDone).json);
    }
    dispatch(new SuccessAction(PhoneExtension.savingDone).json);
    if (!resultingPhone) return;

    dispatch(
      new SuccessAction(PhoneExtension.receive, {
        items: [resultingPhone],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.phone.one),
        type: 'success',
      }),
    );
    return resultingPhone as APIPhoneExtension;
  };
};

export const updateValidity = (
  property: keyof APIPhoneExtension,
  valid: boolean,
) => {
  return validityAction<APIPhoneExtension>(
    property,
    valid,
    'phoneextensions',
    PhoneExtension.updateValidity,
  );
};
