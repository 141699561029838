import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Pricing } from '../actions';
import * as toastActions from '../toasts/actions';

import State from '../../interfaces/State';
import { nvFetch } from '../../helpers/nvFetch';
import { APIPricing } from '../../interfaces/Pricing';
import { fetchMsg, saveMsg } from '../../helpers/actionMessageCreator';

export const clear = (): ActionInterface => {
  return new SuccessAction(Pricing.clear).json;
};

export const getPricing = (): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Pricing.fetching).json);

    let data; //APIPricing
    try {
      data = await nvFetch(
        `customers/${getState().user.activeCustomerId}/pricing`,
        getState().user.accessToken,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg('pricing', true, error.message),
          type: 'danger',
        }),
      );

      return;
    }

    dispatch(new SuccessAction(Pricing.fetchingDone).json);
    dispatch(
      new SuccessAction(Pricing.receive, {
        pricing: data,
      }).json,
    );
  };
};

export const savePricing = (pricing: APIPricing): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Pricing.saving).json);
    let data;
    try {
      data = await nvFetch(
        `customers/${activeCustomerId}/pricing`,
        accessToken,
        'PUT',
        pricing,
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg('pricing', true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Pricing.savingDone).json);
    }
    dispatch(new SuccessAction(Pricing.savingDone).json);
    dispatch(
      new SuccessAction(Pricing.receive, {
        pricing: data,
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg('pricing'),
        type: 'success',
      }),
    );
  };
};
