import * as React from 'react';

import styles from './styles.module.scss';

import { Props, State } from './interfaces';

export default class Loading extends React.PureComponent<Props, State> {
  private unmounted: boolean;

  public state: State = {
    showMessage: false,
  };

  public static defaultProps: Props = {
    message: 'This is taking a little longer than expected',
    messageDelay: 10000,
    size: 3,
  };

  public componentDidMount() {
    if (this.props.messageDelay > 0) {
      const showMessageTimeout = setTimeout(
        this.showDelayMessage,
        this.props.messageDelay,
      );

      this.setState({ showMessageTimeout });
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.state.showMessageTimeout);

    // For reasons unknown to me, the timeout still fires even when cleared on
    // unmount, so the unmounted flag protects against updating state on an
    // unmounted component
    this.unmounted = true;
  }

  private showDelayMessage = () => {
    if (!this.unmounted) {
      this.setState({ showMessage: true });
    }
  };

  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        <div
          className={styles.spinner}
          style={{
            borderWidth: `${this.props.size * 1.5}px`,
            height: `${this.props.size}em`,
            width: `${this.props.size}em`,
          }}
        />

        {this.state.showMessage && <span>{this.props.message}</span>}
      </div>
    );
  }
}
