import deepFreeze from 'deep-freeze';

import { BlockingRules } from '../actions';

import BlockingRuleState, {
  APIBlockingRule,
} from '../../interfaces/BlockingRules';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: BlockingRuleState = {
  blockingRulesById: {},
  error: '',
  fetching: false,
  saving: false,
  valid: { matchString: true },
};

const clear = (state: BlockingRuleState = initialState): BlockingRuleState => {
  return {
    ...state,
    blockingRulesById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (
  state: BlockingRuleState = initialState,
): BlockingRuleState => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: BlockingRuleState = initialState,
  receivedBlockingRule: APIBlockingRule[] = [],
): BlockingRuleState => {
  const { idMap } = arrayToIdMapAndOrdering(receivedBlockingRule, 'id');

  return {
    ...state,
    blockingRulesById: {
      ...state.blockingRulesById,
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (
  state: BlockingRuleState = initialState,
): BlockingRuleState => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: BlockingRuleState = initialState): BlockingRuleState => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (
  state: BlockingRuleState = initialState,
): BlockingRuleState => {
  return {
    ...state,
    saving: false,
  };
};

const remove = (
  state: BlockingRuleState = initialState,
  blockingRule: APIBlockingRule,
): BlockingRuleState => {
  const { [blockingRule.id]: _, ...rest } = state.blockingRulesById;
  return {
    ...state,
    blockingRulesById: rest,
  };
};

const updateValidity = (
  state: BlockingRuleState = initialState,
  validity: Validity<APIBlockingRule>,
) => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};

interface BlockingRuleAction extends ActionInterface {
  payload: {
    item?: APIBlockingRule;
    items?: APIBlockingRule[];
    validity?: Validity<APIBlockingRule>;
  };
}

export default (
  state: BlockingRuleState = initialState,
  action?: BlockingRuleAction,
): BlockingRuleState => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case BlockingRules.clear:
      return clear(state);

    case BlockingRules.fetching:
      return fetching(state);

    case BlockingRules.receive:
      return receive(state, action.payload.items);

    case BlockingRules.fetchingDone:
      return fetchingDone(state);

    case BlockingRules.remove:
      return remove(state, action.payload.item);

    case BlockingRules.saving:
      return saving(state);

    case BlockingRules.savingDone:
      return savingDone(state);

    case BlockingRules.updateValidity:
      return updateValidity(state, action.payload.validity);

    default:
      return state;
  }
};
