import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Trunks } from '../actions';
import * as toastActions from '../toasts/actions';

import {
  createMsg,
  removeMsg,
  saveMsg,
} from '../../helpers/actionMessageCreator';
import { createEndpoint } from '../../helpers/api';
import hydrateEndpointBody from '../../helpers/hydrateEndpointBody';
import { nvFetch } from '../../helpers/nvFetch';
import State from '../../interfaces/State';
import Thing from '../../interfaces/Thing';
import Things from '../../interfaces/Things';
import { APITrunk } from '../../interfaces/Trunks';
import { accountConfigSelector } from '../../selectors/customer';
import { completelyFetchResourceIntoStore } from '../fetchHelpers';
import { blockInvalid, ThunkFunction, validityAction } from '../helpers';

export const clear = (): ActionInterface => {
  return new SuccessAction(Trunks.clear).json;
};

export const getTrunks = (): ThunkFunction =>
  completelyFetchResourceIntoStore(
    'endpoints',
    (terms) => terms.trunk.other,
    {
      fetching: Trunks.fetching,
      fetchingDone: Trunks.fetchingDone,
      receive: Trunks.receive,
    },
    { pageSize: 200 },
    { type: 'trunk' },
  );

export const saveTrunk = (trunk: APITrunk): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    try {
      blockInvalid(getState().trunks.valid, dispatch, toastActions.push);
    } catch {
      return;
    }

    const { activeCustomerId, accessToken } = getState().user;
    dispatch(new SuccessAction(Trunks.saving).json);
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/endpoints/${trunk.id}`,
        accessToken,
        'PUT',
        trunk,
      )) as Things<APITrunk>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: saveMsg(terms.trunk.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Trunks.savingDone).json);
    }
    dispatch(new SuccessAction(Trunks.savingDone).json);
    if (!data) {
      return;
    }
    dispatch(
      new SuccessAction(Trunks.receive, {
        items: [data],
      }).json,
    );
    dispatch(
      toastActions.push({
        delay: 5000,
        message: saveMsg(terms.trunk.one),
        type: 'success',
      }),
    );
  };
};

export const removeTrunk = (trunk: APITrunk): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { id } = trunk;
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Trunks.saving).json);

    try {
      await nvFetch(
        `customers/${activeCustomerId}/endpoints/${id}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.trunk.one, true, error.message),
          type: 'danger',
        }),
      );
      return dispatch(new SuccessAction(Trunks.savingDone).json);
    }
    dispatch(new SuccessAction(Trunks.savingDone).json);
    dispatch(
      new SuccessAction(Trunks.remove, {
        item: trunk,
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: removeMsg(terms.trunk.one),
        type: 'success',
      }),
    );
  };
};

export const createTrunk = (trunk: Partial<APITrunk>) => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    const { accessToken, activeCustomerId } = getState().user;
    dispatch(new SuccessAction(Trunks.saving).json);
    let resultingTrunk;
    try {
      resultingTrunk = (await createEndpoint(
        accessToken,
        activeCustomerId,
        hydrateEndpointBody(trunk),
      )) as Thing;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.trunk.one, true, error.message),
          type: 'danger',
        }),
      );

      return dispatch(new SuccessAction(Trunks.savingDone).json);
    }
    dispatch(new SuccessAction(Trunks.savingDone).json);
    if (!resultingTrunk) return;

    dispatch(
      new SuccessAction(Trunks.receive, {
        items: [resultingTrunk],
      }).json,
    );

    dispatch(
      toastActions.push({
        delay: 10000,
        message: createMsg(terms.trunk.one),
        type: 'success',
      }),
    );
    return resultingTrunk as APITrunk;
  };
};

export const updateValidity = (property: keyof APITrunk, valid: boolean) =>
  validityAction(property, valid, 'trunks', Trunks.updateValidity);
