import deepFreeze from 'deep-freeze';

import { PhoneNumbers } from '../actions';

import IPhoneNumbers, { APIPhoneNumber } from '../../interfaces/PhoneNumbers';

import { ActionInterface } from '../../helpers/actionBuilder';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';
import { Validity } from '../../interfaces/interfaces';

export const initialState: IPhoneNumbers = {
  error: '',
  fetching: false,
  phoneNumbersById: {},
  saving: false,
  valid: {
    faxNotificationEmail: true,
    smsNotificationEmail: true,
    smsNotificationUrl: true,
  },
};

/**
 * Clear phonenumbers
 */
const clear = (state: IPhoneNumbers = initialState): IPhoneNumbers => {
  return {
    ...state,
    phoneNumbersById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: IPhoneNumbers = initialState): IPhoneNumbers => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive phone numbers
 */
const receive = (
  state: IPhoneNumbers = initialState,
  phoneNumbers: APIPhoneNumber[] = [],
): IPhoneNumbers => {
  const processedPhoneNumbers = phoneNumbers.map((phoneNumber) => ({
    ...phoneNumber,
    created: new Date(phoneNumber.created),
  }));
  const { idMap } = arrayToIdMapAndOrdering(processedPhoneNumbers, 'id');

  return {
    ...state,
    phoneNumbersById: {
      ...state.phoneNumbersById,
      ...idMap,
    },
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: IPhoneNumbers = initialState): IPhoneNumbers => {
  return {
    ...state,
    fetching: false,
  };
};

/**
 * Set saving flag to true
 */
const saving = (state: IPhoneNumbers = initialState): IPhoneNumbers => {
  return {
    ...state,
    saving: true,
  };
};

/**
 * Set saving flag to false
 */
const savingDone = (state: IPhoneNumbers = initialState): IPhoneNumbers => {
  return {
    ...state,
    saving: false,
  };
};

/**
 * Remove a phonenumber
 */
const remove = (
  state: IPhoneNumbers = initialState,
  phoneNumber: APIPhoneNumber,
): IPhoneNumbers => {
  const { [phoneNumber.id]: _, ...rest } = state.phoneNumbersById;
  return {
    ...state,
    phoneNumbersById: rest,
    // TODO check
  };
};

const updateValidity = (
  state: IPhoneNumbers,
  validity: Validity<APIPhoneNumber>,
) => {
  return {
    ...state,
    valid: { ...state.valid, ...validity },
  };
};

interface PhoneNumbersAction extends ActionInterface {
  payload: {
    item?: APIPhoneNumber;
    items?: APIPhoneNumber[];
    validity?: Validity<APIPhoneNumber>;
  };
}

export default (
  state: IPhoneNumbers = initialState,
  action?: PhoneNumbersAction,
): IPhoneNumbers => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case PhoneNumbers.clear:
      return clear(state);

    case PhoneNumbers.fetching:
      return fetching(state);

    case PhoneNumbers.receive:
      return receive(state, action.payload.items);

    case PhoneNumbers.fetchingDone:
      return fetchingDone(state);

    case PhoneNumbers.remove:
      return remove(state, action.payload.item);

    case PhoneNumbers.saving:
      return saving(state);

    case PhoneNumbers.savingDone:
      return savingDone(state);
    case PhoneNumbers.updateValidity:
      return updateValidity(state, action.payload.validity);
    default:
      return state;
  }
};
