import deepFreeze from 'deep-freeze';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { Numbering } from '../actions';

import INumbering, { PhoneNumber } from '../../interfaces/Numbering';

import { ActionInterface } from '../../helpers/actionBuilder';
import { qualityPriceMap } from '../../helpers/constants';
import { arrayToIdMapAndOrdering } from '../../helpers/utils';

export const initialState: INumbering = {
  error: '',
  fetching: false,
  numbersById: {},
};

/**
 * Clear toasts
 */
const clearNumbers = (state: INumbering = initialState): INumbering => {
  return {
    ...state,
    numbersById: {},
  };
};

/**
 * Set fetching flag to true
 */
const fetching = (state: INumbering = initialState): INumbering => {
  return {
    ...state,
    fetching: true,
  };
};

/**
 * Receive numbers
 */
const receive = (
  state: INumbering = initialState,
  numbers: PhoneNumber[] = [],
): INumbering => {
  const processedNumbers = numbers.map((number) => ({
    ...number,
    area: `0${number.area}`,
    country: `+${number.country}`,
    formattedNumber: parsePhoneNumberFromString(
      `+${number.fullNumber}`,
    ).formatNational(),
    fullNumber: number.fullNumber,
    id: `1-${number.country}-${number.area}-${number.number}`,
    price: qualityPriceMap.get(number.quality),
  }));
  const { idMap } = arrayToIdMapAndOrdering(processedNumbers, 'id');
  return {
    ...state,
    numbersById: idMap,
  };
};

/**
 * Set fetching flag to false
 */
const fetchingDone = (state: INumbering = initialState): INumbering => {
  return {
    ...state,
    fetching: false,
  };
};

interface NumberingAction extends ActionInterface {
  payload: {
    numbers?: PhoneNumber[];
  };
}

export default (
  state: INumbering = initialState,
  action?: NumberingAction,
): INumbering => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Numbering.clear:
      return clearNumbers(state);

    case Numbering.fetching:
      return fetching(state);

    case Numbering.receive:
      return receive(state, action.payload.numbers);

    case Numbering.fetchingDone:
      return fetchingDone(state);

    default:
      return state;
  }
};
