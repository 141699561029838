import * as Sentry from '@sentry/browser';
import { routerMiddleware } from 'connected-react-router';
import { throttle } from 'lodash';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunk from 'redux-thunk';
import { history } from '../helpers/history';

import createRootReducer from './reducers';
import { loadState, saveState } from './statePersistence';

import State from '../interfaces/State';

export const configureStore = () => {
  const initialState = loadState() as State;

  const middlewares: Middleware[] = [routerMiddleware(history), thunk];

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      debug: process.env.NODE_ENV !== 'production',
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment:
        process.env.NODE_ENV === 'production' ? 'production' : 'development',
      // TODO Make a pre-build script that writes version and commit shorthash
      //   to .env.local?
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_COMMIT_SHORT_SHA}`,
      // tags: {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   git_commit: process.env.REACT_APP_COMMIT,
      // },
    });

    middlewares.push(createSentryMiddleware(Sentry, {}));
  }

  let composedMiddleware;

  if (process.env.NODE_ENV !== 'production') {
    composedMiddleware = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    composedMiddleware = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composedMiddleware,
  );

  // Don't allow state to be stored more than once a second
  store.subscribe(
    throttle(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      saveState(store.getState());
    }, 1000),
  );

  return store;
};
