import deepFreeze from 'deep-freeze';

import { App } from '../actions';

import { ActionInterface } from '../../helpers/actionBuilder';

export const initialState = {
  modalOpen: false,
  sidebarOpen: false,
};

const modalOpened = (app = initialState) => {
  return {
    ...app,
    modalOpen: true,
  };
};

const modalClosed = (app = initialState) => {
  return {
    ...app,
    modalOpen: false,
  };
};

const openSidebar = (app = initialState) => {
  return {
    ...app,
    sidebarOpen: true,
  };
};

const closeSidebar = (app = initialState) => {
  return {
    ...app,
    sidebarOpen: false,
  };
};

const toggleSidebar = (app = initialState) => {
  return {
    ...app,
    sidebarOpen: !app.sidebarOpen,
  };
};

export default (state = initialState, action?: ActionInterface) => {
  if (process.env.NODE_ENV !== 'production') {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case App.modalOpened:
      return modalOpened();

    case App.modalClosed:
      return modalClosed();

    case App.openSidebar:
      return openSidebar();

    case App.closeSidebar:
      return closeSidebar();

    case App.toggleSidebar:
      return toggleSidebar();

    default:
      return state;
  }
};
