import { DateTime } from 'luxon';
import {
  APITimeInterval,
  APIYearlyTimeInterval,
} from '../interfaces/TimeIntervals';

type Options = {
  customerId: string;
  startDateTime: Date;
  endDateTime: Date;
  name: string;
};

type Month = string;

type IncludedTime = {
  month: Month;
  dayOfMonth: number;
  startTime: string;
  endTime: string;
};

const months: string[] = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

/* eslint-disable sort-keys */
const daysInMonth: {
  [month: string]: number;
} = {
  JAN: 31,
  FEB: 29,
  MAR: 31,
  APR: 30,
  MAY: 31,
  JUN: 30,
  JUL: 31,
  AUG: 31,
  SEP: 30,
  OCT: 31,
  NOV: 30,
  DEC: 31,
};
/* eslint-enable sort-keys */

const timeStringToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');
  return Number(hours) * 60 + Number(minutes);
};

export const sortIncludedTimes = <T extends APITimeInterval>(
  timeInterval: T,
): T => {
  if (timeInterval.repeat === 'YEARLY') {
    const { includedTimes } = timeInterval as APIYearlyTimeInterval;
    const newIncludedTimes = includedTimes.sort((a, b) => {
      const monthPosA = months.indexOf(a.month);
      const monthPosB = months.indexOf(b.month);
      if (monthPosA !== monthPosB) {
        return monthPosA - monthPosB;
      }
      if (a.dayOfMonth !== b.dayOfMonth) {
        return a.dayOfMonth - b.dayOfMonth;
      }
      const startTimeA = timeStringToMinutes(a.startTime);
      const startTimeB = timeStringToMinutes(b.startTime);
      if (startTimeA !== startTimeB) {
        return startTimeA - startTimeB;
      }
      return timeStringToMinutes(a.endTime) - timeStringToMinutes(b.endTime);
    });
    return { ...timeInterval, includedTimes: newIncludedTimes };
  }

  return timeInterval;
};

const buildIncludedTime = (
  month: string,
  dayOfMonth: number,
  startTimeParam = '00:00',
  endTimeParam = '23:59',
): IncludedTime => ({
  dayOfMonth,
  endTime: endTimeParam,
  month,
  startTime: startTimeParam,
});

const buildIncludedTimes = (
  intervalMonth: string,
  intervalStartDay: number,
  intervalEndDay = 0,
  intervalStartTime = '00:00',
  intervalEndTime = '23:59',
) => {
  let includedTimes: IncludedTime[] = [];

  for (let day = intervalStartDay; day <= intervalEndDay; day++) {
    const adjustedStartTime =
      day === intervalStartDay ? intervalStartTime : '00:00';
    const adjustedEndTime = day === intervalEndDay ? intervalEndTime : '23:59';

    includedTimes = [
      ...includedTimes,
      buildIncludedTime(intervalMonth, day, adjustedStartTime, adjustedEndTime),
    ];
  }

  return includedTimes;
};

const getNextMonth = (month: string): Month => {
  const monthIdx = months.findIndex((x) => x === month);
  const nextMonthIdx = monthIdx + 1;

  return nextMonthIdx >= months.length ? months[0] : months[nextMonthIdx];
};

const getIncludedMonths = (startMonth: string, endMonth: string): Month[] => {
  if (startMonth === endMonth) {
    return [startMonth];
  }

  let includedMonths = [startMonth];

  let done = false;

  let nextMonth = startMonth;

  while (!done) {
    nextMonth = getNextMonth(nextMonth);
    if (nextMonth === endMonth) {
      done = true;
    }

    includedMonths = [...includedMonths, nextMonth];
  }

  return includedMonths;
};

export const buildInterval = (options: Options) => {
  const start = DateTime.fromJSDate(options.startDateTime);
  const end = DateTime.fromJSDate(options.endDateTime);

  const startTime = start.toLocaleString(DateTime.TIME_24_SIMPLE);
  const endTime = end.toLocaleString(DateTime.TIME_24_SIMPLE);
  const startDay = start.day;
  const endDay = end.day;
  const startMonth = months[start.month - 1];
  const endMonth = months[end.month - 1];

  if (!options.customerId) {
    throw new Error('Time interval requires a company ID');
  }

  if (end < start) {
    throw new Error('End date is before start date');
  }

  if (!options.name) {
    throw new Error('Time interval requires a name');
  }

  const includedMonths = getIncludedMonths(startMonth, endMonth);

  const includedTimes = includedMonths.reduce(
    (a: IncludedTime[], month: string, i, arr) => {
      const isFirstMonth = i === 0;
      const isLastMonth = i === arr.length - 1;
      const isOnlyMonth = arr.length === 1;

      const daysThisMonth = daysInMonth[month];

      let times = [];

      if (isOnlyMonth) {
        times = buildIncludedTimes(month, startDay, endDay, startTime, endTime);
      } else if (isFirstMonth) {
        times = buildIncludedTimes(month, startDay, daysThisMonth, startTime);
      } else if (isLastMonth) {
        times = buildIncludedTimes(month, 1, endDay, undefined, endTime);
      } else {
        times = buildIncludedTimes(month, 1, daysThisMonth);
      }

      return [...a, ...times];
    },
    [],
  );

  const newTimeInterval = {
    includedTimes,
    name: options.name,
    repeat: 'YEARLY',
    type: 'timeinterval',
  };

  return newTimeInterval;
};
