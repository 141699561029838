import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { Toasts } from '../actions';

import Toast from '../../interfaces/Toast';

const animationDuration = 250;

/**
 * Clear toasts
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(Toasts.clear).json;
};

/**
 * Add toast to queue
 */
export const push = (toast: Toast | string): ActionInterface => {
  if (typeof toast === 'string') {
    toast = {
      delay: -1,
      message: toast,
      type: 'primary',
    };
  }

  return new SuccessAction(Toasts.push, { toast }).json;
};

/**
 * Remove toast from front of queue
 */

export const shift = (): any => {
  return (dispatch: (action: ActionInterface) => void) => {
    dispatch(new SuccessAction(Toasts.preremove).json);

    setTimeout(() => {
      dispatch(new SuccessAction(Toasts.shift).json);
    }, animationDuration);
  };
};

/**
 * Remove a specific toast
 */

export const remove = (id: number): any => {
  return (dispatch: (action: ActionInterface) => void) => {
    dispatch(new SuccessAction(Toasts.preremove, { id }).json);

    setTimeout(() => {
      dispatch(new SuccessAction(Toasts.remove, { id }).json);
    }, animationDuration);
  };
};
