import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import component from './component';

import State from '../../interfaces/State';
import { activeCustomerSelector } from '../../selectors/customer';

const mapStateToProps = (state: State) => {
  return {
    company: activeCustomerSelector(state)?.company,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
